import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../../Mycontext";
import { message } from "antd";
import { useNavigate } from "react-router";
import axios from "axios";
import { FaTags } from "react-icons/fa";
function PublishedJob({
  job,
  LocationSelected,
  languageSelected,
  skillSelected,
  technologySelected,
  categorySelected,
  setshowPublished, showpublished, Description,
  // tags
}) {
  const { endPointUrl, Token, user } = useContext(MyContext);
  const Navigate = useNavigate();
  const [showmodel, setshowModel] = useState(true);
  const CloseModel = () => {
    setshowModel(false);
    setshowPublished(!showpublished)
  };
  const handleSubmit = async (IsActive) => {
    // seterror(true);
    let Status;
    if (IsActive) {
      Status = "Published";
    } else {
      Status = "Draft";
    }
    try {
      await axios.post(endPointUrl + "/user/userAddJobs", {
        ...job,
        Location: LocationSelected.join(","),
        SkillsRequired: skillSelected.join(","),
        Technology: technologySelected.join(","),
        Category: categorySelected.join(","),
        Language: languageSelected.join(","),
        // Keywords: tags.join(","),
        Employer_ID: user.id,
        Description,
        Token,
        IsActive,
        Status
      });
      if (IsActive) {
        message.success("Job Published Successfully");
      } else {
        message.success("Job saved in Draft");
      }
      Navigate("../EmployerJobProfile");
      setshowModel(false);
    } catch {
      window.scroll(0, 0);
      message.warning("please fill all the fields");
      setshowPublished(!showpublished);
    }

  };
  return (
    <div id="myModal" className={showmodel ? "model-open" : "model"} style={{ overflow: 'scroll' }}>
      <div className="modal-content publish">
        <span className="close" onClick={CloseModel}>
          &times;
        </span>
        <div className="publishButton">
          <div className="btn" onClick={() => handleSubmit(true)}>
            Publish
          </div>
          <div className="btn" style={{ background: "#b69d48" }} onClick={() => handleSubmit(false)}>
            Save as Draft
          </div>
        </div>
        <hr />
        <div>
          <h2 style={{ fontWeight: "bold" }}>{job?.Title}</h2>
        </div>
        {/* <div>
          <h3>ShortDesc</h3>
          <span style={{ fontSize: "14px", fontWeight: "400" }}>
            {job?.ShortDesc}
          </span>
        </div> */}
        <div>
          <h3>Project Type</h3>
          <span style={{ fontSize: "14px", fontWeight: "400" }}>
            {job?.ProjectType}
          </span>
        </div>
        <div>
          <h3 style={{ fontWeight: "600", display: "block" }}>Description</h3>
          <span dangerouslySetInnerHTML={{ __html: Description }}></span>
        </div>

        <h3>Budget Details</h3>
        <span>{job?.Budget_From}-</span>
        <span>{job?.Budget_To}/</span>
        <h3>Payment Type</h3>
        <span>{job?.Budget_Type}</span>

        <div>
          <h3>Job Type</h3>
          <span>{job?.Type}</span>

          <div className="viewSkills">
            <h3>Skills</h3>
            <div>
              {skillSelected.map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          <div className="viewSkills">
            <h3>Technology</h3>
            <div>
              {technologySelected.map((item) => <span>{item}</span>)}</div>
          </div>
          <div className="viewSkills">
            <h3>Category</h3>
            <div>{categorySelected.map((item) => <span>{item}</span>)}</div>
          </div>
          {/* <div className="viewSkills">
            <h3>Keywords</h3>
            <div>{tags.map((item) => <span>{item}</span>)}</div>
          </div> */}
          <div className="viewSkills">
            <h3>Language</h3>
            <div>
              {languageSelected.map((item) => <span>{item}</span>)}</div>
          </div>
          <div className="viewSkills">
            <h3>Location</h3>
            <div>
              {LocationSelected.map((item) => <span>{item}</span>)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishedJob;
