
import React, { useState, useContext, useEffect } from "react";
import remove from '../../images/remove.png'
import add from '../../images/add.png'
import moment from "moment/moment";
import { message } from 'antd';
import { MyContext } from "../../Mycontext";
import axios from 'axios';
import { useNavigate } from "react-router";

function Milestone({ forms, setForms, editable }) {
  const navigate = useNavigate();
  const { Token, endPointUrl } = useContext(MyContext);
  const [Index, setIndex] = useState();

  const getIndex = () => {
    for (let index = 0; index < forms.length; index++) {
      const item = forms[index];
      // console.log(forms);
      if (item.status === "Pending" || item.status === "Rejected") {
        setIndex(index);
        break;
      }
    }
    // console.log(Index)
  };
  const handleFormChange = (index, event) => {
    const { name, value } = event.target;
    const newForms = [...forms];
    newForms[index][name] = value;
    setForms(newForms);
  };

  const handleAddForm = () => {
    const newForms = [
      ...forms,
      { Amount: 0, Description: "", M_Due_Date: "" },
    ];
    setForms(newForms);
  };

  const handleRemoveForm = (index) => {
    const newForms = [...forms];
    newForms.splice(index, 1);
    setForms(newForms);
  };
  const handleclick = async (PM_ID) => {
    try {
      await axios.post(endPointUrl + "/project/updatemilestone", { Token, PM_ID: PM_ID, MilstoneStatus: "Up for Review" })
      message.success("Raised milestone up for Review")
    } catch {
      message.error("An error occured while raising review")
    }
    getIndex();
    navigate("/FreelancerJobProfile");
  };
  useEffect(() => {
    getIndex();
  }, [forms])

  return (
    <>
      {editable ? (
        <div>
          <h4>Milestone</h4>
          {forms.map((form, index) => (
            <div>
              <div style={{ display: "flex", flexDirection: "column" }} key={index}>
                <span style={{ marginRight: "5px" }}> #{index + 1}</span>
                <div className="bid_Form milestone_Form upper">
                  <label style={{ display: "flex" }}>
                    <span id="highlighted">Amount :</span>
                    <span>{form.Amount}</span>
                  </label>
                  <label style={{ display: "flex" }}>
                    <span id="highlighted">Payment :</span>
                    <span>{form.recieveStatus}</span>
                  </label>
                  <label style={{ display: "flex" }}>
                    <span id="highlighted">Due Date :</span>
                    <span>{moment(form.M_Due_Date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}</span>
                  </label>
                </div>
                <div className="bid_Form upper">
                  <label className="description">
                    <span id="highlighted">Description :</span>
                    <textarea readOnly
                      name="Description"
                      id="Description"
                      cols="40"
                      rows="3"
                      value={form.Description}
                      style={{ overflowX: "hidden" }}
                    ></textarea>
                  </label>
                </div>
              </div>
              {form.paymentStatus === "Released" ? Index === index ?
                <button className="mil_buton"
                  style={{ marginBlockEnd: "10px", marginInlineStart: "70%", borderRadius: "0" }}
                  onClick={() => handleclick(form.mid)}
                >Raise for Review</button>
                : form.status === "Up for Review" ?
                  <button className='mil_buton disabled'
                    style={{ marginBlockEnd: "10px", marginInlineStart: "71%", borderRadius: "0" }}
                  >Awaiting Review</button>
                  : <button className='mil_buton disabled'
                    style={{ marginBlockEnd: "10px", marginInlineStart: "76.7%", borderRadius: "0" }}
                  >Reviewed</button> : null}
              <hr />
            </div>
          ))
          }
        </div>
      ) : (<div>
        <h4>Milestones</h4>
        {forms.map((form, index) => (
          <div key={index}>
            #{index + 1}
            <div className="bid_Form milestone_Form">
              <label>
                Amount:
                <input
                  type="number"
                  name="Amount"
                  step={0.01}
                  value={form.Amount}
                  onChange={(event) => handleFormChange(index, event)}
                  disabled={editable}

                />
              </label>
              <label>
                Due_Date:
                <input
                  type="date"
                  name="M_Due_Date"
                  value={moment(form.M_Due_Date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}
                  onChange={(event) => handleFormChange(index, event)}
                  disabled={editable}
                />
              </label>
            </div>
            <div className="bid_Form">
              <label className="description">
                Description:
                <textarea
                  name="Description"
                  id="Description"
                  cols="40"
                  rows="5"
                  value={form.Description}
                  onChange={(event) => handleFormChange(index, event)}
                  disabled={editable}
                ></textarea>
              </label>
            </div>
            <hr />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {(index > 0 && !editable) && (
                <img src={remove} onClick={() => handleRemoveForm(index)} className="remove" />
              )}
              {(index === forms.length - 1 && !editable) && (
                <img src={add} onClick={handleAddForm} className="add" />
              )}
            </div>
          </div>
        ))
        }
      </div>
      )}
    </>
  );
}

export default Milestone;
