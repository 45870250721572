import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { MyContext } from "../../../Mycontext";
import add from "../../../images/add.png";
import { MdOutlineCancel } from 'react-icons/md';

import { message } from 'antd';
import EditCertificate from "./EditCertificate";
// import EditPortfolio from "./EditPortfolio";

function AddCertificate() {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [showmodel, setshowModel] = useState(false);
    const [preform, setPreform] = useState();
    const [PortData, setPortData] = useState([]);
    const [form, setForm] = useState({
        Title: "",
        Completion_Date: "",
        Completion_Expire_Date: ""
    });
    const [errorForm, setErrorForm] = useState({
        Title: "",
        Completion_Date: "",
        Completion_Expire_Date: "",
        file: "",
    });

    const handlemodle = () => {
        setErrorForm({
            Title: "",
            Completion_Date: "",
            Completion_Expire_Date: "",
            file: "",
        })
        setshowModel(true)
    }
    const handleCertificate = () => {
        const newFormErrors = {
            Title: "",
            Completion_Date: "",
            Completion_Expire_Date: "",
            file: "",
        };
        let showError = false;
        if (form.Title === null || form.Title === "") {
            showError = true;
            newFormErrors.Title = 'Please fill out this field!';
        } else {
            newFormErrors.Title = '';
        }
        if (form.Completion_Expire_Date === null || form.Completion_Expire_Date === "") {
            showError = true;
            newFormErrors.Type = 'Please fill out this field!';
        }
        else {
            newFormErrors.Completion_Expire_Date = "";
        }
        if (form.Completion_Date === null || form.Completion_Date === "") {
            showError = true;
            newFormErrors.Completion_Date = 'Please fill out this field!';
        } else {
            newFormErrors.Completion_Date = '';
        }
        if (preform === null || !preform) {
            showError = true;
            newFormErrors.file = 'Please fill out this field!';
        } else {
            newFormErrors.file = '';
        }
        setErrorForm(newFormErrors);
        if (!showError) {
            AddCertificate()
        }
    }

    const getCertificate = async () => {
        const { data } = await axios.post(endPointUrl + "/user/getCertiByUserID", {
            Token,
            UserID: user.id
        });
        if (data.data != null) {
            setPortData(data.data);
        }

    }
    const AddCertificate = async () => {
        try {
            const formData = new FormData()
            formData.append("Certificate_Image", preform)

            const { data } = await axios.post(
                endPointUrl + "/user/certificateupload", formData
            );
            try {
                await axios.post(endPointUrl + "/user/addcertificate", {
                    ...form,
                    Certificate_Image: data.fileName,
                    UserID: user.id,
                    Token
                });
                message.success("Certificate Added")
                getCertificate()
                setshowModel(false)

            } catch {
                console.log("upload url produce error");
            }
        } catch {
            console.log("error occur during upload docs");
        }
    }

    useEffect(() => {
        getCertificate();
    }, [])

    return (
        <div className="education">
            <strong style={{ display: "flex", alignItems: "center" }}>
                Cetificate <img src={add} alt="" onClick={handlemodle} />
            </strong>

            {PortData.map((item, index) =>
                item?.UserID ?
                    <div className="all-education" key={index}>
                        <div className="education-line">
                        </div>
                        <div className="education-conainer" >
                            <div className="eduction-time" >
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ color: "black", fontSize: "20px", fontWeight: "600" }}>{item?.Type}</span></div>
                                    <div className="year-range">
                                        {item?.Completion_Date?.slice(5, 7)}, {" "}
                                        {item?.Completion_Date?.slice(0, 4)} - {" "}
                                        {item?.Completion_Expire_Date?.slice(5, 7)}, {" "}
                                        {item?.Completion_Expire_Date?.slice(0, 4)}
                                    </div>

                                    <div className="collage">{item?.Title}</div>
                                    <div className="Portfolio-docs" style={{ display: "flex", alignItems: "center", gap: "20px", margin: "20px 0" }}>
                                        <div className="potfolioConatiner" style={{ position: "relative" }}>
                                            <a
                                                href={`${endPointUrl}/certificates/${item.Certificate_Image}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    src={`${endPointUrl}/certificates/${item.Certificate_Image}`}
                                                    style={{ width: "50px", height: "50px", borderRadius: "25%" }}
                                                    alt="cert"
                                                    className="potfoliothumbail"
                                                />
                                            </a>
                                        </div>

                                    </div>
                                    <EditCertificate Certificate={item} getCertificate={getCertificate} />
                                    <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
                                </div>
                            </div>
                        </div>
                    </div> : null
            )}
            <div id="myModal" className={showmodel ? "model-open" : "model"}>

                <div className="modal-content" style={{ width: "60%", padding: 0 }} >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                        <span style={{ color: "#46c294", fontWeight: "600" }}>Add Certificate</span>
                        <span className="close" onClick={(() => setshowModel(false))}>
                            &times;
                        </span>
                    </div>

                    <form style={{ padding: "0 40px", margin: "30px 0" }} className="model-field exp-form"
                        encType="multipart/form-data"
                        onSubmit={(e) => {
                            e.preventDefault();

                        }}>
                        <div style={{ paddingInlineEnd: "20px" }}>
                            <div>
                                {" "}
                                <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                    <div>
                                        <label htmlFor="title">Title</label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Title}</small>
                                        <input
                                            style={{ marginInlineEnd: "12%" }}
                                            type="text"
                                            name="title"
                                            className={errorForm.Title != "" ? "errorborder" : ""}
                                            placeholder='Enter title'
                                            onChange={(e) => setForm({ ...form, Title: e.target.value })}
                                        />
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <div >
                                            <label htmlFor="file" style={{ display: "block", marginBottom: "0" }}>File:</label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.file}</small>
                                            <input
                                                type="file"
                                                name="File_Upload"
                                                className={errorForm.file != "" ? "errorborder" : ""}
                                                onChange={(e) => { setPreform(e.target.files[0]) }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                    <div>
                                        <label htmlFor="Completion_Date">Completion_Date</label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Completion_Date}</small>
                                        <input
                                            type="date"
                                            className={errorForm.Completion_Date != "" ? "errorborder" : ""}
                                            name="Completion_Date"
                                            placeholder="Enter Completion_Date"
                                            onChange={(e) =>
                                                setForm({ ...form, Completion_Date: e.target.value })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="Completion_Expire_Date">Completion Expire Date</label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Completion_Expire_Date}</small>
                                        <input
                                            type="date"
                                            className={errorForm.Completion_Expire_Date != "" ? "errorborder" : ""}
                                            name="Completion_Expire_Date"
                                            placeholder="Enter Completion_Expire_Date"
                                            onChange={(e) =>
                                                setForm({ ...form, Completion_Expire_Date: e.target.value })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                <input type="submit" className="btn" value={'Add Certificate'} style={{ width: "20%", backgroundColor: "#e3e3e3", color: "#000" }} />
                                <input type="submit" className="btn" onClick={handleCertificate} value={"Submit"} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default AddCertificate;