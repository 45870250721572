import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
function Thanku() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
    <div className='Container'>
      <div  className='header_Container'>
         <h2>Plan<span id='highlight'> Acquired !</span></h2>
         <p style={{marginBottom:"0"}}>Thank You <span id='highlight'>!</span></p>
         <p style={{marginTop:"0"}}>Your purchase has been successful</p>
          <div id='background_img'>{ " "}</div>
        <button 
        onClick={()=> navigate(-2)} className='buton'>Go Home</button>
      </div>
      <div className='body_wrapper'>
      </div>
    </div>
    </>
  )
}

export default Thanku