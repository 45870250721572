import React, { useEffect, useState, useContext } from "react";
import { message } from "antd";
import { MyContext } from "../Mycontext";
import axios from "axios";
import manage from "../images/manage.png";
import comma from '../images/comma.png'
import { Link } from 'react-router-dom';
import { AiFillStar } from 'react-icons/ai';
function reviewContainer() {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [Reviews, setReviews] = useState([])

    const getReviews = async () => {
        const { data } = await axios.post(endPointUrl + '/admin/globalReviewfind', { Token });
        setReviews(data.data);

    }
    useEffect(() => {
        getReviews();
    }, [])
    return (
        <div className='HowItWorks' style={{ minHeight: "auto" }}>
            <div>
                <div className="home-howitwork">
                    <Link to="/">Home </Link>/{" "}
                    <Link to="/HireFreelancers">Reviews</Link>
                </div>
                <div style={{ margin: "20px 4%" }}>
                    <span className="jobpostheading" style={{ backgroundColor: "#ceefe4" }}>
                        <div>
                            Grow your brand with Digilanxer's user review 
                            <p> Discover real user opinions with Digilanxer's professional reviews.</p>
                        </div>

                        <img src={manage} alt="" style={{ marginTop: "20px" }} />
                    </span>
                </div>
                {/* <div className="Hire-Apart" style={{ backgroundColor: "rgb(208 241 230)" }}>
                    <div className="hire-Apart-text">
                        <h1>User Reviews</h1>
                        <p>
                         Grow your brand with Digilanxer's user review / Discover real user opinions with Digilanxer's professional reviews.
                        </p>
                    </div>
                    <div className="hire-Apart-img">
                        <img src={manage} alt="" style={{ bottom: "-6px" }} />
                    </div>
                </div> */}
            </div>
            <div className='review_container_box'>
                {Reviews?.map ((item, index)=>
                <div className="rectangle-parent-7" key={index}>
                    <img className='comma' src={comma} alt="Png File - Double Quotes Png@seekpng.com" />
                    <p className="reviews">
                            {item?.GlobalReview_Review}
                    </p>
                        <h2 className="aname">{item?.GlobalReview_Name}</h2>
                    <div className="star-2">
                            <i data-star={item?.Stars}></i>
                    </div>
                    </div>)}
            </div>

        </div>

    )
}

export default reviewContainer;