import img1 from '../images/Icons/quality.png'
import img2 from '../images/Icons/no-cost.png'
import img3 from '../images/Icons/secure.png'
import winboy from '../images/winboy.png'
const SectionDetails = () => {
  return (
    <div className="why-section">
      <div className="why-section-content">
        <div className="points">
          <div className="why-section-head">
            Why businesses turn to Digilanxer
          </div>

          <li>
            <div className='points-num'>01</div>
            <div className='points-icon'>
              <img src={img1} alt="" />
            </div>
            <div> Proof of Fixed Price
              <p>Our experienced freelancers provide accurate quotes and timelines, allowing businesses to plan and allocate their resources efficiently. </p>
            </div>
           
          </li>
          <li>
            <div className='points-num'>02</div>
            <div className='points-icon'>
              <img src={img2} alt="" />

            </div>
            <div> Proof of Hourly Rate
              <p> Our highly skilled freelancers track their time accurately, so businesses only pay for the hours worked.  </p>
            </div>
           
          </li>
          <li>
            <div className='points-num'>03</div>
            <div className='points-icon'>
              <img src={img3} alt="" />

            </div>
            <div> Proof of Recurring Payment
              <p>We establish a long-term partnership and receive ongoing support at a fixed monthly rate,ongoing support or maintenance services. </p>
            </div>
           
          </li>

        </div>
        <div className="why-img">
          <img src={winboy} alt="" />
        </div>
      </div>
      <div className="why-section-box">We are
        the world’s
        work
        marketplace</div>
    </div>
  );
};

export default SectionDetails;
