import logo from "../images/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { MyContext } from "../Mycontext";
import { useContext } from "react";
import search from "../images/search.png";
import Profile from "../images/emplyeer.png";
import notification from "../images/notification.png";
import message from '../images/message.svg';
import Sidebar from "./user-profile/Sidebar";
import axios from "axios";
const Header = () => {
  const { user, endPointUrl, Token } = useContext(MyContext);
  const Navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [sidebar, setsidebar] = useState(false);
  const [profile_progress, setProfile_progress] = useState();
  const handleMenu = (value) => {
    setshow(value);
  };
  const handleSidebar = (sidebar) => {
    setsidebar(!sidebar);
  };
useEffect(()=>{
 const getuser = async() =>{const {data} =await axios.post(endPointUrl+"/getUserById",{
  id:user?.id,Token
})
 setProfile_progress(data.data.profile_progress);
}
getuser();
},[user])
  return (
    <>
      <div>
        <nav className="group-nav">
          <div className="nav">
            <div className="menu-logo">
              <span
                className="material-symbols-outlined menubar"
                onClick={() => handleMenu(!show)}
              >
                menu
              </span>
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="digilancer logo" />
                </Link>
              </div>
            </div>
            <div className="links">
              <ul className="nav-links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/HireFreelancers">Hire Freelancers</Link>
                </li>
                <li>
                  <Link to="/UI-UX-Design">Find Jobs</Link>
                </li>
                <li>
                  <Link to="/How-It-Works">How It Works</Link>
                </li>
                <li>
                  <Link to="/whyDigilancer">Why Digilanxer</Link>
                </li>
              </ul>
            </div>

            <div className="buttons">
              {user?.Type === "Employer" || !user?.Type ? (
                <div className="post-job" style={{paddingBlock:"10px"}}>
                  {profile_progress > 50 ?
                    <button className="mil_buton" style={{ borderRadius: "5px" }} onClick={() => {
                      if (!user?.Type) {
                        Navigate("./login")
                      } else {
                        Navigate("./JobCreation")
                      }
                    }}>Post A Job</button>
                    : !user?.Type ?
                      <button className="mil_buton" style={{ borderRadius: "5px" }} onClick={() => Navigate("./login")}
                      >Post a Job</button>
                      :
                      <div className="iconTool icon_danger">
                        <button className="mil_buton disabled" style={{ borderRadius: "5px" }}
                        >Post a Job</button>
                        <div className="tooltip tooltip_dang">
                          Complete your profile atleast 50%
                        </div>
                      </div>}
                </div>
              ) : null}

              {!user?.Type ? (
                <div className="login-signup">
                  <button onClick={() => Navigate("./login")}>Login</button>
                  <button onClick={() => Navigate("./Signup")}>Sign Up</button>
                </div>
              ) : (
                <>
                  <div className="search-noti"
                  >
                    <img src={search} alt="search" />
                    <img src={message} alt="message" />
                    <img src={notification} alt="notification" />
                  </div>
                  <div
                    className="userName"
                    onClick={() => handleSidebar(sidebar)}
                  >
                    <img
                      className="header-img"
                      src={
                        user.Photo === "" || user.Photo == null
                          ? Profile
                          : `${endPointUrl}/images/${user.Photo}`
                      }
                      alt=""
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </nav>
        {show ? (
          <nav className="menu-bar">
            <div className="menu-links">
              <ul className="nav-links">
                <li onClick={() => setshow(!show)}>
                  <Link to="/">Home</Link>
                </li>
                <li onClick={() => setshow(!show)}>
                  <Link to="/HireFreelancers">Hire Freelancers</Link>
                </li>
                <li onClick={() => setshow(!show)}>
                  <Link to="/UI-UX-Design">Find Job</Link>
                </li>
                <li onClick={() => setshow(!show)}>
                  <Link to="/How-It-Works">How Its Works</Link>
                </li>
                <li onClick={() => setshow(!show)}>
                  <Link to="/whyDigilancer">Why Digilanxer</Link>
                </li>
              </ul>
            </div>
          </nav>
        ) : null}
      </div>
      {sidebar ? <Sidebar sidebar={sidebar} handleSidebar={handleSidebar} /> : null}
    </>
  );
};

export default Header;
