import { useContext, useEffect, useState } from "react";
import Profile from "../../images/freelancer.png";
import Frame from "../../images/Frame.svg"
import { message } from "antd";
import axios from "axios";
import Uploadimg from "./Uploadimg";
import { Link } from "react-router-dom";
import Userprofileupdate from "./userprofileupdate";
import { MyContext } from "../../Mycontext";
import ProgressBar from "../ProgressBar";
import { MdVerified } from 'react-icons/md';
import { Popup } from "semantic-ui-react";
import { AiFillStar } from 'react-icons/ai';
import img from '../../images/veryfied.png';
function Employerprofile() {
  const { user, setuser, endPointUrl, Token } = useContext(MyContext);
  const [counter, setCounter] = useState(0);
  const [bgColor, setbgColor] = useState('');

  const [uploadImg, setUploadImg] = useState(false);
  const handleuploadImg = () => {
    setUploadImg(!uploadImg);
  };
  const labelStyles = {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    color: '#5E5E5E',
    fontSize: '12px'
  }

  const progress = () => {
    (setCounter((counter) => counter = 0));
    (user?.About) ? (setCounter((counter) => counter + 17)) : (null),
      (user?.Verified_Profile == "Verified") ? (setCounter((counter) => counter + 20)) : (null),
      (user?.Photo) ? (setCounter((counter) => counter + 10)) : (null),
      // (user?.Last_Name) ? (setCounter((counter) => counter + 5)) : (null),

      (user?.Name) ? (setCounter((counter) => counter + 10)) : (null),
      (user?.Address) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Gender) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Mobile) ? (setCounter((counter) => counter + 10)) : (null),

      (user?.City) ? (setCounter((counter) => counter + 7)) : (null),
      (user?.Email) ? (setCounter((counter) => counter + 7)) : (null),
      (user?.Language) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.State) ? (setCounter((counter) => counter + 2)) : (null),
      (user?.Country) ? (setCounter((counter) => counter + 2)) : (null)
  }

  const gradient = () => {
    (counter < 25) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg,rgba(7,178,116,1), #00ffff)')) : (
      (counter < 70) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1), #00ffff, #c4fdff)'))
        : (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1) 25%, #00ffff 63%, rgba(51,154,250,1) 90%)'))
    )
  }
  const profileProgress = async () => {
    try {
      await axios.post(endPointUrl + "/updateUserProfile", {
        profile_progress: counter,
        id: user.id, Token
      });
      setuser({ ...user, profile_progress: counter })
    } catch { message.error("could not update progress") }
  };
  useEffect(() => {
    profileProgress();
    gradient();
  }, [counter])

  useEffect(() => {

    progress();
  }, [user]);
  return (
    <div className="HowItWorks">
      <div className="home-howitwork">
        <Link to="/">Home </Link>/{" "}
        <Link to="/userProfile">{user.Name} {user.Last_Name}</Link>
      </div>
      <div className="profile-Apart">
        {!uploadImg ? (
          <img
            src={
              user.Photo === "" || user.Photo == null
                ? Profile
                : `${endPointUrl}/images/${user.Photo}`
            }
            alt=""
            onClick={() => handleuploadImg()}
          />
        ) : (
          <Uploadimg handleuploadImg={handleuploadImg} userid={user.id} />
        )}
        <div className="user-name">
          <img src={img} alt="" style={{ filter: user.Verified_Profile == "Verified" ? "none" : "grayscale(1)", width: "23px", height: "auto", marginLeft: "auto", position: "absolute", top: "15px", right: "0px" }} />
          {/* <Popup
            trigger={user.Verified_Profile == "Verified" ? <MdVerified style={{ color: "rgb(7 178 116)", marginLeft: "auto", height: "auto", fontSize: "20px" }} /> :
              <MdVerified style={{ color: "rgb(133 133 133)", marginLeft: "auto", fontSize: "20px", height: "auto", position: "absolute", right: 0 }} />}
            content={user.Verified_Profile == "Verified" ? "Profile Verified" :
              user.Verified_Profile == "Pending" ? "Profile verification Pending" :
                "Profile Unverified"}
          /> */}
          <h2>
            {user?.Name} {user.Last_Name}
          </h2>
          {/* <span>{user?.Last_Name}</span> */}
        </div>
        <div className="user-reviews-address">
          <span className="rating" style={{ paddingLeft: "8px", fontSize: "16px" }}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 16 15" fill="#FF8A00" className="rating-star">
            <path d="M8.00027 12.1734L3.29827 14.8054L4.34827 9.52004L0.391602 5.86137L5.74293 5.22671L8.00027 0.333374L10.2576 5.22671L15.6089 5.86137L11.6523 9.52004L12.7023 14.8054L8.00027 12.1734Z" fill="#FF8A00" />
          </svg> */}
            <AiFillStar className="featuredfreelancer-rating-star" />
            {user.avg_Rating ? user.avg_Rating : 0} <p>({user.no_of_reviews ? user.no_of_reviews : 0} Reviews)</p></span>
          <span>|  <img src={Frame} alt="location" style={{ paddingRight: "3px", paddingLeft: "18px", width: "18px" }} /> {user?.City} {user?.City ? "," : null} {user?.Country}</span>
        </div>
      </div>
      <div className="about-rate employer-about-rate">
        <div className="about" style={{ width: "68%" }}>
          <b>About</b>
          <p>{user?.About}</p>
        </div>
        <div className="skills-tech-container"> <div className="rate-card">
          <Userprofileupdate userDetail={user} />
          <div className="profile-complete" style={{ borderRadius: "10px", marginBottom: "20px", background: "#0cfca415" }}>
            <span style={labelStyles}><span style={{ fontWeight: "600", color: "black", marginRight: "3px" }}>{counter}% </span> Profile complete</span>
            <div className="progress">
              <ProgressBar bgColor={bgColor} completed={counter} />
            </div>
          </div>

          <h2 style={{ marginBlock: "auto" }}>Profile</h2>




          <div className="user-details" style={{ marginTop: "20px" }}>
            <p>Location</p>
            <span>{user?.City} {user?.City ? "," : null} {user?.Country}</span>
          </div>
          <hr style={{ borderTop: '1px solid #E4E4E4' }} />
          <div className="user-details">
            <p>Verification Status</p>
            <span>{(user?.Verified_Profile == "Verified") ? "Verified" : "Unverified"}</span>
          </div>
          <hr style={{ borderTop: '1px solid #E4E4E4' }} />
          <div className="user-details">
            <p>Language</p>
            <span>
              {user?.Language?.split(', ').join(', ')}

            </span>
          </div>
          <hr style={{ borderTop: '1px solid #E4E4E4' }} />
          <div className="user-details">
            <p>Gender</p>
            <span>{user?.Gender}</span>
          </div>
          <hr style={{ borderTop: '1px solid #E4E4E4' }} />
          <div className="user-details">
            <p>Email</p>
            <span>{user?.Email}</span>
          </div>
          <hr style={{ borderTop: '1px solid #E4E4E4' }} />
          <div className="user-details">
            <p>Phone Number</p>
            <span>+91 - {user?.Mobile}</span>
          </div>

        </div>
        </div>

      </div >
    </div >
  );
}

export default Employerprofile;
