import React, { useState, useContext, useEffect } from "react";
import { MyContext } from "../../Mycontext";
import Selectant from "./Select";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { message } from "antd";
import { CKEditor } from "ckeditor4-react";
import Keywords from "./Keywords";
function EditJob({ Job, getjobs,button ,setdetailview,handlejobClick}) {
  const [showmodel, setshowModel] = useState(false);
  const { endPointUrl, Token } = useContext(MyContext);
  const [skillmaster, setskillmaster] = useState([]);
  const [technologyMaster, settechnologyMaster] = useState([]);
  const [categoryMaster, setCategorymaster] = useState([]);
  const [tags, setTags] = useState(Job.Keywords ? Job.Keywords.split(",") : []);
  const [skillSelected, setskillSelected] = useState(
    Job.SkillsRequired.split(",")
  );
  const [technologySelected, settechnologySelected] = useState(
    Job.Technology ? Job.Technology.split(",") : []
  );
  const [categorySelected, setCategorySelected] = useState(
    Job.Technology ? Job.Technology.split(",") : []
  );
  const handleskillsmaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setskillmaster(a);
  };
  const handletechnologyMaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getAlltechnology", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    settechnologyMaster(a);
  };
  const handlecategoryMaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getallcategory", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setCategorymaster(a);
  };
  const [job, setjob] = useState({
    Title: Job.Title,
    Date: Job.Date,
    Budget_From: Job.Budget_From,
    Budget_To: Job.Budget_To,
    Budget_Type: Job.Budget_Type,
    Expiry_Date: Job.Expiry_Date,
    Priority: Job.Priority,
    ShortDesc: Job.ShortDesc,
    Type: Job.Type,
    SkillsRequired: Job.SkillsRequired,
    Technology: Job.Technology,
    Category: Job.Category,
    Description: Job.Description,
    Keywords:Job.Keywords,
  });
  const handleExpModel = () => {
    setshowModel(true);
    console.log(Job);
  };
  const CloseModel = () => {
    setshowModel(false);
  };
  const handleSubmitUseredit = async (data) => {
    try {
      await axios.post(endPointUrl + "/user/UpdateJobs", {
        ...data,
        JobID: Job.JobID,
        SkillsRequired: skillSelected.join(","),
        Technology: technologySelected.join(","),
        Category: categorySelected.join(","),
        Keywords:tags.join(","),
        Token,
      });
     if(button){
      handlejobClick()
      setdetailview(false);

     }
      setshowModel(false);
      message.success(" Job updated SuccessFully");
      getjobs();
    } catch {
      message.error("Job is Not Updated");
    }
  };
  const onEditorChange = (evt, editor) => {
    const data = evt.editor && evt.editor.getData();
    setjob({ ...job, Description: data });
  };
  useEffect(() => {
    handleskillsmaster();
    handlecategoryMaster();
    handletechnologyMaster();
  }, []);
  return (
    <>
      {button? <button
                  style={{
                    background: "#283c0ade",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleExpModel()}
                >
                 Edit 
                </button>:<FaEdit
        className="btn"
        style={{
          padding: "4px",
          margin: "2px 4px",
        }}
        onClick={() => handleExpModel()}
      >
        Edit
      </FaEdit>}
      <div
        id="myModal"
        className={showmodel ? "model-open" : "model"}
        style={{ overflow: "scroll" }}
      >
        <div className="modal-content" style={{color:'black'}}>
          <span className="close" onClick={CloseModel}>
            &times;
          </span>
          <h2>Edit Job Post</h2>
          <form
            className="model-field exp-form "
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitUseredit(job);
            }}
          >
            <div>
              <div>
                <input
                  type="text"
                  name="Title"
                  placeholder="Title"
                  value={job.Title}
                  onChange={(e) => setjob({ ...job, Title: e.target.value })}
                />
                <input
                  type="number"
                  name="Budget_From"
                  value={job.Budget_From}
                  step={"0.01"}
                  placeholder="Enter Your Min Budget"
                  onChange={(e) =>
                    setjob({ ...job, Budget_From: parseFloat(e.target.value) })
                  }
                />
                <input
                  value={job.Budget_To}
                  type="number"
                  name="Budget_To"
                  step={"0.01"}
                  placeholder="Enter Your Max Budget"
                  onChange={(e) =>
                    setjob({ ...job, Budget_To: parseFloat(e.target.value) })
                  }
                />
                {/* <select
                  type="text"
                  name="Budget_Type"
                  style={{ width: "95%" }}
                  defaultValue={job.Budget_Type}
                  onChange={(e) =>
                    setjob({ ...job, Budget_Type: e.target.value })
                  }
                >
                  <option value="hour">Hour</option>
                  <option value="day">Day</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select> */}
                <label htmlFor="Completion_Date" style={{display:'block'}}>Completion_Date</label>
                <input
                  value={job.Completion_Date}
                  type="date"
                  name="Completion_Date"
                  placeholder="Enter Project Completion_Date"
                  onChange={(e) =>
                    setjob({ ...job, Completion_Date: e.target.value })
                  }
                />
              </div>
              <div>
                <input
                  value={job.Type}
                  type="text"
                  name="Type"
                  placeholder="Enter Your Priority"
                  onChange={(e) => setjob({ ...job, Type: e.target.value })}
                />

                <div>
                  {" "}
                  <Selectant
                    label="Skills"
                    options={skillmaster}
                    setselected={setskillSelected}
                    selected={skillSelected}
                  />
                </div>
                <div style={{ marginTop: "17px" }}>
                  <Selectant
                    label="Technology"
                    options={technologyMaster}
                    setselected={settechnologySelected}
                    selected={technologySelected}
                  />
                </div>
                <div style={{ marginTop: "17px" }}>
                  <Selectant
                    label="Category"
                    options={categoryMaster}
                    setselected={setCategorySelected}
                    selected={categorySelected}
                  />
                </div>
                <Keywords tags={tags} setTags={setTags} />
              </div>
            </div>
            <label htmlFor="Description">Description</label>
            <article style={{ display: "flex", marginTop: "20px" }}>
              <textarea
                style={{ flex: "2", marginRight: "5px" }}
                value={job.ShortDesc}
                name="ShortDesc"
                onChange={(e) => setjob({ ...job, ShortDesc: e.target.value })}
              ></textarea>
              <CKEditor
                style={{ flex: "3" }}
                initData={job.Description}
                config={{
                  toolbar: [
                    { name: "document", items: ["Undo", "Redo"] },
                    { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                    { name: "styles", items: ["Format"] },
                    {
                      name: "basicstyles",
                      items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                    },
                  ],

                }}
                onChange={onEditorChange}
              />
            </article>
            <input
              type="submit"
              className="btn"
              value={"Submit"}
              style={{ marginTop: "10px" }}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default EditJob;
