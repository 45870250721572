import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { MyContext } from "../../../Mycontext";
import deletebutton from '../../../images/delet2.png'
import { message } from 'antd';

function EditCertificate({ Certificate, getCertificate }) {
    const { user, endPointUrl, Token } = useContext(MyContext);


    // Delete Certificate
    const deleteCertificate = async () => {
        try {
            await axios.post(endPointUrl + "/user/deleteCertiDoc", {
                Token,
                
                C_Id: Certificate.C_Id
            });
            message.success("Certificate Deleted");
            getCertificate();
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <div className="edit-icons">
                <img id="del-btn" src={deletebutton} alt="delete" onClick={() => deleteCertificate()} />
            </div>
        </>
    )
}

export default EditCertificate