import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { MyContext } from "../../Mycontext";
import moment from "moment";
import { useNavigate } from "react-router";
import Master from "./Master";
import { message } from "antd";
import { AiOutlineMessage } from "react-icons/ai";
function BiddingUserInformation({ change, item, Show }) {
  const { Token, endPointUrl, user } = useContext(MyContext);
  const [showmodel, setshowModel] = useState(false);
  const [bidder, setbidder] = useState({});
  const [Count, setCount] = useState(0);
  const [milestone, setmilestone] = useState([]);
  const [ID, setID] = useState();
  const Navigate = useNavigate();

  function handlechange() {
    setID(item.UserID);
    change(ID);
  }
  const getuserData = async (id) => {
    const { data } = await axios.post(endPointUrl + "/getUserById", {
      Token,
      id,
    });
    setbidder(data.data);
    // console.log(Show);
    console.log("BID ID", item.Bid_ID);
    const result = await axios.post(endPointUrl + "/project/getMilestoneforBid", {
      Token,
      Bid_ID: item.Bid_ID,
    });
    console.log("getMilestone BId", result);

    if (result.data.milestones.length !== 0) {
      setmilestone(result.data.milestones);
    } else {
      console.log("Enter in Else")
      const result = await axios.post(endPointUrl + "/user/getMilestoneforBid", {
        Token,
        Bid_ID: item.Bid_ID,
      });
      setmilestone(result.data.milestones);
      console.log("getMilestone BId", result);

    }

    // console.log(result.data.milestones);

  };
  const getCount = () => {
    for (let index = 0; index < milestone.length; index++) {
      const item = milestone[index];
      if (item.MilstoneStatus === "Up for Review") {
        setCount((Count) => Count + 1);
      }
    }
  };
  useEffect(() => {
    setCount((Count) => Count = 0)
    getCount()
  }, [milestone]);
  const handleExpModel = async () => {
    if (item.View_Status === false) {
      await axios.post(endPointUrl + "/user/updateBid", { Token, Bid_ID: item.Bid_ID, View_Status: true });
    }
    setshowModel(true);
    document.body.classList.add("modal-open");
  };
  const CloseModel = () => {
    setshowModel(false);
    document.body.classList.remove("modal-open");
  };

  function extractFirst100Words(htmlString) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    const textContent = tempElement.textContent || tempElement.innerText || "";
    const words = textContent.trim().split(/\s+/).slice(0, 30);
    const first100Words = words.join(" ");

    return first100Words;
  };

  const handleApprovedReject = async (status) => {
    try {
      await axios.post(endPointUrl + "/user/updateBid", { Token, Bid_ID: item.Bid_ID, Bid_Status: status })
      message.success(`Bid ${status} successfully`)
      CloseModel();
    } catch {
      message.error("Some occur on Approved/Rejection");
    }
    window.location.reload(false);

  }
  useEffect(() => {
    getuserData(item.UserID);
    // console.log(item.Bid_ID);
  }, []);
  return (
    <div>
      {Show ?
        <>
          <div
            className="current-jobs"
            style={{ cursor: "pointer", marginTop: '0px' }}
            onClick={() => handleExpModel()}
          >
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={require("../../images/emplyeer.png")} alt="" style={{ width: '70px', marginRight: '15px', marginBottom: '10px' }} />
                <div>
                  <span
                    className="job-name"
                    // onClick={() => {
                    //   setdetailview(!detailview);
                    //   setdetailjob(item);
                    //   window.scrollTo(0, 0);
                    // }}
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    {bidder.Name}
                  </span>
                  <div className="current-tags">
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                      if (index < 5) {
                        return val.length > 0 ? <span>{val}</span> : null
                      }
                    })}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? "+" : ""}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 5 : ""}

                  </div>
                </div>
              </div>
              <p>{extractFirst100Words(item.Proposal)}...</p>

              {/* <div id="keywords">
                        {(item?.Keywords ? item.Keywords.split(",") : []).map(
                          (skill) => {
                            return skill.length > 0 ? (
                              <span> #{skill}</span>
                            ) : null;
                          }
                        )}
                      </div> */}
            </div>
            <div>
              <div>
                <div className="avg-bite">
                  <strong>$ {item.BidAmount}</strong>
                </div>
                <span className="total-bits"> {milestone.length} milestone</span>
                <div className="total-bits">{moment(item.Bid_date).format("DD-MM-YYYY")}</div>
              </div>
            </div>
          </div>
          <div
            id="myModal"
            className={showmodel ? "model-open" : "model"}
            style={{ overflow: "scroll" }}
          >
            <div className="modal-content" style={{ color: "black" }}>
              <span className="close" onClick={CloseModel}>
                &times;
              </span>

              <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <div style={{ flex: '1' }}>
                  <img src={require("../../images/emplyeer.png")} alt="" />
                </div>
                <div style={{ flex: '5' }}>
                  <a
                    className="job-name"
                    // onClick={() => {
                    //   setdetailview(!detailview);
                    //   setdetailjob(item);
                    //   window.scrollTo(0, 0);
                    // }
                    href={`/userProfile/${bidder.Name}-${bidder.id}`}
                    style={{
                      marginLeft: "10px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                    target="_blank" rel="noopener noreferrer"
                  // onClick={()=>Navigate(`/userProfile/${bidder.Name + "-" + bidder.id}`)}
                  >
                    {bidder.Name}
                  </a>
                  <div className="current-tags">
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                      if (index < 5) {
                        return val.length > 0 ? <span>{val}</span> : null
                      }
                    })}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? "+" : ""}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 5 : ""}

                  </div>
                </div>
                <div className="biddingInformation" style={{ flex: '2' }}>
                  <div>
                    <span className="bidhead">Price</span>
                    <span id="bidheadValue">${item.BidAmount}</span>
                  </div>
                  <div>
                    <span className="bidhead">Duration</span>
                    <span id="bidheadDuration">
                      {item.Duration + "/"}
                      {item.DurationType}
                    </span>
                  </div>
                </div>

                {item.Bid_Status === "Reject" ?
                  <button className="mil_buton" style={{ backgroundColor: "brown", borderColor: "brown", borderRadius: "10px" }} onClick={() => { handleApprovedReject("Submitted") }}>
                    Back to Review</button>
                  :
                  <div className="biddingButton" style={{ flex: '2' }}>
                    <button style={{ background: "green" }}
                      // onClick={()=>{handleApprovedReject("Approved"),updateAppprovedReject("Approved")}}
                      onClick={() => { Navigate('/milestonePayment', { state: { milestone: milestone, item: item } }); CloseModel() }}
                    >
                      Approve</button>
                    <button style={{ background: "#d70404" }} onClick={() => { handleApprovedReject("Reject") }}>
                      Reject</button>
                  </div>
                }
              </div>

              <b style={{ marginTop: "10px" }}>Proposal</b>
              <span dangerouslySetInnerHTML={{ __html: item.Proposal }} className="biddingProposal"></span>
              <Master milestone={milestone} />
            </div>
          </div>
        </>
        : <div className="modal-content" style={{ color: "black", width: "90%", margin: "0" }}>

          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div style={{ flex: '1' }}>
              <img src={require("../../images/emplyeer.png")} alt="" />
            </div>
            <div style={{ flex: '5' }}>
              <a
                className="job-name"
                // onClick={() => {
                //   setdetailview(!detailview);
                //   setdetailjob(item);
                //   window.scrollTo(0, 0);
                // }
                href={`/userProfile/${bidder.Name}-${bidder.id}`}
                style={{
                  marginLeft: "10px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
                target="_blank" rel="noopener noreferrer"
              // onClick={()=>Navigate(`/userProfile/${bidder.Name + "-" + bidder.id}`)}
              >
                {bidder.Name}
              </a>
              {/* <div className="jobContainer_header">
        <p style={{margin:"0 0 10px 10px"}}
        onClick={handlechange}
        >message<AiOutlineMessage/></p>
        </div> */}
              <div className="current-tags">
                {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                  if (index < 5) {
                    return val.length > 0 ? <span>{val}</span> : null
                  }
                })}
                {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? "+" : ""}
                {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 5 : ""}

              </div>
            </div>
            <div className="biddingInformation" style={{ flex: '3' }}>
              <div>
                <span className="bidhead">Price</span>
                <span id="bidheadValue">${item.BidAmount}</span>
              </div>
              <div>
                <span className="bidhead">Duration</span>
                <span id="bidheadDuration">
                  {item.Duration + "-"}
                  {item.DurationType === "monthly" ? "month" : item.DurationType === "weekly" ? "week" : "hour"}
                </span>
              </div>
            </div>
          </div>

          <b style={{ marginTop: "10px" }}>Proposal</b>
          <span dangerouslySetInnerHTML={{ __html: item.Proposal }} className="biddingProposal"></span>
          <Master milestone={milestone} />
          <button className='mil_buton'
            style={{ marginInline: "27%", marginTop: "2em" }}
            onClick={() => { Navigate('/milestonePayment', { state: { milestone: milestone, item: item } }) }}
          >Review Milestone Pyments ( {Count} )</button>
        </div>}
    </div>
  );
}

export default BiddingUserInformation;
