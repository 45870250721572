import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
// import Selectant from "../Jobs/Select";
import {MyContext} from "../../Mycontext";


function Keywords({tags,setTags}) {
  const [inputValue, setInputValue] = useState("");
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      addTag(inputValue);
    }
  }

  function handleBlur() {
    addTag(inputValue);
  }

  function addTag(tag) {
    if (tag.trim() === "") {
      return;
    }
    setTags([...tags, tag.trim()]);
    setInputValue("");
  }

  function removeTag(indexToRemove) {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  }

  function handleChange(event) {
    setInputValue(event.target.value);
  }

  return (
    <div className="tags"  style={{marginTop:'10px'}}>
      {tags?.map((tag, index) => (
        <span key={index} className="tag">
          #{tag}
          <button
          className="mil_buton"
            type="button"
            onClick={() => removeTag(index)}
            aria-label={`Remove ${tag}`}
          >
            ×
          </button>
        </span>
      ))}
      <input
        type="text"
        placeholder="Add Keywords..."
        value={inputValue}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </div>
  );
}
export default Keywords