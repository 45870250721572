import React from "react";

const ProgressBar = (props) => {

  const { bgColor, completed } = props;

  const containerStyles = {
    height: '10px',
    width: '80%',
    border: '1px solid #0d0d0d36',
    /* padding: 0px; */
    backgroundColor: 'rgb(248 248 248)',
    borderRadius: '50px'
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: "#07b273",
    // backgroundImage: bgColor,
    borderRadius: 'inherit',
    textAlign: 'left',
    transition: "all .3s ease"
  }




  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>

      </div>
    </div>
  );
};

export default ProgressBar;