import React, { useState, useContext, useEffect } from 'react'
import axios from "axios";
import { message } from 'antd';
import { MyContext } from '../Mycontext';

function Rating({ P_ID, JobID, show, freelancer }) {
  console.log(P_ID)
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [reviews, setReviews] = useState("");
  const [reviewd, setReviewd] = useState([]);
  const [employer, setEmployer] = useState(null);
  const [status, setStatus] = useState("");

  const getemployer = async (JobID) => {
    const { data } = await axios.post(endPointUrl + "/user/getemployerByjobID", {
      Token,
      JobID: JobID,
    })
    setEmployer(data.data.id);
  };
  const checkStatus = async (P_ID) => {
    const { data } = await axios.post(endPointUrl + "/getProjectbyId", {
      Token,
      P_ID: P_ID,
    });
    setStatus(data.data.Project_status);
  };
  const getreviews = async (P_ID, freelancer) => {
    const { data } = await axios.post(endPointUrl + "/getReviewById", {
      Token,
      From_ID: user.id,
      To_ID: freelancer ? freelancer : employer,
      P_ID: P_ID,
    })
    setReviewd(data.data);
  };
  useEffect(() => {
    if (employer) {
      getreviews(P_ID)
    }
  }, [employer]);

  const updateRating = async (P_ID) => {
    if (rating === 0 || reviews === "") {
      message.warning("Rating or Review can not be empty")
    } else {
      try {
        const { data } = await axios.post(endPointUrl + "/addreview", {
          Token,
          From_ID: user.id,
          To_ID: employer,
          P_ID: P_ID,
          Stars: rating,
          Review: reviews
        })
        message.success("Review submitted")
        window.location.reload(false);
      } catch {
        message.error("some error occured")
      }
    }
  };
  const onChange = (e) => {
    setReviews(e.target.value);
  };
  const onSubmit = () => {
    updateRating(P_ID);
  }
  useEffect(() => {
    if (freelancer) {
      getreviews(P_ID, freelancer);
      checkStatus(P_ID);

    } else if (JobID && P_ID) {
      getemployer(JobID, P_ID);
      checkStatus(P_ID);

    }
  }, [JobID, P_ID, freelancer])
  return (
    <>
      {show === "Released" || status === "Closed" ? reviewd?.length === 0 ?
        <div className="Review-container">
          <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={index <= (hover || rating) ? "on" : "off"}
                  onClick={() => setRating(index)}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(rating)}
                >
                  <span className="star-rating-star">&#9733;</span>
                </button>
              );
            })}
          </div>
          <div >
            <div className='reviews-form' >
              <span>Rate your Experience working with this {freelancer ? "Freelancer" : "Employer"}</span>
              <textarea
                className="reviews-form-input"
                type="text"
                cols={40}
                rows={3}
                placeholder="Enter your review"
                value={reviews}
                onChange={onChange}
              />
              <button className='buton' onClick={onSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div> :
        <div className='Review-container'>
          <h3>Review <span id='highlight'>Submitted !</span></h3>
        </div>
        : null}
    </>
  );
}

export default Rating