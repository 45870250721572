import React, { useEffect, useContext, useState } from "react";
import { MyContext } from "../../Mycontext";
import axios from "axios";
import moment from "moment";
import BiddingUserInformation from "./BiddingUserInformation";
import Rating from "../Rating";

function BiddingCard({ change, JobID, Show }) {
  const { Token, endPointUrl } = useContext(MyContext);
  const [bits, setBits] = useState([]);
  const [freelancer, setfreelancer] = useState(null);
  const [BidID, setBidID] = useState(null);
  const [P_ID, setP_ID] = useState(null);
  const [reviewShow, setreviewShow] = useState(null);
  const [employebit, setemployebit] = useState([]);
  const [totallength, settotallength] = useState(0);
  const [Allbits, setAllBits] = useState({
    New: [],
    Review: [],
    Approved: [],
    Rejected: [],
  });
  const [activejob, setActivejob] = useState(0);
  const handlejobClick = (buttonIndex) => {
    setActivejob(buttonIndex);
  };
  const gettotalbits = async (JobID) => {
    const { data } = await axios.post(endPointUrl + "/user/job/totalBid", {
      Token,
      JobID,
    });
    settotallength((data.data).length)
    const New = data.data.filter((item) => item.View_Status === false);
    const Review = data.data.filter(
      (item) => item.Bid_Status === "Submitted" && item.View_Status
    );
    const Approved = data.data.filter(
      (item) => item.Bid_Status === "Approved" && item.View_Status
    );
    const Rejected = data.data.filter(
      (item) => item.Bid_Status === "Reject" && item.View_Status
    );
    setActivejob(0);
    setBits(New);
    setAllBits({ New, Review, Approved, Rejected });
    setemployebit(Approved);
    setfreelancer(Approved[0].UserID);
    setBidID(Approved[0].Bid_ID);
  };
  useEffect(() => {
    if (BidID) {
      getproject(BidID)
    }
  }, [BidID])
  const getproject = async (BidID) => {
    const { data } = await axios.post(
      endPointUrl + "/project/getMilestoneforBid",
      { Bid_ID: BidID, Token }
    );
    const mil = data.milestones;
    setreviewShow(mil[mil.length - 1].FreelancerPaymentStatus);
    setP_ID(mil[0].P_ID);
  };

  // sorting based on price
  const handleSortByAmount = (value) => {
    const sortedBits = [...bits]; // Create a copy of the 'bits' array
    if (value === "low")
      sortedBits.sort((a, b) => a.BidAmount - b.BidAmount);
    else if (value === "high")
      sortedBits.sort((a, b) => b.BidAmount - a.BidAmount);
    else if (value === "new") {
      sortedBits.sort((a, b) => moment(a.Bid_date).diff(moment(b.Bid_date)));
    }
    else if (value === "old") {
      sortedBits.sort((a, b) => moment(b.Bid_date).diff(moment(a.Bid_date)));
    }
    // Sort by the 'Amount' property in ascending order
    setBits(sortedBits); // Update the state with the sorted array
  };


  useEffect(() => {
    gettotalbits(JobID);
  }, []);
  return (
    <>
      {Show ?
        <div className="jobContainer">
          <span>Bids({totallength})</span>
          <div style={{ display: "inline-block", float: 'right' }}>
            <select name="Bits" id="Bits" style={{
              background: "#ffffff",
              borderRadius: "3px",
              outline: "none", fontSize: "12px", padding: '5px 0'
            }} onChange={(e) => handleSortByAmount(e.target.value)} >
              <option>Sort</option>
              <option value="low">Low to High</option>
              <option value="high">High to Low</option>
              <option value="new">Latest</option>
              <option value="old">Oldest</option>
            </select></div>
          <div className="jobMatch">
            <span
              style={
                activejob === 0
                  ? {
                    borderBottom: "4px solid #07b273",
                  }
                  : null
              }
              onClick={() => {
                setBits(Allbits.New);
                handlejobClick(0);
              }}
            >
              New({Allbits.New.length})
            </span>
            <span
              style={
                activejob === 1
                  ? {
                    borderBottom: "4px solid #07b273",
                  }
                  : null
              }
              onClick={() => {
                setBits(Allbits.Review);
                handlejobClick(1);
              }}
            >
              Review({Allbits.Review.length})
            </span>
            <span
              style={
                activejob === 2
                  ? {
                    borderBottom: "4px solid #07b273",
                  }
                  : null
              }
              onClick={() => {
                setBits(Allbits.Approved);
                handlejobClick(2);
              }}
            >
              Approved({Allbits.Approved.length})
            </span>
            <span
              style={
                activejob === 3
                  ? {
                    borderBottom: "4px solid #07b273",
                  }
                  : null
              }
              onClick={() => {
                setBits(Allbits.Rejected);
                handlejobClick(3);
              }}
            >
              Rejected({Allbits.Rejected.length})
            </span>
          </div>
          <hr />
          {bits.length > 0 ? bits.map((item) => (
            <div className="bittingCard">
              <BiddingUserInformation item={item} Show={Show} />
            </div>
          )) : <span>No bits</span>}
        </div>
        : <div className="jobContainer" style={{ border: "none", marginTop: "0", padding: "0", paddingTop: "30px" }}>
          <Rating P_ID={P_ID} show={reviewShow} freelancer={freelancer} />
          <div className="jobContainer_header">
            <span>Freelancer Details</span>
          </div>
          {employebit.map((item) => (
            <div className="bitttingCard">
              <BiddingUserInformation change={change} item={item} Show={Show} />
            </div>
          ))}
        </div>
      }
    </>
  );
}

export default BiddingCard;
