import React from 'react'

function Forgot() {
  return (
    <><div className="blur"></div>
       <div className='forgot-password'>
        <span>Forgot Password</span>
        <span>Enter your email address below and we will send you a link
where you can enter a new password.
</span><input type="text" placeholder='Your Email' required/>
       <button className='btn'>Get OTP</button>
       </div>
    </>
  )
}

export default Forgot;