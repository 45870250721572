import React, { useContext } from 'react';
import { MyContext } from '../../Mycontext';
import { Link, useNavigate } from "react-router-dom";
import ChangePassword from './ChangePassword';
import dashboard from '../../images/Menu Icon/dashboard.png';
import profile from '../../images/Menu Icon/profile-icon.png';
import password from '../../images/Menu Icon/passwordicon.png';
import logout from '../../images/Menu Icon/logout.png';
const Sidebar = ({ sidebar, handleSidebar }) => {
    const { user, setuser } = useContext(MyContext)
    const Navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        setuser(null);
        handleSidebar(sidebar);
        Navigate('/');
    }
    return (
        <div className='navbar_sidebar'>
            <ul>
                <span>
                    <img src={dashboard} alt="" />
                    <li onClick={() => {
                        Navigate(`/${user.Type == 'Employer' ? 'EmployerJobProfile' : 'FreelancerJobProfile'}`)
                        handleSidebar(sidebar);
                    }}>Dashboard</li>
                </span>

                <span>
                    <img src={profile} alt="" />
                    <li onClick={() => {
                        user.Type == "Employer"
                            ? Navigate("/employerprofile")
                            : Navigate("/loginUserProfile");
                        handleSidebar(sidebar);
                    }}>Profile</li>
                </span>

                <span>
                    <img src={password} alt="" />
                    <li><ChangePassword /></li>
                </span>
                <span>
                    <img src={logout} alt="" />
                    <li style={{ borderBottom: "none" }} onClick={() => handleLogout()}>Logout</li>
                </span>
                {/* {user.Type == "Freelancer" ? <li onClick={() => {Navigate("/SkillTest"), handleSidebar(sidebar)}}>Skill Test</li> :null} */}
            </ul>
        </div>
    );
}

export default Sidebar;
