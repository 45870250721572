
import tshirt from'../images/tshirt.jpg'
import Arrow from'../images/right-arrows.png'
const TShirtDesignCard = () => {

  return (
    <div className="card1">
      <img src={tshirt} alt="" />
      <div className="card-content">
        <div className="tags-and-time">
          <div className="design">DESIGN</div>
          <div className="time">July 15, 2023</div>
        </div>
        <div className="blog-head">How to Design a T-Shirt</div>
        <div className="blog-content">
        There are a million reasons you might want to design a t-shirt. Perhaps you have a large charity event coming up and want to make a splash with a free shirt for the participants, or maybe you need a shirt that features your.
        </div>
      </div>
      <div className="right-arrow">
        <img src={Arrow} alt="" />
      </div>
    </div>
  );
};

export default TShirtDesignCard;
