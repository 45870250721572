import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import Certificate from '../../images/Certificate.png'
import { MyContext } from '../../Mycontext'
import {BsArrowRightCircle} from 'react-icons/bs'
import { useNavigate } from 'react-router'
import { Link } from "react-router-dom";

function SkillTest() {
    const { endPointUrl, Token, user } = useContext(MyContext);
    const [Tests, setTests] = useState([]);
    const [showmodel, setshowModel] = useState(false);
    const [Index, setIndex] = useState(0);
    const [assessment, setAssessment] = useState({
        Test_taken: 0,
        Badges: 0
    });
    const navigate = useNavigate();

    const getTests = async () => {

        const { data } = await axios.post(endPointUrl + '/getSkilltestforfreelancer', {
            Token,
            id: user.id
        })
        setTests(data.data);
        let Tests_taken = data.assesment.length;
        let Badges = data.assesment.filter((item)=> {
        return item.Result === "Pass";
    });
    setAssessment({ Test_taken: Tests_taken, Badges: Badges.length });
    };
    const handleSkillModel = (index) => {
        setIndex(index);
        setshowModel(true);
      };
    const CloseModel = () => {
        setshowModel(false);
      };
    useEffect(() => {
        window.scroll(0,0)
        getTests();
    }, [])
    return (
        <div className='skillTest-container'>
            <div className="home-howitwork" style={{ position: "absolute", top: "101px", left: "51px" }}>
                <Link to="/FreelancerJobProfile">Home </Link>/{" "}
                <Link to="/SkillTest">Skill Test </Link>

            </div>
            <div className="skillTest-sidePanel">
                <div className="sidePanel-header">
                    <h3>My assessments</h3>
                    <span>Lorem Obcaecati ea nisi dignissimos ipsa soluta?
                        Ipsa </span>
                    <div className='sidePanel-stats'>
                        <div>
                            <span>Tests Taken</span> <p id='num'>{assessment.Test_taken}</p>
                        </div>
                        <hr />
                        <div>
                            <span>Badges Earned</span> <p id='num'>{assessment.Badges}</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="skillTest-mainPanel">
                <div className="mainPanel-header">
                    <h3>Skill assessments</h3>
                    <span>Lorem Obcaecati ea nisi dignissimos ipsa soluta?
                        Ipsa adipisci maiores quae rerum deserunt</span>
                </div>
                <hr />
                <div className="mainPanel">
                    {Tests.map((item, index) =>
                        <div className="mainPanel-cards" key={index}>
                            <div className='mainPanel-image'>
                                <img src={Certificate} alt="badge" />
                            </div>
                            <div className='mainPanel-detail' onClick={() => handleSkillModel(index)}>
                                <h4>{item.SKT_Title}</h4>
                                <div><span id='topic'>No. of Questions</span><p>{item.No_of_Questions}</p></div>
                                <div><span id='topic'>Skills</span><p>{item.Skills}s</p></div>
                                <div><span id='topic'>Time</span><p>{item.Duration} mins.</p></div>

                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div id="myModal" className={showmodel ? 'model-open' : 'model'}>
                {Tests.map((item,index) => Index === index ? 
                <div className="modal-content" style={{ width: "55%" }} key={item.SKT_Title} >
                    <span className="close" onClick={CloseModel}>&times;</span>
                    <h2>{item.SKT_Title}</h2>
                    <div className='instructions'>
                        <h4>Instructions</h4>
                        <ul>
                            <li>Total <span> {item.No_of_Questions} Questions</span></li>
                            <li><span>{((item.Duration/item.No_of_Questions).toString()).slice(0,3)} mins</span> to answer each question </li>
                            <li>Must be finished in one sitting</li>
                            <li>Will not allow you to go back and change your answer</li>
                            <li>Attempting all questions is compulsary</li>
                        </ul>
                        <button className='buton view-btn' onClick={()=>navigate("/SKillQA",{state:{SKT_ID:item.SKT_ID,duration:item.Duration}})}> Start<BsArrowRightCircle/></button>
                    </div>
                </div> :null
                )}
             </div>  
           </div>   
                )
}

                export default SkillTest