import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MyContext } from "../../Mycontext";
import editbutton from "../../images/edit.png"
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import Selectant from "../Jobs/Select";
import { AiOutlineFile } from 'react-icons/ai'
import Userskills from "./Userskills";

function Userprofileupdate({ userDetail }) {
  const [showmodel, setshowModel] = useState(false);
  const { user, setuser, endPointUrl, Token } = useContext(MyContext);
  const [Languagemaster, setLanguagemaster] = useState([]);
  const [freelancerStatus, setFreelancerStatus] = useState(true);
  const [File_Type, setFile_Type] = useState(userDetail.Verification_doc_type)
  const [preform, setPreform] = useState([]);
  const [allskills, setallskills] = useState([]);
  const [skillmaster, setskillmaster] = useState([]);
  const [edit, setedit] = useState({
    Name: userDetail.Name,
    Last_Name: userDetail.Last_Name,
    Address: userDetail.Address,
    Country: userDetail.Country,
    City: userDetail.City,
    State: userDetail.State,
    Zip: userDetail.Zip,
    Language: userDetail.Language?.split(","),
    Skills: userDetail.Skills?.split(","),
    About: userDetail.About,
    Gender: userDetail.Gender,
    Mobile: userDetail.Mobile,
    rate_from: userDetail.rate_from,
    rate_to: userDetail.rate_to,
    rate_type: userDetail.rate_type,
  });
  const handleLanguageChange = (selectedLanguages) => {
    setLanguageSelected(selectedLanguages);
    setedit({ ...edit, Language: selectedLanguages });
  };
  const handleSkillChange = (selectedSkills) => {
    setSkillSelected(selectedSkills);
    setedit({ ...edit, Skills: selectedSkills });
  };


  const uploadfiles = async () => {
    const formdata = new FormData();
    let filePath = "";
    formdata.append('photo', preform)
    try {
      if (preform.length != 0) {
        const { data } = await axios.post(
          endPointUrl + "/user/imageUpload", formdata //Documents array upload
        );
        filePath = data.fileName
      }

      try {


        await axios.post(endPointUrl + "/addverification", {
          Token,
          id: user.id,
          Verification_Doc: filePath,
          Verification_doc_type: File_Type,
          Verified_Profile: "Pending"
        });
        message.success("Document Added")
        setuser({ ...user, Verification_Doc: data.fileName, Verification_doc_type: File_Type });
      } catch {
        console.log("upload url produce error");
      }
    } catch {
      console.log("error occur during upload docs");
    }
  }

  const handleExpModel = () => {
    setshowModel(true);

  };
  const CloseModel = () => {
    setshowModel(false);
  };
  const handleStatusToggle = async (newStatusValue) => {
    try {
      await axios.post(endPointUrl + "/updateuserProfile", {
        Token,
        id: user.id,
        Status_by_Admin: newStatusValue,
      });

      setFreelancerStatus(newStatusValue);

    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const handleLanguageMaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getalllanguage", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setLanguagemaster(a);
  };
  const handleskillsmaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setskillmaster(a);
  };
  
  const handleSubmitUseredit = async (data) => {
    if (isStringlessThan200Chars(edit.About)) {
      message.warning("About should have minmium 200 and maximum 500 characters");
      return;
    }

    if (userDetail.Verified_Profile != "Verified") {
      uploadfiles();
    }

    try {
      await axios.post(endPointUrl + "/updateUserProfile", {
        ...data,
        id: userDetail.id, Token
      });
      setshowModel(false);
      setuser({
        ...userDetail,
        Name: edit.Name,
        Last_Name: edit.Last_Name,
        Address: edit.Address,
        Country: edit.Country,
        City: edit.City,
        State: edit.State,
        Zip: edit.Zip,
        Language: edit.Language?.join(","),
        Skills: edit.Skills?.join(","),
        About: edit.About,
        Gender: edit.Gender,
        Mobile: edit.Mobile,
        rate_from: edit.rate_from,
        rate_to: edit.rate_to,
        rate_type: edit.rate_type,
      });
      message.success("updated SuccessFully");
    } catch (err) {
      message.error("profile is Not Updated", err);
    }
  };

  function isStringlessThan200Chars(inputString) {
    return (500 < inputString.length || inputString.length < 200 || inputString == null || inputString == "");
  }
  // const checkaboutlength = (content) => {
  //   const strippedContent = content.replace(/<[^>]+>/g, "");

  //   // Count the words in the content
  //   const wordCount = strippedContent.trim().split(/\s+/).length;

  //   // Check if the word count is less than 50
  //   if (wordCount < 100) {
  //     return true;
  //   }
  //   return false;
  // };
  const [selectedCountry, setSelectedCountry] = useState(edit.Country || null);
  const [selectedState, setSelectedState] = useState(edit.State || null);
  const [selectedCity, setSelectedCity] = useState(edit.City || null);
  const [LanguageSelected, setLanguageSelected] = useState(null);
  const [SkillSelected, setSkillSelected] = useState(null);
  useEffect(() => {
    if (edit.Language != "") {
      setLanguageSelected(edit.Language)
    }
    if (edit.Skills != "") {
      setSkillSelected(edit.Skills)
    }

    console.log(userDetail);
    handleLanguageMaster();
    handleskillsmaster();
  }, [])

  return (
    <>
      {" "}
      <img
        src={editbutton}
        style={{ paddingLeft: "15px", zIndex: "9", cursor: 'pointer', width: '40px', position: 'absolute', top: '5px', right: '7px' }}
        onClick={() => handleExpModel()}
      />
      <div id="myModal" className={showmodel ? "model-open" : "model"}>
        <div className="modal-content" style={{ padding: 0 }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <h2 style={{ color: "#46c294" }}>Edit Profile</h2>

              <div className="radio-inputs-user" style={{ padding: 0, width: "auto", borderRadius: "25px",background:"#fff" }}>
                <label className="radio" style={{ fontSize: "12px" }}>
                  <input
                    style={{ padding: "5px,15px" }}
                    type="radio"
                    name="radio"
                    checked={freelancerStatus === true} // Assuming "true" represents "Active"
                    onChange={() => handleStatusToggle(true)} // Set status to "Active"
                  />
                  <span className="status" style={{ borderRadius: "25px", padding: "5px,15px" }}>Active</span>
                </label>
                <label className="radio" style={{fontSize:"12px"}}>
                  <input type="radio" name="radio"
                    style={{padding:"5px,15px"}}
                    checked={freelancerStatus === false} // Assuming "false" represents "Inactive"
                    onChange={() => handleStatusToggle(false)} // Set status to "Inactive"
                  />
                  <span className="status" style={{ borderRadius: "25px", padding: "5px,15px" }}>InActive</span>
                </label>
              </div>
            </div>
            <span className="close" onClick={CloseModel}>
              &times;
            </span>
          </div>

          <div style={{ marginTop: "30px" }}>

            <form
              className="model-field userprofile exp-form"
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmitUseredit(edit);
              }}
            >
              <div>
                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="Name">First Name</label>
                    <input
                      type="text"
                      name="Name"
                      value={edit.Name}
                      placeholder="Enter username"
                      onChange={(e) => setedit({ ...edit, Name: e.target.value })}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="Last_Name">Last Name</label>
                    <input
                      type="text"
                      name="Last_Name"
                      placeholder="Enter Your Last_Name"
                      value={edit.Last_Name}
                      onChange={(e) => setedit({ ...edit, Last_Name: e.target.value })}
                    />
                  </div>
                </div>
                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="Mobile">Mobile:</label>
                    <input
                      type="tel"
                      name="Mobile"
                      value={edit.Mobile}
                      placeholder="Enter Your Mobile Number"
                      onChange={(e) => setedit({ ...edit, Mobile: e.target.value })}
                    />
                  </div>
                  <div>
                    <label htmlFor="Gender" style={{marginBottom:"10px"}}>Gender:</label>
                    <div className="radio">
                      <span className="radio_span">
                        <input type="radio" name="Gender" value="male" defaultChecked={edit.Gender === "male"}
                          onClick={(e) => setedit({ ...edit, Gender: e.target.value })} />
                        <label>Male </label>
                      </span>

                      <span className="radio_span">
                        <input type="radio" name="Gender" value="female" defaultChecked={edit.Gender === "female"}
                          onClick={(e) => setedit({ ...edit, Gender: e.target.value })} />
                        <label>Female </label>
                      </span>


                      <span className="radio_span">
                        <input type="radio" name="Gender" value="other" defaultChecked={edit.Gender === "other"}
                          onClick={(e) => setedit({ ...edit, Gender: e.target.value })} />
                        <label>Other </label>
                      </span>

                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div>
                  {user.Type === "Freelancer" ?
                    <div style={{marginBottom:"30px"}}>

                      <div className="userdeatail_container" style={{ gridTemplateColumns: "100%" }}>
                        <label style={{marginTop:"20px"}}>Rate :</label>
                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                          <div >
                            <input
                              type="text"
                              style={{ marginBottom: 0, width: "auto" }}
                              name="rate_from"
                              id="rate_from"
                              value={edit.rate_from}
                              placeholder="mn."
                              onChange={(e) => setedit({ ...edit, rate_from: e.target.value })}
                            />
                          </div>
                          <div>/</div>
                          <div>
                            {/* <label htmlFor="rate_type">rate_type:</label> */}
                            <select
                              style={{ padding: "10px",margin:"0" }}
                              id="rate_type"
                              name="rate_type"
                              value={edit.rate_type}
                              placeholder="Enter Your rate_type"
                              onChange={(e) => setedit({ ...edit, rate_type: e.target.value })}
                            >

                              <option value="">Select Rate Type</option>
                              <option value="hr.">hourly</option>
                              <option value="week">weekly</option>
                              <option value="month">monthly</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div> : null}
                  <div style={{ padding: "0 40px", marginBottom: "30px" }}>
                    <label htmlFor="About">About:</label>
                    <textarea
                      style={{ width: "98%", padding: "10px" }}
                      name="About"
                      id="About"
                      value={edit.About}
                      placeholder="Enter About Yousrself"
                      onChange={(e) => setedit({ ...edit, About: e.target.value })}
                    />
                  </div>
                  <div className="language" style={{ padding: "0 40px", marginBottom: "30px" }}>
                    <label htmlFor="Language">Language:</label>
                    <Selectant
                      label="Language"
                      options={Languagemaster}
                      selected={LanguageSelected}
                      setselected={handleLanguageChange}
                      onChange={(e) => setedit({ ...edit, Language: e.target.value })}
                    />
                  </div>
                 
                </div>
              </div>
              <hr />

              <div>
                <div className="userdeatail_container" style={{ gridTemplateColumns: "100%" }}>
                  <label style={{ marginTop: "20px" }} htmlFor="Address">Address:</label>
                  <input
                    id="Addess"
                    type="text"
                    name="Address"
                    value={edit.Address}
                    placeholder="Enter Your Address"
                    onChange={(e) => setedit({ ...edit, Address: e.target.value })}
                  />
                </div>
                <div className="userdeatail_container">
                  <div style={{ width: "96%" }}>
                    <label htmlFor="Country">Country:</label>
                    <Select

                      options={Country.getAllCountries()}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={
                        Country.getAllCountries().find((country) => country.name === edit.Country) || null
                      }
                      onChange={(item) => {
                        setSelectedCountry(item);
                        setedit({ ...edit, Country: item.name });
                      }}
                    />
                  </div>
                  <div style={{ width: "96%" }}>
                    <label htmlFor="State">State:</label>
                    <Select

                      options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}

                      value={
                        State.getAllStates().find(state => state.name === edit.State) || null
                      }
                      onChange={(item) => {
                        setSelectedState(item);
                        setedit({ ...edit, State: item.name })
                      }}
                    />
                  </div>
                </div>
                <div className="userdeatail_container">
                  <div style={{ width: "96%" }}>
                    <label htmlFor="City">City:</label>
                    <Select
                      options={City.getCitiesOfState(
                        selectedState?.countryCode,
                        selectedState?.isoCode
                      )}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      // value={edit.City}
                      value={
                        City.getAllCities().find(city => city.name === edit.City) || null
                      }
                      onChange={(item) => {
                        setSelectedCity(item);
                        setedit({ ...edit, City: item.name })
                      }}
                    />
                  </div>

                  <div >
                    <label htmlFor="Zip">Zip:</label>
                    <input
                      type="text"
                      style={{ padding: "7px 10px", width: "91%" }}
                      name="Zip"
                      value={edit.Zip}
                      placeholder="Enter Your Zip code"
                      onChange={(e) => setedit({ ...edit, Zip: e.target.value })}
                    />
                  </div>
                </div>

                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="file">Document Type:</label>
                    <select
                      style={{ width: "96%", padding: "10px" }}
                      id="Verif_doc_type"
                      name="Verif_doc_type"
                      value={File_Type}
                      placeholder="Enter Your document type"
                      onChange={(e) => setFile_Type(e.target.value)}
                    >
                      <option value="">Options</option>
                      <option value="Aadhar Card">Aadhar Card</option>
                      <option value="PAN Card">PAN Card</option>
                      <option value="VoterID Card">VoterID Card</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="file">Verification Document:</label>
                    <input

                      type="file"
                      name="photo"
                      onChange={(e) => { setPreform(e.target.files[0]) }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="skillsset-card" style={{boxShadow:"none",border:"none",marginTop:"10px",paddingInline:"40px"}}>
                <h5 style={{fontSize:"16px"}}>Skills</h5>
                {/* <Userskills allskills={allskills} user={user} /> */}
                <div className="language" style={{ padding: "0 40px", marginBottom: "30px" }}>
                  {/* <label htmlFor="Skill">Skills:</label> */}
                  <Selectant
                    label="Skill"
                    options={skillmaster}
                    selected={SkillSelected}
                    setselected={handleSkillChange}
                    onChange={(e) => setedit({ ...edit, Skills: e.target.value })}
                  />
                </div>
               
                
              </div>
              <hr />
              <div style={{ display:"flex", alignItems:"center", justifyContent:"center" }}>
                <input
                  type="submit"
                  className="buton"
                  style={{ marginBlockStart: "20px", color: "white" }}
                  value={"Submit"} />
              </div>
            </form>
          </div>


        </div>
      </div >
    </>
  );
}

export default Userprofileupdate;
