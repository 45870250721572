import { useNavigate } from "react-router";
import JobPostFilter from "../components/JobPostFilter";
import BlogWrapper from "../components/BlogWrapper";
import CategoryFooter from "../components/CategoryFooter";
import ClientReviewContainer from "../components/ClientReviewContainer";
import JobPostingContainer from "../components/JobPostingContainer";
import SectionDetails from "../components/SectionDetails";
import ShowNumbers from "../components/ShowNumbers";
import HireExpertConatiner2 from "../components/HireExpertConatiner2";
import FeaturedFreelancers from "../components/FeaturedFreelancers";
import { BsArrowRightCircle } from 'react-icons/bs'
import job from '../images/Icons/postjob.png'
import freelancer from '../images/Icons/hire-freelancer.png'
import work from '../images/Icons/work-done.png'
import payment from '../images/Icons/secure-payment.png'
const IndexV1 = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    document.documentElement.scrollTop = 0;
    navigate('/How-It-Works')
  }
  return (
    <div className="index-v1">
      <HireExpertConatiner2 />
      <ShowNumbers />
      {/* <JobPostFilter /> */}
      <div className="its-easy-to">
        <h2>It's Easy to Get Work Done on DigiLanxer</h2>
        <p>DigiLanxer is the ideal choice. Start your freelance career here today and take advantage of the countless opportunities it offers</p>
      </div>
      <div className="jobs-card">
        <JobPostingContainer
          image={job}
          jobPostingText="Post a Job"
          jobPostingDescription="Digilanxer is a platform where businesses can post job listings specify budget for the job and hire freelancers.."
        />
        <JobPostingContainer
          image={freelancer}
          jobPostingText="Hire Freelancers"
          jobPostingDescription="Hiring freelancers provides flexibility for businesses as they can choose the specific skills and expertise required for a project.."
        />
        <JobPostingContainer
          image={work}
          jobPostingText="Get Work Done"
          jobPostingDescription="They offer a reliable and dedicated work ethic, ensuring projects are completed on time and to the client's satisfaction."
        />
        <JobPostingContainer
          image={payment}
          jobPostingText="Make Secure Payments"
          jobPostingDescription="They can choose from various payment platforms and methods available.Digilanxers can also keep records for accounting purposes."
        />
      </div>
      <div className="jobs-card-open">
        <button className="btn-outline" onClick={handleClick} >See how it works <BsArrowRightCircle /></button>
      </div>
      <FeaturedFreelancers />
      <SectionDetails />
      <ClientReviewContainer />
      <BlogWrapper />
    </div>
  );
};

export default IndexV1;
