import Profile from "../images/freelancer.png";
import dolar from "../images/dolar.png";
import editbutton from "../images/edit.png";
import deletebutton from "../images/delet2.png";
import Frame from "../images/Frame.svg"
import { Link, Navigate, useNavigate } from "react-router-dom";
import { MyContext } from "../Mycontext";
import { useContext, useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import WorkExp from "./user-profile/WorkExp";
import Userprofileupdate from "./user-profile/userprofileupdate";
import Uploadimg from "./user-profile/Uploadimg";
import Userskills from "./user-profile/Userskills";
import add from '../images/add.png'
import Technicalskills from "./user-profile/Technicalskills";
import AddPortfolio from "./user-profile/Portfolio/AddPortfolio";
import ProgressBar from "./ProgressBar";
import Certificate from "./user-profile/Certificate/AddCertificate";
import { MdVerified } from 'react-icons/md';
import { Popup } from "semantic-ui-react";
import { AiFillStar } from 'react-icons/ai';
import img from '../images/veryfied.png';
// import 'semantic-ui-css/semantic.min.css';
function Logeduser() {
  const { user, endPointUrl, Token } = useContext(MyContext);

  const [counter, setCounter] = useState(0);
  const [bgColor, setbgColor] = useState('');
  const [education, setEducation] = useState([]);
  const [Editeducation, setEditeducation] = useState([]);
  const [exp, setExp] = useState([]);
  const [newEducation, setnewEducation] = useState({ DegreeName: null, University: null, Year: null });
  const [errorForm, setErrorForm] = useState({ DegreeName: "", University: "", Year: "" });
  const [showmodel, setshowModel] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const [uploadImg, setUploadImg] = useState(false);
  const [allskills, setallskills] = useState([]);
  let skills = user?.Skills ? user.Skills.split(",") : [];
  const Navigate = useNavigate();
  const handleuploadImg = () => {
    setUploadImg(!uploadImg);
  }

  const getEducation = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getuserEducation", { UserID: user.id, Token })
    setEducation(data.data);

  };

  const getExp = async () => {

    const { data } = await axios.post(endPointUrl + "/user/getuserExp", {
      UserID: user.id,
      Token,
    });
    setExp(data.data);
  };
  useEffect(() => {
    getExp();
    getEducation();
  }, []);
  const addEducation = async (education) => {
    const { data } = await axios.post(endPointUrl +
      "/user/userEducation",
      { ...education, UserID: user.id, Token }
    );
    getEducation();
  };
  const handleEducationModel = (e) => {
    setErrorForm(
      { DegreeName: "", University: "", Year: "" }
    );
    setshowModel(true);
  }
  const handleEditEducationModel = (Item) => {
    let tem = education.filter((item) => {
      return item.U_E_ID === Item.U_E_ID
    });
    setEditeducation(tem);
    setshowEdit(true);
  };
  const CloseModel = () => {
    setshowModel(false);
    setshowEdit(false);
  };
  const handlesubmitEducationModel = (e) => {
    e.preventDefault();
    const newFormErrors = {
      DegreeName: "",
      University: "",
      Year: "",
    };
    let showError = false;
    if (newEducation.DegreeName === null || newEducation.DegreeName === "") {
      showError = true;
      newFormErrors.DegreeName = 'Please fill out this field!';
    } else {
      newFormErrors.DegreeName = '';
    }
    if (newEducation.University === null || newEducation.University === "") {
      showError = true;
      newFormErrors.University = 'Please fill out this field!';
    }
    else {
      newFormErrors.University = "";
    }
    if (newEducation.Year === null || newEducation.Year === "") {
      showError = true;
      newFormErrors.Year = 'Please fill out this field!';
    } else {
      newFormErrors.Year = '';
    }

    setErrorForm(newFormErrors);
    if (!showError) {
      addEducation(newEducation);
      setshowModel(false);
    }

  };
  const handleEditsubEducationModel = (e) => {
    e.preventDefault();
    updateEducation();
    setshowEdit(false);
  };
  const deleteEducation = async (U_E_ID) => {
    try {
      await axios.post(endPointUrl + "/user/deleteuserEducation", { U_E_ID, Token });
      message.success('Delete Succesfull');
      getEducation();
    } catch {
      message.error('something Went wrong');
    }
  };
  const updateEducation = async () => {
    try {
      await axios.post(endPointUrl + "/user/updateUserEducation ", {
        ...newEducation,
        U_E_ID: Editeducation[0].U_E_ID,
        Token,
      });
      getEducation();
      message.success("Update Succesfull");
    } catch {
      message.error("something went Wrong ");
    }
  };
  const labelStyles = {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    color: '#5E5E5E',
    fontSize: '12px'
  }

  const progress = () => {
    (setCounter((counter) => counter = 0));
    (user?.Photo) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.About) ? (setCounter((counter) => counter + 10)) : (null),
      // (user?.Last_Name) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Verified_Profile == "Verified") ? (setCounter((counter) => counter + 20)) : (null),

      (user?.rate_from) ? (setCounter((counter) => counter + 4)) : (null),
      (user?.rate_to) ? (setCounter((counter) => counter + 4)) : (null),
      (user?.rate_type) ? (setCounter((counter) => counter + 4)) : (null),

      (exp?.length) ? (setCounter((counter) => counter + 5)) : (null),
      (education?.length) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Skills) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Technology) ? (setCounter((counter) => counter + 5)) : (null),

      (user?.Name) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Address) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Gender) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Mobile) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Language) ? (setCounter((counter) => counter + 5)) : (null),

      (user?.Email) ? (setCounter((counter) => counter + 2)) : (null),
      (user?.City) ? (setCounter((counter) => counter + 2)) : (null),
      (user?.State) ? (setCounter((counter) => counter + 2)) : (null),
      (user?.Country) ? (setCounter((counter) => counter + 2)) : (null)
  }

  const gradient = () => {
    (counter < 25) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg,rgba(7,178,116,1), #00ffff)')) : (
      (counter < 70) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1), #00ffff, #c4fdff)'))
        : (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1) 25%, #00ffff 63%, rgba(51,154,250,1) 90%)'))
    )
  }

  useEffect(() => {

    gradient();
  }, [counter])

  useEffect(() => {

    progress();

  }, [user, exp, education])

  useEffect(() => {
    (setCounter((counter) => counter = 0));

  }, []);


  return (
    <div className="HowItWorks" >

      <div className="home-howitwork">
        <Link to={`/${user?.Type == 'Employer' ? 'EmployerJobProfile' : 'FreelancerJobProfile'}`}>DashBoard</Link> {" "}/{" "}
        <Link to="/loginUserProfile">{user?.Name}</Link>
      </div>

      <div className="profile-Apart">
        {!uploadImg ? <img src={user?.Photo === "" || user?.Photo == null ? Profile : `${endPointUrl}/images/${user?.Photo}`} alt="profile" onClick={() => handleuploadImg()} /> : <Uploadimg handleuploadImg={handleuploadImg} userid={user.id} />}

        <div className="user-name">
          <img src={img} alt="" style={{ filter: user.Verified_Profile == "Verified" ? "none" : "grayscale(1)", width: "23px", height: "auto", marginLeft: "auto", position: "absolute", top: "15px", right: "0px" }} />
          {/* <Popup
            trigger={user.Verified_Profile == "Verified" ? <MdVerified style={{ color: "rgb(7 178 116)", marginLeft: "auto", height: "auto", fontSize: "20px", position: "absolute", right: 0 }} /> :
              <MdVerified style={{ color: "rgb(133 133 133)", marginLeft: "auto", fontSize: "20px", height: "auto", position: "absolute", right: 0 }} />}
            content={user.Verified_Profile == "Verified" ? "Profile Verified" :
              user.Verified_Profile == "Pending" ? "Profile verification Pending" :
                "Profile Unverified"}
          /> */}
          <h2>{user?.Name}</h2>
          {/* <span>{user?.Title}</span> */}
        </div>
        <div className="user-reviews-address">
          <span className="rating" style={{ paddingLeft: "8px", fontSize: "16px" }}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 16 15" fill="#FF8A00" className="rating-star">
            <path d="M8.00027 12.1734L3.29827 14.8054L4.34827 9.52004L0.391602 5.86137L5.74293 5.22671L8.00027 0.333374L10.2576 5.22671L15.6089 5.86137L11.6523 9.52004L12.7023 14.8054L8.00027 12.1734Z" fill="#FF8A00" />
          </svg> */}
            <AiFillStar className="featuredfreelancer-rating-star" />
            {user.avg_Rating ? user.avg_Rating : 0} <p>({user.no_of_reviews ? user.no_of_reviews : 0} Reviews)</p></span>
          <span>|  <img src={Frame} alt="location" style={{ paddingRight: "3px", paddingLeft: "18px", width: "18px" }} /> {user?.City} {user?.City ? "," : null} {user?.Country}</span>
        </div>
      </div>

      <section className="logedUser-section">
        <div style={{ width: "69%" }}>
          <div className="about-rate">
            <div className="about">
              <b>About</b>
              <p>{user?.About}</p>

            </div>

          </div>

          <div className="education">
            <strong style={{ display: 'flex', alignItems: 'center' }}>Education <img src={add} alt="" onClick={handleEducationModel} /></strong>
            <div className="all-education">
              <div className="education-line">
              </div>
              <div className="education-conainer" >
                {education.map((item) => (
                  <div className="eduction-time" >
                    <div>
                      <div className="year-range">{item.Year}</div>
                      <div className="collage">{item.University}</div>
                      <p>
                        {item.DegreeName}
                      </p>
                      <div className="edit-icons">
                        <img id="edit-btn" src={editbutton} alt="" onClick={() => handleEditEducationModel(item)} />
                        <img id="del-btn" src={deletebutton} alt="" onClick={() => deleteEducation(item.U_E_ID)} />
                      </div>
                      <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* this model is used for add education*/}

            <div id="myModal" className={showmodel ? 'model-open' : 'model'}>
              <div className="modal-content" style={{ width: "60%", padding: 0 }} >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                  <span style={{ color: "#46c294", fontWeight: "600" }}>Add Education</span>
                  <span className="close" onClick={CloseModel}>&times;</span>

                </div>
                <form style={{ padding: "0 40px", marginTop: "30px" }} className="model-field" onSubmit={handlesubmitEducationModel}>
                  <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                    <div>
                      <label htmlFor="DegreeName">Degree</label>
                      <small style={{ color: "#ec1c24" }}>{errorForm.DegreeName}</small>
                      <input className={errorForm.DegreeName != "" ? "errorborder" : ""} type="text" name="DegreeName"
                        placeholder="Enter your Degree Name" onChange={(e) => setnewEducation({ ...newEducation, DegreeName: e.target.value })} />
                    </div>

                    <div>
                      <label htmlFor="University">University</label>
                      <small style={{ color: "#ec1c24" }}>{errorForm.University}</small>
                      <input className={errorForm.University != "" ? "errorborder" : ""} type="text" name="University" style={{ marginInlineEnd: "12%" }}
                        placeholder="Enter UniverSity/collage Name" onChange={(e) => setnewEducation({ ...newEducation, University: e.target.value })} />
                    </div>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="Year">Completion Year</label>
                    <small style={{ color: "#ec1c24" }}>{errorForm.Year}</small>
                    <input className={errorForm.Year != "" ? "errorborder" : ""} type="number" name="Year" style={{ width: "43%", marginInlineEnd: "35%" }}
                      placeholder="Enter Education Completion Year" onChange={(e) => setnewEducation({ ...newEducation, Year: e.target.value })} />

                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    <input type="submit" className="btn" value={'Add Education'} style={{ padding: "10px 5px", backgroundColor: "#e3e3e3", color: "#000", width: "20%" }} />
                    <input type="submit" className="btn" value={'Submit'} style={{ width: "20%" }} />
                  </div>


                </form>
              </div>
            </div>

            {/* this model is for update education */}

            <div id="myModal" className={showEdit ? 'model-open' : 'model'}>
              <div className="modal-content" style={{ width: "60%", padding: 0 }} >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                  <span style={{ color: "#46c294", fontWeight: "600" }}>Update Education</span>
                  <span className="close" onClick={CloseModel}>&times;</span>
                </div>
                {Editeducation?.map((item) =>
                  <form style={{ padding: "0 40px", marginTop: "30px" }} className="model-field" onSubmit={handleEditsubEducationModel}>
                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                      <div>
                        <label htmlFor="DegreeName">Degree</label>
                        <input type="text" name="DegreeName"
                          placeholder={item.DegreeName} onChange={(e) => setnewEducation({ ...newEducation, DegreeName: e.target.value })} />
                      </div>
                      <div>
                        <label htmlFor="University">University</label>
                        <input type="text" name="University" style={{ marginInlineEnd: "12%" }}
                          placeholder={item.University} onChange={(e) => setnewEducation({ ...newEducation, University: e.target.value })} />
                      </div>

                    </div>
                    <div>
                      <label htmlFor="Year">Completion Year</label>
                      <input type="number" name="Year" style={{ width: "43%", marginInlineEnd: "35%" }}
                        placeholder={item.Year} onChange={(e) => setnewEducation({ ...newEducation, Year: e.target.value })} />
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                      <input type="submit" className="btn" value={'Add Education'} style={{ padding: "10px 5px", backgroundColor: "#e3e3e3", color: "#000", width: "20%" }} />
                      <input type="submit" className="btn" value={'Update'} style={{ width: "20%" }} />
                    </div>


                  </form>)}
              </div>
            </div>

          </div>
          <WorkExp UserID={user.id} />
          <Certificate />
          <AddPortfolio />
        </div>


        <div className="skills-tech-container">
          <div className="rate-card">
            <Userprofileupdate userDetail={user} />
            <div className="profile-complete" style={{ borderRadius: "10px", marginBottom: "20px", background: "#0cfca415" }}>
              <span style={labelStyles}><span style={{ fontWeight: "600", color: "black", marginRight: "3px" }}>{counter}% </span> Profile complete</span>
              <div className="progress">
                <ProgressBar bgColor={bgColor} completed={counter} />
              </div>

            </div>
            <div className="price-range">
              <img src={dolar} alt="" />
              <div className="hour-price">
                <span className="rate">Rate:</span>
                <span>${user?.rate_from} / {user?.rate_type}</span>
              </div>

            </div>
            <div className="user-details">
              <p>Location</p>
              <span>{user?.City} {user?.City ? "," : null} {user?.Country}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Verification Status</p>
              <span>{(user?.Verified_Profile == "Verified") ? "Verified" : "Unverified"}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Language</p>
              {/* <span>
            {user?.Language?.split(",").map((item,index)=>
              index < user?.Language?.split(",").length-1 ?
               <span>{item},</span> 
                : <span>{item}</span> 
             )}
            </span> */}
              <span>{user?.Language?.split(', ').join(', ')}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Gender</p>
              <span>{user?.Gender}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Email</p>
              <span>{user?.Email}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Phone Number</p>
              <span>{user?.Mobile}</span>
            </div>
            <hr style={{ borderTop: '1px solid #f1eded' }}></hr>

            <div className="skills-card" style={{ margin: "10px 0px" }}>
              <h5>Skills</h5>
              <div className="skills-employee " >
                {skills.map((val) => (
                  <strong style={{ color: "#000000", borderRadius: "15px", border: "1px solid #c6bbbb87", padding: "5px 10px" }} key={val}>{val}</strong>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="skillsset-card">
            <h5>Skills</h5>
            <Userskills allskills={allskills} user={user} />
            <hr />
           <div className="takeSkillTest-container">
              <span>Take Skill Test to Earn Badges</span>
              <button className="btn-outline" onClick={() => Navigate("/SkillTest")}>Take Skill Test</button>
              <p>*Badges help boost your profile</p>
            </div> 
          </div> */}

        </div>
      </section>
    </div>
  );
}

export default Logeduser;
