import { CKEditor } from "ckeditor4-react";
import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../Mycontext";
import { message } from "antd";
import { useNavigate } from "react-router";
import axios from "axios";
import Selectant, { required } from "./Select";
import PublishedJob from "./PublishedJob";
import Keywords from "./Keywords";
function Jobform() {
  const { endPointUrl, Token, user } = useContext(MyContext);
  const [showpublished, setshowPublished] = useState(false);
  const [skillmaster, setskillmaster] = useState([]);
  const [Locationmaster, setLocationmaster] = useState([]);
  const [technologyMaster, settechnologyMaster] = useState([]);
  const [categoryMaster, setCategorymaster] = useState([]);
  const [Languagemaster, setLanguagemaster] = useState([]);
  const [skillSelected, setskillSelected] = useState([]);
  const [LocationSelected, setLocationSelected] = useState([]);
  const [technologySelected, settechnologySelected] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [LanguageSelected, setLanguageSelected] = useState([]);
  const [tags, setTags] = useState([]);


  const [form, setForm] = useState({
    jobTitle: "",
    // ShortDesc: "",
    Budget_Type: "",
    Budget_From: "",
    ProjectType: "",
    Budget_To: "",
    Priority: "",
    Type: "",
    skillSelected: "",
    categorySelected: "",
    technologySelected: "",
    LocationSelected: "",
    LanguageSelected: "",
    tags: "",
    Description: ""

  })

  const [Description, setDescription] = useState(
    "<p>Give Description About Your Project....</p>"
  );
  const handleskillsmaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setskillmaster(a);
  };
  const handleLocationsmaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getalllocation", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setLocationmaster(a);
  };
  const handletechnologyMaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getAlltechnology", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    settechnologyMaster(a);
  };
  const handlecategoryMaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getallcategory", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setCategorymaster(a);
  };
  const handleLanguageMaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getalllanguage", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setLanguagemaster(a);
  };

  const [job, setjob] = useState({
    Title: null,
    Budget_From: null,
    ProjectType: null,
    Budget_To: null,
    Budget_Type: null,
    Priority: null,
    // ShortDesc: null,
    Type: null,
    Completion_Date: null,
  });
  const [error, seterror] = useState(false);

  const onEditorChange = (evt, editor) => {
    const data = evt.editor && evt.editor.getData();
    setDescription(data);
  };
  useEffect(() => {
    handleLocationsmaster();
    handleskillsmaster();
    handlecategoryMaster();
    handletechnologyMaster();
    handleLanguageMaster();
  }, []);

  const handlejobSubmit = (e) => {
    e.preventDefault();
    console.log("Enter")
    // Initialize the error object with no errors
    const newFormErrors = {
      jobTitle: "",
      // ShortDesc: "",
      ProjectType: "",
      Budget_Type: "",
      Budget_From: "",
      Budget_To: "",
      Priority: "",
      Type: "",
      skillSelected: "",
      categorySelected: "",
      technologySelected: "",
      LocationSelected: "",
      LanguageSelected: "",
      tags: "",
      Description: ""
    };

    let showError = false;
    if (job.Title === null || job.Title === "") {
      showError = true;
      newFormErrors.jobTitle = 'Please fill out this field!';
    } else {
      newFormErrors.jobTitle = '';
    }
    // if (job.ShortDesc == null || job.ShortDesc === "") {
    //   showError = true;
    //   newFormErrors.ShortDesc = 'Please fill out this field!';
    // }
    // else {
    //   setForm({ ...form, ShortDesc: "" })
    // }
    if (job.ProjectType === null || job.ProjectType === "") {
      showError = true;
      newFormErrors.ProjectType = 'Please fill out this field!';
    } else {
      newFormErrors.ProjectType = '';
    }
    if (job.Budget_Type == null || job.Budget_Type === "") {
      showError = true;
      setForm({ ...form, Budget_Type: 'Please fill out this field!' });
      newFormErrors.Budget_Type = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, Budget_Type: "" })
    }
    if (job.Budget_From == null || job.Budget_From === "") {
      showError = true;
      setForm({ ...form, Budget_From: 'Please fill out this field!' });
      newFormErrors.Budget_From = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, Budget_From: "" })
    }
    if (job.Budget_To == null || job.Budget_To === "") {
      showError = true;
      setForm({ ...form, Budget_To: 'Please fill out this field!' });
      newFormErrors.Budget_To = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, Budget_To: "" })
    }
    if (job.Type == null || job.Type === "") {
      showError = true;
      setForm({ ...form, Type: 'Please fill out this field!' });
      newFormErrors.Type = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, Type: "" })
    }
    if (skillSelected.length == 0) {
      showError = true;
      setForm({ ...form, skillSelected: 'Please fill out this field!' });
      newFormErrors.skillSelected = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, skillSelected: "" })
    }
    if (categorySelected.length == 0) {
      showError = true;
      setForm({ ...form, categorySelected: 'Please fill out this field!' });
      newFormErrors.categorySelected = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, categorySelected: "" })
    }
    if (technologySelected.length == 0) {
      showError = true;
      setForm({ ...form, technologySelected: 'Please fill out this field!' });
      newFormErrors.technologySelected = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, technologySelected: "" })
    }
    if (LocationSelected.length == 0) {
      showError = true;
      setForm({ ...form, LocationSelected: 'Please fill out this field!' });
      newFormErrors.LocationSelected = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, LocationSelected: "" })
    }
    if (LanguageSelected.length == 0) {
      showError = true;
      setForm({ ...form, LanguageSelected: 'Please fill out this field!' });
      newFormErrors.LanguageSelected = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, LanguageSelected: "" })
    }
    // if (tags.length == 0) {
    //   showError = true;
    //   setForm({ ...form, tags: 'Please fill out this field!' });
    //   newFormErrors.tags = 'Please fill out this field!';
    // }
    // else {
    //   setForm({ ...form, tags: "" })
    // }
    if (Description === "") {
      showError = true;
      setForm({ ...form, Description: 'Please fill out this field!' });
      newFormErrors.Description = 'Please fill out this field!';
    }
    else {
      setForm({ ...form, Description: "" })
    }
    setForm(newFormErrors);
    console.log("showError", showError, newFormErrors)
    if (showError) {
      setshowPublished(false);
      return;
    }
    setshowPublished(!showpublished);

  }
  return (
    <>
      {showpublished ? (
        <PublishedJob
          job={job}
          LocationSelected={LocationSelected}
          skillSelected={skillSelected}
          languageSelected={LanguageSelected}
          technologySelected={technologySelected}
          categorySelected={categorySelected}
          showpublished={showpublished}
          setshowPublished={setshowPublished}
          Description={Description}
          // tags={tags}
        />
      ) : (
        <div className="JobPost">
          <span className="jobpostheading">
            <div>
              Post Your Job Here
              <p>Lorem ipsum dolor sit amet consectetur adipisicing. Praesentium iusto, Vel vero delectus aliquam earum.</p>
            </div>

            <img src={require("../../images/img.png")} alt="" />
          </span>
          <form
            className="JobPostForm"
            onSubmit={(e) => {
              handlejobSubmit(e)
            }}
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   setshowPublished(!showpublished);
          // }}
          >
            <div>
              <div>
                {" "}
                <label htmlFor="Title">Job Title <span style={{ color: "gray" }}>*</span></label>
                <input
                  value={job.Title}
                  type="text"
                  name="Title"
                  placeholder="Enter Your title"
                  className={form.jobTitle != "" ? "errorborder" : ""}
                  onChange={(e) => setjob({ ...job, Title: e.target.value })}
                />
                <small style={{ color: "#ec1c24" }}>{form.jobTitle}</small>


                <label>Budget<span style={{ color: "gray" }}>*</span></label>

                <div className="bid_amount">
                  <div className="bid_range" style={{ paddingRight: "2%", marginRight: "10%" }}>
                    <label htmlFor="Budget_From" style={{ color: "GrayText" }}>From </label>
                    <input
                      value={job.Budget_From}
                      type="number"
                      name="Budget_From"
                      step={"0.01"}
                      placeholder="Min"
                      className={form.Budget_From != "" ? "errorborder" : ""}

                      onChange={(e) =>
                        setjob({ ...job, Budget_From: parseFloat(e.target.value) })
                      }
                    />

                  </div>

                  <div className="bid_range" style={{ paddingRight: "2%" }}>
                    <label htmlFor="Budget_To" style={{ color: "GrayText" }}>To </label>
                    <input
                      value={job.Budget_To}
                      type="number"
                      name="Budget_To"
                      step={"0.01"}
                      placeholder="Max"
                      className={form.Budget_To != "" ? "errorborder" : ""}

                      onChange={(e) =>
                        setjob({ ...job, Budget_To: parseFloat(e.target.value) })
                      }
                    />

                  </div>

                </div>

                <label htmlFor="Budget_type">Payment Type<span style={{ color: "gray" }}>*</span></label>
                <select
                  value={job.Budget_Type}
                  type="text"
                  name="Budget_type"
                  placeholder="Enter Your Address"
                  className={form.Budget_Type != "" ? "errorborder" : ""}

                  onChange={(e) =>
                    setjob({ ...job, Budget_Type: e.target.value })
                  }
                  style={{
                    width: "94%",
                    padding: "10px 3px",
                    fontSize: "14px",
                  }}
                >
                  <option value="">Options</option>
                  <option value="hour">Hourly</option>
                  <option value="week">Weekly</option>
                  <option value="month">Monthly</option>
                </select>
                <small style={{ color: "#ec1c24" }}>{form.Budget_Type}</small>

                <label htmlFor="Enter Language">Language Preference<span style={{ color: "gray" }}>*</span> </label>
                <Selectant
                  label="Language"
                  options={Languagemaster}
                  setselected={setLanguageSelected}
                  selected={LanguageSelected}
                  className={form.LanguageSelected != "" ? "errorborder" : ""}
                />
                <small style={{ color: "#ec1c24" }}>{form.LanguageSelected}</small>

                <label htmlFor="Enter Technology">Technology<span style={{ color: "gray" }}>*</span></label>
                <Selectant
                  label="Technology"
                  options={technologyMaster}
                  setselected={settechnologySelected}
                  selected={technologySelected}
                  className={form.technologySelected != "" ? "errorborder" : ""}
                />
                <small style={{ color: "#ec1c24" }}>{form.technologySelected}</small>

                {/* <label htmlFor="ShortDesc">Short Desc<span style={{ color: "gray" }}>*</span></label>
                <textarea style={{ marginInlineEnd: "30px", padding: "10px" }}
                  value={job.ShortDesc}
                  rows="4"
                  cols="50"
                  name="ShortDesc"
                  placeholder="Enter the Short Description About your Projects"
                  className={form.ShortDesc != "" ? "errorborder" : ""}
                  onChange={(e) =>
                    setjob({ ...job, ShortDesc: e.target.value })
                  }
                ></textarea>
                <small style={{ color: "#ec1c24" }}>{form.ShortDesc}</small> */}


              </div>
              <div>


                <label htmlFor="SkillsRequired">Skills<span style={{ color: "gray" }}>*</span></label>
                <Selectant
                  label={"Enter Skills"}
                  options={skillmaster}
                  setselected={setskillSelected}
                  selected={skillSelected}
                  className={form.skillSelected != "" ? "errorborder" : ""}

                />
                <small style={{ color: "#ec1c24" }}>{form.skillSelected}</small>

                <div className="bid_range" style={{ marginTop: "40px", display: "block" }}>
                  <label htmlFor="Priority" style={{ margin: 0 }}>Job Type<span style={{ color: "gray" }}>*</span></label>
                  <div className="type_job" style={{ marginTop: "20px" }}>
                    <div>
                      <input style={{ width: "auto" }}
                        type="radio" value="Full-time" name="Type"
                        onChange={(e) => setjob({ ...job, Type: e.target.value })} /> Full time</div>
                    <div>
                      <input style={{ width: "auto" }}
                        type="radio" value="Part-time" name="Type"
                        onChange={(e) => setjob({ ...job, Type: e.target.value })} /> Part time</div>
                    <div>
                      <input style={{ width: "auto" }}
                        type="radio" value="Fixede" name="Type"
                        onChange={(e) => setjob({ ...job, Type: e.target.value })} /> Fixed</div>
                  </div>
                  <small style={{ color: "#ec1c24" }}>{form.Type}</small>
                </div>

                <label htmlFor="Budget_type">Poject Type<span style={{ color: "gray" }}>*</span></label>
                <select
                  // value={job.Budget_Type}
                  type="text"
                  name="Project_type"
                  className={form.ProjectType != "" ? "errorborder" : ""}
                  onChange={(e) =>
                    setjob({ ...job, ProjectType: e.target.value })
                  }
                  style={{
                    width: "94%",
                    padding: "10px 3px",
                    fontSize: "14px",
                  }}
                >
                  <option value="">options</option>
                  <option value="Fixed">Fixed</option>
                  <option value="Hourly">Hourly</option>
                </select>
                <small style={{ color: "#ec1c24" }}>{form.Budget_Type}</small>

                <label htmlFor="SkillsRequired">Location Preference<span style={{ color: "gray" }}>*</span></label>
                <Selectant
                  label={"Enter Location"}
                  options={Locationmaster}
                  setselected={setLocationSelected}
                  selected={LocationSelected}
                  isRequired={true}
                  className={form.LocationSelected != "" ? "errorborder" : ""}
                />
                <small style={{ color: "#ec1c24" }}>{form.LocationSelected}</small>

                <label htmlFor="Enter Category">Category<span style={{ color: "gray" }}>*</span></label>
                <Selectant
                  label="Category"
                  options={categoryMaster}
                  setselected={setCategorySelected}
                  selected={categorySelected}
                  className={form.categorySelected != "" ? "errorborder" : ""}
                />
                <small style={{ color: "#ec1c24" }}>{form.categorySelected}</small>





                {/* <label htmlFor="Keywords">
                  KeyWords (<small>press enter to add keywords</small>)
                </label>
                <Keywords tags={tags} setTags={setTags} className={form.tags != "" ? "errorborder" : ""} />


                <small style={{ color: "#ec1c24" }}>{form.tags}</small> */}

              </div>
            </div>
            <label htmlFor="Description">Description<span style={{ color: "gray" }}>*</span></label>
            <article>
              <CKEditor
                initData={Description}
                className={form.Description != "" ? "errorborder" : ""}
                config={{
                  toolbar: [
                    { name: "document", items: ["Undo", "Redo"] },
                    { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                    { name: "styles", items: ["Format"] },
                    {
                      name: "basicstyles",
                      items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                    },
                  ],
                }}
                onChange={onEditorChange}
              />
            </article>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input
                type="submit"
                value="Post a Job"
                className="buton"
                style={{ marginBlockStart: "20px" }}
              />
            </div>
          </form >
        </div >
      )
      }
    </>
  );
}

export default Jobform;
