import React from "react";
import { Select } from "antd";

function Selectant({ options, selected, setselected, label }) {
  const filteredOptions = options?.filter((o) => !selected?.includes(o));
  // Add a check for selected to prevent the error
  const selectedValues = selected || [];

  return (
    <div style={{ width: "95%" }}>
      <Select
        mode="multiple"
        placeholder={` ${label} `}
        value={selectedValues}
        size={"medium"}
        onChange={setselected}
        style={{ width: "100%", color: "grey" }}
        options={filteredOptions.map((item) => ({
          value: item,
          label: item,
        }))}
      />
    </div>
  );
}

export default Selectant;
