import React, { useContext, useEffect, useState } from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";

function Master({milestone}) {

//   const handleDelete = async (Id) => {
//     try {
//       await axios.post(endPointUrl + deleteUrl, {
//         Token,
//         [id]: Id,
//       });
//       getAllskills();
//       message.success("skill deleted successfully");
//     } catch {
//       message.error("something went wrong");
//     }
//   };
//   const handleUpdate = async (Id, value) => {
//     try {
//       await axios.post(endPointUrl + updateurl, {
//         Token,
//         [id]: Id,
//         Name: value,
//       });
//       message.success("skill updated successfully");
//       getAllskills();
//     } catch {
//       message.error("something went wrong");
//     }
//   };
//   const handleAdd = async () => {
//     try {
//       await axios.post(endPointUrl + addurl, {
//         Token,
//         Name: skill,
//       });
//       getAllskills();
//       handleAddshow();
//       message.success("skill added successfully");
//     } catch {
//       message.error("something went wrong");
//     }
//   };
//   const getAllskills = async () => {
//     try {
//       const { data } = await axios.post(endPointUrl + getUrl, {
//         Token,
//       });
//       setallskills(data.data);
//       console.log(data.data);
//     } catch {
//       console.log("get skill is not working");
//     }
//   };
//   const handleAddshow = () => setshowAdd(!showAdd);

  
 



  const data = React.useMemo(() => milestone, [milestone]);
  const columns = React.useMemo(
    () => [
      {
        Header: "M_ID",
        accessor: "M_ID",
      },
      {
        Header: "Amount",
        accessor: "Amount", // accessor is the "key" in the data
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "M_Due_Date",
        accessor: "M_Due_Date",
      },
      {
        Header: "MilstoneStatus",
        accessor: "MilstoneStatus",
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <>
      

     
        
  
            
                    <input
                    className="search-box"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={globalFilter||''} onChange={(e)=>setGlobalFilter(e.target.value)} 
                    />
                 

               
             <table>
                  <thead>
                  {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                  {page.map((row,index) => {
  prepareRow(row);
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        // Check if the cell value is a date
        const { column } = cell;
        if (column.id === 'M_Due_Date') {
          const date = new Date(cell.value);
          const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          return <td {...cell.getCellProps()}>{formattedDate}</td>;
        }
        if(column.id==="M_ID"){
          return <td>{cell.render(index+1)}</td>
        }

        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
      })}
    </tr>
  );
})}

                  </tbody>
                
              {/* <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-primary text-white bg-primary px-2">
          {'Start'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-primary text-white  bg-primary px-2">
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-primary text-white  bg-primary px-2">
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="btn btn-primary text-white  bg-primary px-2">
          {'End'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[5,10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div> */}
          </table>
     
    </>
  );
}

export default Master;
