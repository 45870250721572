import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { MyContext } from "../../../Mycontext";
import { MdOutlineCancel } from 'react-icons/md';
import editbutton from '../../../images/edit.png'
import deletebutton from '../../../images/delet2.png'
import { message } from 'antd';
import Selectant from '../../Jobs/Select';

function EditPortfolio({ Portfolio, getPortfolio }) {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [showmodel, setshowModel] = useState(false);
    const [skillmaster, setskillmaster] = useState([]);
    const [preform, setPreform] = useState([]);
    const [File_Type, setFile_Type] = useState([])
    const [newForm, setNewForm] = useState({
        Title: Portfolio.Title || "",
        Description: Portfolio.Description || "",
        Type: Portfolio.Type || "",
        Completion_Date: Portfolio.Completion_Date.slice(0, 10) || "",
        Skills: Portfolio.Skills || "",
        Project_Link: Portfolio.Project_Link || "",
    });
    const [skillSelected, setSkillSelected] = useState([]);

    // Use a useEffect hook to update the Skills field in the form state
    useEffect(() => {
        const skillsString = skillSelected.join(", ");
        setNewForm({ ...newForm, Skills: skillsString });
    }, [skillSelected]);

    const handleskillsmaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push(item.Name));
        setskillmaster(a);
    };

    // Delete Portfolio
    const deletePortfolio = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/deletePortfolio", {
                Token,
                UserID: user.id,
                PF_Id: Portfolio.PF_Id
            });
            message.success("Portfolio Deleted");
            getPortfolio();
        } catch (err) {
            console.log(err);
        }
    }
    function getFileExtension(filename) {
        var a = filename.split(".");
        if (a.length === 1 || (a[0] === "" && a.length === 2)) {
            return "";
        }
        return a.pop();
    }
    const handlefiles = () => {
        let arr = [];
        preform.map((file) => arr.push(getFileExtension(file.name)));
        setFile_Type(arr);
    }

    useEffect(() => {
        if (File_Type.length > 0) { uploadfiles() }
    }, [File_Type])


    const uploadfiles = async () => {
        console.log("uploadfilesPreform", preform)
        console.log("uploadfilesType", File_Type)
        const formdata = new FormData();
        preform.map((item) => formdata.append('File_Upload', item))

        try {
            const { data } = await axios.post(
                endPointUrl + "/user/docupload", formdata //Files array upload
            );

            try {
                await axios.post(endPointUrl + "/user/addportfolioDoc", {
                    Token,
                    PF_Id: Portfolio.PF_Id,
                    UserID: user.id,
                    File_Upload: data.filenames,
                    File_Type: File_Type
                });
                message.success("Portfolio Added")
                getPortfolio();
            } catch {
                console.log("upload url produce error");
            }
        } catch {
            console.log("error occur during upload docs");
        }
    }

    //Add File in preform
    const handlefileAdd = (files) => {
        setPreform([...files]);
    }
    // Edit Portfolio
    const editPortfolio = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/updatePortfolio", {
                Token,
                PF_Id: Portfolio.PF_Id,
                // Include fields from newForm here for updating
                Title: newForm.Title,
                Description: newForm.Description,
                Type: newForm.Type,
                Completion_Date: Portfolio.Completion_Date || newForm.Completion_Date,
                Skills: newForm.Skills,
                Project_Link: newForm.Project_Link,

            });
            // setNewForm({
            //     Title: data.data.Title || "",
            //     Description: data.data.Description || "",
            //     Type: data.data.Type || "",
            //     Completion_Date: data.data.Completion_Date.slice(0, 10) || "",
            //     Skills: data.data.Skills || "",
            //     Project_Link: data.data.Project_Link || "",
            // })

            handlefiles();
            // Handle the response as needed
        } catch (err) {
            console.log(err);
        }
    }
    // const handlefiles = () => {
    //     console.log(preform)
    //     let arr = [];
    //     Array.from(preform).map((file) => arr.push(getFileExtension(file.File_Upload)));
    //     setFile_Type(arr);
    // }

    const handleshowform = async () => {
        handleskillsmaster();
        setPreform(Portfolio.docs)
        setSkillSelected(Portfolio.Skills.split(', '))
        setshowModel(true);
    }

    return (
        <>
            <div className="edit-icons">
                <img id="edit-btn" src={editbutton} alt="edit" onClick={handleshowform} />
                <img id="del-btn" src={deletebutton} alt="delete" onClick={deletePortfolio} />
            </div>
            <div id="myModal" className={showmodel ? "model-open" : "model"}>
                <div className="modal-content" style={{ padding: "0" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                        <span style={{ color: "#46c294", fontWeight: "600" }}>Update Portfolio</span>
                        <span className="close" onClick={(() => setshowModel(false))}>
                            &times;
                        </span>
                    </div>
                    <div style={{ padding: "0 40px", margin: "30px 0" }}>
                        <form className="model-field exp-form"
                            encType="multipart/form-data"
                            onSubmit={(e) => {
                                e.preventDefault();
                                setshowModel(false);
                                editPortfolio(); // Call the editPortfolio function here
                            }}>
                            <div style={{ paddingInlineEnd: "20px" }}>
                                <div>
                                    {" "}
                                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                        <div>
                                            <label htmlFor="title">Title</label>
                                            <input
                                                style={{ marginInlineEnd: "12%" }}
                                                type="text"
                                                name="title"
                                                value={newForm.Title}
                                                placeholder='Enter title'
                                                onChange={(e) => setNewForm({ ...newForm, Title: e.target.value })}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="Completion_Date">Date</label>
                                            <input
                                                value={newForm.Completion_Date}
                                                type="date"
                                                name="Completion_Date"
                                                placeholder="Enter Completion_Date"
                                                onChange={(e) =>
                                                    setNewForm({ ...newForm, Completion_Date: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                        <div>
                                            <label htmlFor="project_type">Project Type</label>
                                            <select
                                                style={{ marginInlineEnd: "12%", marginBottom: "0", width: "100%" }}
                                                name="project_type"
                                                value={newForm.Type}
                                                onChange={(e) => setNewForm({ ...newForm, Type: e.target.value })}
                                            >
                                                <option value="">Project Type</option>
                                                <option value="Website">Website</option>
                                                <option value="Mobile App">Mobile App</option>
                                                <option value="ERP">ERP</option>
                                                <option value="SPA">SPA</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="SkillsRequired">Skills</label>
                                            <Selectant
                                                label={"Enter Skills"}
                                                options={skillmaster}
                                                setselected={setSkillSelected}
                                                selected={skillSelected}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <div >
                                            <label htmlFor="descrption" style={{ display: "block" }}>Description</label>
                                            <textarea
                                                style={{ width: "98%" }}
                                                name="description"
                                                id=""
                                                cols="30"
                                                rows="3"
                                                maxLength={500}
                                                value={newForm.Description}
                                                onChange={(e) => setNewForm({ ...newForm, Description: e.target.value })}
                                                placeholder='Enter Description'></textarea>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                            <div>
                                                <label htmlFor="file" style={{ display: "block" }}>Files</label>
                                                <input
                                                    style={{ marginInlineEnd: "12%" }}
                                                    type="file"
                                                    name="File_Upload"
                                                    multiple
                                                    // onChange={(e) => { setPreform(e.target.files) }}
                                                    onChange={(e) => { handlefileAdd(e.target.files) }}
                                                // onChange={handlefileAdd}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="Project_Link">Project Link</label>
                                                <input
                                                    style={{ marginInlineEnd: "12%" }}
                                                    type="text"
                                                    name="Project_Link"
                                                    value={newForm.Project_Link}
                                                    placeholder='Enter Link'
                                                    onChange={(e) => setNewForm({ ...newForm, Project_Link: e.target.value })}
                                                />
                                            </div>

                                        </div>
                                        <div>
                                            {preform && preform.length > 0 && (
                                                <div>
                                                    <h4>Selected Files:</h4>
                                                    <ul>
                                                        {Array.from(preform).map((file, index) => (
                                                            <li key={index} style={{ display: "grid", gridTemplateColumns: "30% 60%", alignItems: "center", gap: "30px" }}>
                                                                <span style={{ overflowWrap: "break-word" }}><strong>Type:</strong> {file.File_Type || file.type}</span>
                                                                <span style={{ overflowWrap: "break-word" }}> <strong>Name:</strong> {file.File_Upload || file.name}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                    <input type="submit" className="btn" value={'Add Portfolio'} style={{ width: "20%", backgroundColor: "#e3e3e3", color: "#000" }} />
                                    <input type="submit" className="btn" value={"Submit"} />
                                </div>
                                {/* <div style={{ display: "flex", alignItems: "center" }}>
                                    <button style={{ padding: "10px 50px", fontSize: "16px" }} className='btn'>Submit</button>
                                </div> */}
                            </div>
                        </form></div>

                </div>
            </div>
        </>
    )
}

export default EditPortfolio;
