import React, { useEffect, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { CLIENT_ID } from '../../Config/Config'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import moment from "moment";
import { message } from 'antd';
import { MyContext } from "../../Mycontext";
import axios from 'axios';
import { FUNDING } from "@paypal/react-paypal-js";
import { useNavigate, Link } from 'react-router-dom'

const MilestonePayment = () => {
  const { Token, endPointUrl, user } = useContext(MyContext);
  const [show, setShow] = useState({
    show1: false,
    show2: false,
    show3: false
  });
  const [Orderdata, setOrderdata] = useState({
    ID: "",
    time: ""
  });
  const [BidID, setBidID] = useState();
  const [MID, setMID] = useState();
  const [Index, setIndex] = useState();
  const [value, setValue] = useState(10);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getIndex = () => {
    console.log(location.state.milestone);
    for (let index = 0; index < location.state.milestone.length; index++) {
      const item = location.state.milestone[index];
      if (item.EmployerPaymentStatus === "Pending") {
        setIndex(index);
        break;
      }
    }
  }
  const createOrder = (data, actions) => {
    // console.log(data);
    return actions.order.create({
      purchase_units: [
        {
          description: "Milestone",
          amount: {
            currency_code: "USD",
            value: value,
          },
        },
      ],
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (orderData) {
      setOrderdata({
        ID: orderData.id,
        time: orderData.create_time
      })
      setSuccess(true);
      console.log(orderData);
    })
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
      message.success("Payment successful!!");

      Payment();
      // console.log('Order successful . Your order id is--', orderID);
      handleApprovedReject("Approved", BidID),
        updateAppprovedReject("Approved")
    }
  }, [success]);
  const Payment = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/applypayment", {
        Token,
        EmployerID: user.id,
        M_ID: MID,
        Payment_date: Orderdata.time,
        JobID: location.state.item.JobID,
        Bid_ID: BidID,
        Amount: value,
        PaymentReference: Orderdata.ID,
      });
      console.log("PaymentID", data.PaymentID)
      message.success("confirmed")
      // if (Index === 0) {
      addprojects(data.PaymentID);
      // }

    } catch {
      message.error("error while adding payment to logs");
    }
  };
  const addprojects = async (PaymentID) => {
    console.log(location.state.item.Duration);
    try {
      await axios.post(endPointUrl + "/addProjects", {
        Token,
        Awarded_By_ID: user.id,
        Bid_ID: BidID,
        Awarded_To_ID: location.state.item.UserID,
        JobID: location.state.item.JobID,
        Project_Deadline: location.state.item.Duration,
        PaymentID: PaymentID
      })
      message.success('Project Added Successfully!')
    } catch {
      message.error('error while adding Project')
    }
    navigate(-1);
  };
  const updateMilestone = async (PM_ID, Status, ind, P_ID) => {
    if (Status === "Approved") {
      try {
        await axios.post(endPointUrl + "/project/updatemilestone", {
          Token,
          PM_ID: PM_ID,
          MilstoneStatus: Status,
          FreelancerPaymentStatus: "Released"
        })
        if (ind == (location.state.milestone.length - 1)) {
          await axios.post(endPointUrl + "/UpdateProjects", {
            Token,
            P_ID: P_ID,
            Project_status: "Completed"
          })
        }
        message.success(`Milestone ${Status} successfully`)
      } catch {
        message.error("Unsuccessful in Approving milestone")
      }
    } else {
      try {
        await axios.post(endPointUrl + "/project/updatemilestone", {
          Token,
          PM_ID: PM_ID,
          MilstoneStatus: Status,
        })
        message.success(`Milestone ${Status} successfully`)
      } catch {
        message.error("Unsuccessful in Rejecting milestone")
      }
    }
    navigate(-1);
  };
  const updateAppprovedReject = async (status) => {
    try {
      await axios.post(endPointUrl + "/user/UpdateJobs", { Token, JobID: location.state.item.JobID, Status: status })
    } catch {
      message.error('error in updating status')
    }
  };
  const handleApprovedReject = async (status, Bid_ID) => {
    try {
      await axios.post(endPointUrl + "/user/updateBid", { Token, Bid_ID: Bid_ID, Bid_Status: status })
      // message.success(`Bid ${status} successfully`)
    } catch {
      message.error("Some occur on Approved/Rejection");
    }

  };
  const handleclick = (Amount, Bid_ID, M_ID) => {
    setValue(Amount);
    setBidID(Bid_ID);
    setMID(M_ID);
  };
  // const handleclick3 = (Amount , Bid_ID , M_ID) => {
  //   handleApprovedReject("Approved", Bid_ID),
  //   updateAppprovedReject("Approved"),
  //   updateMilestone(M_ID)
  // };
  useEffect(() => {
    getIndex();
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <div className="home-howitwork" style={{ position: "absolute", top: "101px", left: "51px" }}>
        <Link to="/EmployerJobProfile">Home </Link>/{" "}
        <Link to="/milestonePayment">Milestone Payments </Link>

      </div>
      <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
        <div className='Container'>
          <div className='header_Container'>
            <p>Complete your payment to approve this Project</p>
            <h2><span id='highlight'>Milestone</span> Payment</h2>
          </div>
          <div className='cards_Container' style={{ flexDirection: "column", marginInline: "17%" }}>
            {location.state.milestone.map((item, index) =>

              <div className='milestone_Card' key={index}>
                <div className='mil_head'>
                  <h4 style={{ fontSize: "25px", marginBlock: "20px" }}>Milestone #{index + 1}</h4>
                  {item.EmployerPaymentStatus !== "Pending" ? item.MilstoneStatus === "Up for Review" ?
                    <div className="biddingButton" style={{ margin: "0" }}>
                      <button className='mil_buton' style={{ background: "#07b273", fontSize: "14px", border: "2px solid #07b273", borderRadius: "5px" }}
                        onClick={() => updateMilestone(item.PM_ID, "Approved", index, item.P_ID)}
                      >
                        Approve</button>{" "}
                      <button className='mil_buton' style={{ background: "#bd5656fc", fontSize: "14px", border: "2px solid #bd5656fc", borderRadius: "5px" }}
                        onClick={() => updateMilestone(item.PM_ID, "Rejected", index)}
                      >
                        Reject</button>
                    </div>
                    : <button className='mil_buton disabled'
                    // onClick={()=>{handleclick(item.Bid_ID, item.M_ID)}}
                    >Released</button>
                    : Index === index ?
                      <button className='mil_buton'

                        onClick={() => { setShow({ show1: !show.show1 }), handleclick(item.Amount, item.Bid_ID, item.M_ID) }}
                      >Release Payment</button>
                      : null
                  }
                  {show.show1 && Index === index ?
                    <PayPalButtons
                      fundingSource={FUNDING.PAYPAL}
                      style={{ layout: "vertical", color: "white", shape: "pill" }}
                      createOrder={(data, actions) => createOrder(data, actions)}
                      onApprove={onApprove}
                    />
                    : null}

                </div>
                <hr />
                <div className="mil_desc" style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <div className='mil_detail'>
                      <h5>Amount:</h5> <span>{item.Amount}</span>
                    </div>
                    <div className='mil_detail'>
                      <h5>Employer Payment Status:</h5> <span>{item.EmployerPaymentStatus}</span>
                    </div>
                    <div className='mil_detail'>
                      <h5>Milestone Status:</h5> <span>{item.MilstoneStatus}</span>
                    </div>
                    <div className='mil_detail'>
                      <h5>Freelancer Payment Status:</h5> <span>{item.FreelancerPaymentStatus}</span>
                    </div>
                    <div className='mil_detail'>
                      <h5>Due Date:</h5> <span>{moment(item.M_Due_Date).format("DD-MM-YYYY")}</span>
                    </div>
                  </div>
                  <div className='mil_detail'>
                    <h5>Description:</h5><span>{item.Description}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </PayPalScriptProvider>
    </>
  )
}

export default MilestonePayment