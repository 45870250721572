import React from 'react'
import pay from '../images/Pay.png'
function PayFreelancer() {
  return (
    <div className="SignUpEmployee">
      <h1>Get Paid</h1>
      <p className="sign-up-content">
        Our safe payment system will ensure that you receive payment for your work in a timely manner.
      </p>
      <div className="sign-up-content-img">
        <div className="sign-up-img"><img src={pay} alt="" /></div>
        <div className="sign-up-points">
          <div className="sign-up-points-numbers">
            <div className="number">1</div>
            <div className="content"><h3>Use SafePay</h3>
              <p>Ask the Employer to fund SafePay with a minimum balance prior to beginning the job to guarantee that your work is funded.</p>
            </div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">2</div>
            <div className="content"><h3>Use auto-pay to streamline your payments </h3>
              <p>To guarantee that you will get paid automatically after finishing work for your online freelance jobs, choose AutoPay.</p></div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">3</div>
            <div className="content"><h3>Pick your preferred payment method</h3>
              <p>Choose from our available payment options to ensure a fast and smooth payment disbursal </p>
            </div>
          </div>





        </div>

      </div>

    </div>
  )
}

export default PayFreelancer