import React, { useEffect, useState, useContext } from "react";
import Profile from "../images/profile.jpg";
import Frame from "../images/Frame.svg"
import dolar from "../images/dolar.png";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AiOutlineFilePdf, AiOutlineFileText, AiFillStar } from 'react-icons/ai';
import { MyContext } from "../Mycontext";
import { MdVerified } from 'react-icons/md';
import Certificate from "./user-profile/Certificate/AddCertificate";
import { Popup } from "semantic-ui-react";
import img from '../images/veryfied.png';

function UserProfile() {
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [item, setitem] = useState({});
  const [education, seteducation] = useState([]);
  const [Exp, setExp] = useState([]);
  const [PortData, setPortData] = useState([]);
  const [CertData, setCertData] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const { nameid } = useParams();
  let skills = item?.Skills ? item.Skills.split(",") : [];
  // let technology = item?.Technology ? item.Technology.split(',') : [];
  const getuser = async () => {
    let id = nameid.split("-")[1];
    try {
      const { data } = await axios.post(endPointUrl + "/getUserById", {
        Token,
        id,
      });
      setitem(data.data);

    } catch {
      console.log("error for geting the userProfile");
    }
  };
  const getUserEducation = async () => {
    let id = nameid.split("-")[1];
    try {
      const { data } = await axios.post(
        endPointUrl + "/user/getuserEducation",
        { UserID: id, Token }
      );
      seteducation(data.data)
    } catch {
      console.log('error at education api')
    }
  };
  const getExp = async () => {
    let id = nameid.split("-")[1];
    const { data } = await axios.post(endPointUrl + "/user/getuserExp", {
      UserID: id,
      Token,
    });
    setExp(data.data);
  };
  const getPortfolio = async () => {
    let id = nameid.split("-")[1];
    const { data } = await axios.post(endPointUrl + "/user/getportfolioByUserID", {
      Token,
      UserID: id
    });
    if (data.data != null) {
      setPortData(data.data);
    }
  }
  const Analytics = async () => {
    let id = nameid.split("-")[1];
    try {
      const { data } = await axios.post(endPointUrl + "/analyticsforfreelancer", {
        id: id,
        Token
      });
      setAnalytics(data.data);
    } catch {
      message.error("Error while fetching analytics")
    }
  };
  const getCertificate = async () => {
    let id = nameid.split("-")[1];
    const { data } = await axios.post(endPointUrl + "/user/getCertiByUserID", {
      Token,
      UserID: id
    });
    if (data.data != null) {
      setCertData(data.data);
    }

  }
  useEffect(() => {
    window.scroll(0, 0);
    getuser();
    getUserEducation();
    getExp();
    Analytics();
    getPortfolio();
    getCertificate();
  }, []);
  return (
    <div className="HowItWorks">
      <div className="home-howitwork">
        <Link to="/">Home </Link>/{" "}
        <Link to="/HireFreelancers">Freelancers</Link>/{" "}
        <Link to="/userProfile">{item.Name} {item.Last_Name}</Link>
      </div>
      <div className="profile-Apart">
        <img
          src={
            item.Photo === "" || item.Photo == null
              ? Profile
              : `${endPointUrl}/images/${item.Photo}`
          }
          alt=""
        />
        <div className="user-name">
          {/* <Popup
            trigger={item.Verified_Profile == "Verified" ? <MdVerified style={{ color: "#0dd50d", marginLeft: "auto", width: "20px", height: "auto" }} /> :
              <MdVerified style={{ color: "rgb(133 133 133)", marginLeft: "auto" }} />}
            content={item.Verified_Profile == "Verified" ? "Profile Verified" :
              item.Verified_Profile == "Pending" ? "Profile verification Pending" :
                "Profile Unverified"}
          /> */}
          <h2>{item.Name} {item.Last_Name}</h2>
          {/* <span>{item.Title}</span> */}
        </div>
        <div className="user-reviews-address">
          <span className="rating" style={{ paddingLeft: "8px", fontSize: "16px", paddingBottom: "9px" }}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 16 15" fill="#FF8A00" className="rating-star">
            <path d="M8.00027 12.1734L3.29827 14.8054L4.34827 9.52004L0.391602 5.86137L5.74293 5.22671L8.00027 0.333374L10.2576 5.22671L15.6089 5.86137L11.6523 9.52004L12.7023 14.8054L8.00027 12.1734Z" fill="#FF8A00" />
          </svg> */}
            <AiFillStar className="featuredfreelancer-rating-star" />
            {item.avg_Rating ? item.avg_Rating : 0} <p>({item.no_of_reviews ? item.no_of_reviews : 0} Reviews)</p></span>
          {/* <span>|  <img src={Frame} alt="location" style={{ paddingRight: "3px", paddingLeft: "18px", width: "18px" }} /> {item?.City} {item?.City ? "," : null} {item?.Country}</span> */}
        </div>
      </div>

      <div className="about-rate">
        <div style={{ width: "69%" }}>
          <div className="about">
            <b>About</b>
            <p>{item.About}</p>
          </div>
          <div className="education">
            <strong>Education</strong>
            <div className="all-education">
              <div className="education-line"></div>
              <div>
                {education.map((val, index) => (
                  <div className="eduction-time" key={index}>
                    <div className="year-range">{val.Year}</div>
                    <div className="collage">{val.University}</div>
                    <p>
                      {val.DegreeName}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="education">
            <strong>Work & Experience</strong>
            <div className="all-education">
              <div className="education-line"></div>
              <div>
                {Exp.map((val, index) => (
                  (val.Company && val.From) ? (
                    <div className="eduction-time" key={index}>
                      <div className="year-range" >
                        {val.From.substring(0, 4)} -{" "}
                        {val.Current ? "Present" : val.to.substring(0, 4)}{" "}
                      </div>
                      <div className="collage">
                        {val.Company + " " + val.Location}
                      </div>
                      <div>{val.Title}</div>
                      <p>{val.Description}</p>
                    </div>
                  ) : (null)
                ))}
              </div>
            </div>
          </div>

          <div className="education">
            <strong style={{ display: "flex", alignItems: "center" }}>
              Certificates
            </strong>

            {CertData.map((item, index) =>
              item?.UserID ?
                <div className="all-education" key={index}>
                  <div className="education-line">
                  </div>
                  <div className="education-conainer" >
                    <div className="eduction-time" >
                      <div>
                        <div style={{ display: "flex", justifyContent: "center" }}><span style={{ color: "black", fontSize: "20px", fontWeight: "600", margin: "5px" }}>{item?.Type}</span></div>
                        <div className="year-range">
                          {item?.Completion_Date?.slice(5, 7)}, {" "}
                          {item?.Completion_Date?.slice(0, 4)} - {" "}
                          {item?.Completion_Expire_Date?.slice(5, 7)}, {" "}
                          {item?.Completion_Expire_Date?.slice(0, 4)}
                        </div>
                        <div className="collage">{item?.Title}</div>
                        <div className="Portfolio-docs" style={{ display: "flex", alignItems: "center", gap: "20px", margin: "20px 0" }}>
                          <div className="potfolioConatiner" style={{ position: "relative" }}>
                            <a
                              href={`${endPointUrl}/certificates/${item.Certificate_Image}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={`${endPointUrl}/certificates/${item.Certificate_Image}`}
                                style={{ width: "50px", height: "50px", borderRadius: "25%" }}
                                alt="cert"
                                className="potfoliothumbail"
                              />
                            </a>
                          </div>

                        </div>
                        <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
                      </div>
                    </div>
                  </div>
                </div> : null
            )}
          </div>

          <div className="education">
            <strong style={{ display: "flex", alignItems: "center" }}>
              Portfolio
            </strong>
            {PortData?.map((item, index) =>
              item?.UserID ?
                <div key={index} className="all-education">
                  <div className="education-line">
                  </div>
                  <div className="education-conainer" >
                    <div className="eduction-time" >
                      <div>
                        <div style={{ display: "flex", justifyContent: "center" }}><span style={{ color: "black", fontSize: "20px", fontWeight: "600", margin: "5px" }}>{item?.Type}</span></div>

                        <div className="year-range">{item?.Completion_Date.slice(0, 10)}</div>
                        <div className="collage">{item?.Title}</div>
                        <p style={{ fontSize: "12px" }}>
                          {item?.Description}
                        </p>

                        <div className="btn-popular skills">
                          {item.Skills?.length < 1 ? <button className="skill">.... ....</button> : null}
                          {item.Skills?.split(',').map((it, index) => {
                            if (index < 5) {
                              return (
                                <button key={index} className="skill">
                                  {it}
                                </button>
                              );
                            }
                          })}
                          {item.Skills?.split(',').length > 5 ? "+" : ""}
                          {item.Skills?.split(',').length > 5 ? item.Skills?.split(',').length - 5 : ""}
                        </div>
                        <div className="Portfolio-docs" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px", margin: "20px 0" }}>
                          {item.docs?.map((it, index) =>
                            <div key={index}>
                              <a
                                href={`${endPointUrl}/portfoliodoc/${it.File_Upload}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                title={it.File_Name}
                              >
                                {it.File_Type === "png" ||
                                  it.File_Type === "jpg" ||
                                  it.File_Type === "jpeg" ||
                                  it.File_Type === "gif" ||
                                  it.File_Type === "webp" ||
                                  it.File_Type === "jfif" ? (
                                  <img
                                    src={`${endPointUrl}/portfoliodoc/${it.File_Upload}`}
                                    style={{ width: "50px", height: "50px", borderRadius: "25%" }}
                                    alt={it.File_Type} // Add alt attribute for accessibility
                                  />
                                ) : it.File_Type === "pdf" ? (
                                  <AiOutlineFilePdf style={{ width: "50px", height: "50px" }} />
                                ) : (
                                  <AiOutlineFileText style={{ width: "50px", height: "50px" }} />
                                )}
                              </a>

                            </div>
                          )
                          }
                        </div>
                        <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
                      </div>
                    </div>
                  </div>
                </div> : null
            )}
          </div>

        </div>
        <div className="skills-tech-container">
          <div className="rate-card" >
            <img src={img} alt="" style={{ filter: item.Verified_Profile == "Verified" ? "none" : "grayscale(1)", marginLeft: "auto", position: "absolute", top: "10px", right: "10px" }} />

            {/* <Popup
              trigger={item.Verified_Profile == "Verified" ? <MdVerified style={{ color: "rgb(7 178 116)", marginLeft: "auto", position: "absolute", top: "10px", right: "10px", fontSize: "25px" }} /> :
                <MdVerified style={{ color: "rgb(133 133 133)", marginLeft: "auto", position: "absolute", top: "10px", right: "10px", fontSize: "35px" }} />}
              content={item.Verified_Profile == "Verified" ? "Profile Verified" :
                item.Verified_Profile == "Pending" ? "Profile verification Pending" :
                  "Profile Unverified"}
            /> */}
            <div className="price-range">
              <img src={dolar} alt="" />
              <div className="hour-price">
                <span className="rate">Rate:</span>
                <span>${item?.rate_from} / {item?.rate_type}</span>
              </div>
            </div>
            <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
            <div className="user-details">
              <p>Completed Projects </p>
              <span> {analytics.completedProject} </span>
            </div>
            <div className="user-details">
              <p>Success Rate</p>
              <span>{analytics.successRate}% </span>
            </div>
            <div className="user-details">
              <p>Language</p>
              <span>{item?.Language?.split(', ').join(', ')}</span>
            </div>
            <div className="user-details">
              <p>Total Earnings</p>
              <span>${analytics.totalEarning}</span>
            </div>
            <div className="user-details">
              <p>Gender</p>
              <span>{item.Gender}</span>
            </div>
            <hr style={{ borderTop: '1px solid #f1eded' }}></hr>

            <div className="skills-card" style={{ margin: "10px 0px" }}>
              <h5>Skills</h5>
              <div className="skills-employee " >
                {skills.map((val) => (
                  <strong style={{ color: "#000000", borderRadius: "15px", border: "1px solid #c6bbbb87", padding: "5px 10px" }} key={val}>{val}</strong>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  );
}

export default UserProfile;
