import TShirtDesignCard from "../components/TShirtDesignCard";
import { BsArrowRightCircle} from 'react-icons/bs'

import arrow from'../images/right-arrows.png'
const BlogWrapper = () => {
  return (
    <div className="blogs">
        <h2 className="our-latest-news">Our Latest News</h2>
     
      <div className="all-blogs">
      <TShirtDesignCard/>
      <TShirtDesignCard/>
      <TShirtDesignCard/>
      </div> 
      <a href="https://digilanxer.com/blog" target="_blank" style={{marginBlock:"20px"}}>
        <button className="btn-outline view-btn">View All <BsArrowRightCircle /></button>
      </a>
    </div>
  );
};

export default BlogWrapper;
