import React from 'react'
import pay from '../images/Pay.png'
function PayEmployee() {
  return (
    <div className="SignUpEmployee">
      <h1>Pay</h1>
      <p className="sign-up-content">
        No more worries about payment management! Let our safest and most secured payment system do the job for you !
      </p>
      <div className="sign-up-content-img">
        <div className="sign-up-img"><img src={pay} alt="" /></div>
        <div className="sign-up-points">
          <div className="sign-up-points-numbers">
            <div className="number">1</div>
            <div className="content"><h3>Bring our safe payment system to use !</h3>
              <p>Pay only for work that keeps up with your provided expectations via safe pay</p>
            </div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">2</div>
            <div className="content"><h3>Use Auto-pay to manage your payments !</h3>
              <p>Make auto-payments to freelancer automatically via our Auto-pay feature !</p></div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">3</div>
            <div className="content"><h3>Choose how you want to pay !</h3>
              <p>
                Make use of our available payment methods for easy disbursal of all your pending payments! </p>
            </div>
          </div>





        </div>

      </div>

    </div>
  )
}

export default PayEmployee