import React, { useContext, useState } from "react";
import { MyContext } from "../Mycontext";
import Profile from "../images/profile.jpg";
import Arrow from "../images/Arrow.png";
import { useNavigate } from "react-router-dom";
import unlike from "../images/unlike.png";
import like from "../images/like.png";
import { MdVerified } from 'react-icons/md';
import { Popup } from "semantic-ui-react";
import { AiFillStar } from 'react-icons/ai';
import img from '../images/veryfied.png'

function ProfilesCard({ item }) {
  const [Like, setlike] = useState(false);
  const { endPointUrl } = useContext(MyContext);
  let Skills = item?.Skills ? item.Skills.split(",") : [];
  const name = item.Name.split(" ")[0];
  const id = item.id;
  const Navigate = useNavigate();
  return (
    <div className="freelancers-card">
      <div className="imglike" style={{ width: "94%", display: "flex" }}>
        <img src={img} alt="" style={{ filter: item.Verified_Profile == "Verified" ? "none" : "grayscale(1)" }} />
        {/* <Popup
          trigger={item.Verified_Profile == "Verified" ?
           <MdVerified style={{ color: "rgb(7 178 116)", marginLeft: "auto", width: "30px", height: "auto" }} /> :
            <MdVerified style={{ color: "rgb(133 133 133)", marginLeft: "auto", width: "30px", height: "auto" }} />
          }
          content={item.Verified_Profile == "Verified" ? "Profile Verified" :
            item.Verified_Profile == "Pending" ? "Profile verification Pending" :
              "Profile Unverified"}
        /> */}
      </div>
      <img
        src={item.Photo ? `${endPointUrl + "/images/" + item.Photo}` : Profile}
        alt="profile"
      />
      <span className="name">{item.Name} {item.Last_Name}</span>
      {/* <span className="profession">{item.Title ? item.Title : "--- ---"}</span> */}
      <span className="rating">
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 16 15" fill="#FF8A00" className="rating-star">
        <path d="M8.00027 12.1734L3.29827 14.8054L4.34827 9.52004L0.391602 5.86137L5.74293 5.22671L8.00027 0.333374L10.2576 5.22671L15.6089 5.86137L11.6523 9.52004L12.7023 14.8054L8.00027 12.1734Z" fill="#FF8A00" />
      </svg> */}
        <AiFillStar className="featuredfreelancer-rating-star" />
        {item.avg_Rating ? item.avg_Rating : 0} <p>({item.no_of_reviews ? item.no_of_reviews : 0} Reviews)</p></span>
      <div className="rate">
        <span>Rate:</span>
        ${item?.rate_from} / {item?.rate_type}
      </div>

      <hr className="line" />
      <div className="location-content btn-popular skills">
        {/* <div className="btn-popular skills"> */}
        {Skills.length < 1 ? <button className="skill">.... ....</button> : null}
        {Skills.map((item, index) => {
          if (index < 3) {
            return (
              <button key={index} className="skill">
                {item}
              </button>
            );
          }
        })}
        {Skills.length > 3 ? "+" : ""}
        {Skills.length > 3 ? Skills.length - 3 : ""}

      </div>
      <hr className="line" />
      <div className="view-profile" onClick={() => Navigate(`/userProfile/${name + "-" + id}`)}>
        View Profile
        <img
          src={Arrow}
          alt=""
          onClick={() => Navigate(`/userProfile/${name + "-" + id}`)}
        />
      </div>
    </div>
  );
}

export default ProfilesCard;
