import React, { useContext } from "react";
import editbutton from "../../images/edit.png";
import deletebutton from "../../images/delet2.png";
import { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { MyContext } from "../../Mycontext";
import add from "../../images/add.png";
function WorkExp() {
  const { endPointUrl, user, Token } = useContext(MyContext);
  const [exp, setExp] = useState([]);
  const [edititem, setedititem] = useState([]);
  const [showEdit, setshowEdit] = useState(false);
  const [newExp, setnewExp] = useState({
    Company: null,
    Location: null,
    Designation: null,
    From: null,
    to: null,
    Description: null,
  });

  const [errorForm, setErrorForm] = useState({
    Company: "",
    Location: "",
    Designation: "",
    From: "",
    to: "",
    Description: "",
  });

  const [showmodel, setshowModel] = useState(false);
  const getExp = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getuserExp", {
      UserID: user.id,
      Token,
    });
    setExp(data.data);
  };
  useEffect(() => {
    getExp();
  }, [user]);
  const addExp = async (Exp) => {
    const { data } = await axios.post(endPointUrl + "/user/userExp", {
      ...Exp,
      UserID: user.id,
      Token,
    });
    getExp();
  };
  const handleEditExpModel = (Item) => {
    setshowEdit(true);
    let tem = exp.filter((item) => {
      return item.U_Ex_ID === Item.U_Ex_ID
    });
    setedititem(tem);
  };

  const handleEditsubModel = (e) => {
    e.preventDefault();
    updateExp();
    setshowEdit(false);
  };

  const handleExpModel = () => {
    setErrorForm({
      Company: "",
      Location: "",
      Designation: "",
      From: "",
      to: "",
      Description: "",
    })
    setshowModel(true);
    getExp();
  };
  const CloseModel = () => {
    setshowModel(false);
    setshowEdit(false);
  };
  const handlesubmitExpModel = (e) => {
    e.preventDefault();
    const newFormErrors = {
      Company: "",
      Location: "",
      Designation: "",
      From: "",
      to: "",
      Description: "",
    };
    let showError = false;
    if (newExp.Company === null || newExp.Company === "") {
      showError = true;
      newFormErrors.Company = 'Please fill out this field!';
    } else {
      newFormErrors.Company = '';
    }
    if (newExp.Location === null || newExp.Location === "") {
      showError = true;
      newFormErrors.Location = 'Please fill out this field!';
    }
    else {
      newFormErrors.Location = "";
    }
    if (newExp.Designation === null || newExp.Designation === "") {
      showError = true;
      newFormErrors.Designation = 'Please fill out this field!';
    } else {
      newFormErrors.Designation = '';
    }
    if (newExp.From === null || newExp.From === "") {
      showError = true;
      newFormErrors.From = 'Please fill out this field!';
    } else {
      newFormErrors.From = '';
    }
    if (newExp.to === null || newExp.to === "") {
      showError = true;
      newFormErrors.to = 'Please fill out this field!';
    } else {
      newFormErrors.to = '';
    }
    if (newExp.Description === null || newExp.Description === "") {
      console.log("8")

      showError = true;
      newFormErrors.Description = 'Please fill out this field!';
    } else {
      newFormErrors.Description = '';
    }
    setErrorForm(newFormErrors);

    console.log("Error", showError)
    if (!showError) {
      console.log("an")
      addExp(newExp);
      setshowModel(false);
    }
  };
  const deleteExp = async (U_Ex_ID) => {
    try {
      await axios.post(endPointUrl + "/user/deleteuserExp", { U_Ex_ID, Token });
      message.success("Delete Succesfull");
      getExp();
    } catch {
      message.error("something Went wrong");
    }
  };
  const updateExp = async () => {
    try {
      await axios.post(endPointUrl + "/user/updateUserExp", {
        ...newExp,
        U_Ex_ID: edititem[0].U_Ex_ID,
        Token,
      });
      getExp();
      message.success("Update Succesfull");
    } catch {
      message.error("something went Wrong ");
    }
  };
  return (
    <div className="education">
      <strong style={{ display: "flex", alignItems: "center" }}>
        Work & Experience <img src={add} alt="" onClick={handleExpModel} />
      </strong>
      <div className="all-education">
        <div className="education-line"></div>
        <div className="educaion-container">

          {exp.map((item) => (
            (item.Company && item.From) ? (
              <div className="eduction-time">
                <div>
                  <div className="year-range" key={item.U_Ex_ID}>
                    {item.From.substring(0, 4)} -{" "}
                    {item.Current ? "Present" : item.to.substring(0, 4)}{" "}
                  </div>
                  <div className="collage">
                    {item.Company + " " + item.Location}
                  </div>
                  <div>{item.Designation}</div>
                  <p>{item.Description}</p>
                  <div className="edit-icons">
                    <img id="edit-btn" src={editbutton} alt="" onClick={() => handleEditExpModel(item)} />
                    <img id="del-btn" src={deletebutton} alt="" onClick={() => deleteExp(item.U_Ex_ID)} />
                  </div>

                  <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
                </div>
              </div>
            ) : (null)
          ))}
        </div>

        <div id="myModal" className={showmodel ? "model-open" : "model"}>
          <div className="modal-content" style={{ padding: 0 }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
              <span style={{ color: "#46c294", fontWeight: "600" }}>Add Experience</span>
              <span className="close" onClick={CloseModel}>
                &times;
              </span>
            </div>
            <div style={{ padding: "0 40px", marginTop: "30px" }}>
              <form
                className="model-field exp-form"
                onSubmit={handlesubmitExpModel}
              >
                <div style={{ paddingInlineEnd: "20px" }}>
                  <div>
                    {" "}
                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                      <div>
                        <label htmlFor="Company">Company Name</label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.Company}</small>
                        <input style={{ marginInlineEnd: "12%" }}
                          type="text"
                          name="Compnay"
                          className={errorForm.Company != "" ? "errorborder" : ""}
                          placeholder="Enter Company Name"
                          onChange={(e) =>
                            setnewExp({ ...newExp, Company: e.target.value })
                          }
                        />
                      </div>
                      <div>
                        <label htmlFor="Designation">Designation</label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.Designation}</small>
                        <input
                          type="text"
                          name="Designation"
                          className={errorForm.Designation != "" ? "errorborder" : ""}
                          placeholder="Enter your Designation"
                          onChange={(e) =>
                            setnewExp({ ...newExp, Designation: e.target.value })
                          }
                        />
                      </div>

                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                      <div>
                        <label htmlFor="Location">Location</label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.Location}</small>
                        <input
                          type="text"
                          name="Location"
                          placeholder="Enter your Company Location"
                          className={errorForm.Location != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setnewExp({ ...newExp, Location: e.target.value })
                          }
                        />
                      </div>

                      <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "column", marginRight: "0%" }}>
                          <label htmlFor="From" style={{ display: "block", marginBottom: 0 }}>From</label>
                          <small style={{ color: "#ec1c24" }}>{errorForm.From}</small>
                          <input style={{ marginInlineEnd: "12%" }}
                            type="date"
                            name="From"
                            placeholder="Enter Joining  Year"
                            className={errorForm.From != "" ? "errorborder" : ""}
                            onChange={(e) =>
                              setnewExp({ ...newExp, From: e.target.value })
                            }
                            min="1900"
                            max="2099"
                          /> </div>
                        <div style={{ display: "flex", flexDirection: "column", marginRight: "6%" }}>
                          <label htmlFor="to" style={{ display: "block" }}>To</label>
                          <small style={{ color: "#ec1c24" }}>{errorForm.to}</small>
                          <input
                            type="date"
                            name="to"
                            placeholder="Enter left Year"
                            className={errorForm.to != "" ? "errorborder" : ""}
                            onChange={(e) =>
                              setnewExp({ ...newExp, to: e.target.value })
                            }
                            min="1900"
                            max="2099"
                          /> </div>
                      </div>



                    </div>
                  </div>
                  <div>
                    <label htmlFor="description">Description</label>
                    <small style={{ color: "#ec1c24" }}>{errorForm.Description}</small>
                    <textarea style={{ width: "97.5%" }}
                      name="Description"
                      id="description"
                      cols="50"
                      rows="5"
                      className={errorForm.Description != "" ? "errorborder" : ""}
                      placeholder="Description"
                      onChange={(e) =>
                        setnewExp({ ...newExp, Description: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                  <input type="submit" className="btn" value={'Add Experience'} style={{ width:"20%", backgroundColor: "#e3e3e3", color: "#000" }} />
                  <input type="submit" className="btn" value={"Submit"} />
                </div>

              </form></div>

          </div>
        </div>
        <div className={showEdit ? "model-open" : "model"}>
          <div className="modal-content" style={{ padding: 0 }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
              <span style={{ color: "#46c294", fontWeight: "600" }}>Update Experience</span>
              <span className="close" onClick={CloseModel}>
                &times;
              </span>
            </div>
            {edititem.map((item) =>
              <div style={{ padding: "0 40px", marginTop: "30px" }}>
                <form
                  className="model-field exp-form"
                  onSubmit={handleEditsubModel}
                >
                  <div>
                    <div>
                      {" "}
                      <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                        <div>
                          <label htmlFor="Company">Company Name</label>
                          <input
                            type="text"
                            name="Company"
                            placeholder={item.Company}
                            onChange={(e) =>
                              setnewExp({ ...newExp, Company: e.target.value })
                              // setnewExp({ ...newExp, Company: e.target.value })
                            }
                          />
                        </div>
                        <div>
                          <label htmlFor="Designation">Designation</label>
                          <input
                            type="text"
                            name="Designation"
                            // placeholder="Enter Designation of Your Designation"
                            placeholder={item?.Designation}
                            onChange={(e) =>
                              setnewExp({ ...newExp, Designation: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                        <div>
                          <label htmlFor="Location">Location</label>
                          <input
                            type="text"
                            name="Location"
                            // placeholder="Enter your Company Location"
                            placeholder={item?.Location}
                            onChange={(e) =>
                              setnewExp({ ...newExp, Location: e.target.value })
                            }
                          />
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                          <div>
                            <label htmlFor="From">From</label>
                            <input
                              type="date"
                              name="From"
                              // placeholder="Enter Joining  Year"
                              // value={item?.From.toLocaleDateString('en-GB')}
                              onChange={(e) =>
                                setnewExp({ ...newExp, From: e.target.value })
                              }
                              min="1900"
                              max="2099"
                            />
                          </div>
                          <div>
                            <label htmlFor="to">To</label>
                            <input
                              type="date"
                              name="to"
                              style={{ width: "90%" }}
                              // placeholder="Enter left Year"
                              // value={item?.to}
                              onChange={(e) =>
                                setnewExp({ ...newExp, to: e.target.value })
                              }
                              min="1900"
                              max="2099"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="description">Description</label>
                      <textarea
                        name="Description"
                        id="description"
                        cols="30"
                        rows="10"
                        style={{ width: "98%" }}
                        placeholder={item?.Description}
                        onChange={(e) =>
                          setnewExp({ ...newExp, Description: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    <input type="submit" className="btn" value={'Add Experience'} style={{ width:"20%", backgroundColor: "#e3e3e3", color: "#000" }} />
                    <input type="submit" className="btn" value={"Update"} />
                  </div>

                </form></div>

            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkExp;
