
import group from '../images/Logo.png'
import { useNavigate } from 'react-router-dom';
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin, FaInstagramSquare, FaYoutubeSquare } from 'react-icons/fa'
const CategoryFooter = () => {
  const handleFunction = (e, value) => {
    document.documentElement.scrollTop = 0;
    navigate(value)
  };
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="all-footer">
        <div className="footer-links">
          <div className="links-1">
            <li>DigiLanxer</li>
            <ul><li value={"/UI-UX-Design"} onClick={(e) => handleFunction(e, "/UI-UX-Design")}>Top Jobs</li>
              <li value={"/HireFreelancers"} onClick={(e) => handleFunction(e, "/HireFreelancers")}>Top Freelancers</li>
              <li>Categories</li>
              <li>Membership</li>
              <li value={"/reviews"} onClick={(e) => handleFunction(e, "/reviews")}>Review & Ratings</li>
              <li>Contact Us</li>
            </ul>
          </div>
          <div className="links-2">
            <li>Browse</li>
            <ul><li value={"/UI-UX-Design"} onClick={(e) => handleFunction(e, "/whyDigilancer")}>About DigiLanxer</li>
              <li value={"/UI-UX-Design"} onClick={(e) => handleFunction(e, "/UI-UX-Design")}>Post a Job</li>
              <li value={"/HireFreelancers"} onClick={(e) => handleFunction(e, "/HireFreelancers")}>Hire Freelancer</li>
              <li value={"/How-It-Works"} onClick={(e) => handleFunction(e, "/How-It-Works")}>How It Works</li>
              <li>Payment Method</li>
              <li>Site Map</li>
            </ul>
          </div>
          <div className="links-3">
            <li>Terms</li>
            <ul><li>Privacy Policy</li>
              <li>Terms and Conditions</li>
              <li>Escrow Payment System</li>
              <li>Copyright Policy</li>
            </ul>
          </div>
        </div>
        <div className="email">
          <div className="email-head">
            <div className="email-heading"><h5>Email Sign Up</h5>
              <p>Subscribe to our newsletter and get exclusive deals you wont find anywhere else straight to your inbox!</p></div>
            <div className="email-input">
              <input type="email" placeholder="Enter Email Address" />
              <button>SUBSCRIBE</button>
            </div>
            <div className='follow'>
              <h5>Follow Us</h5>
              <div className='follow-icon' >
                <FaFacebookSquare />
                <FaTwitterSquare />
                <FaLinkedin />
                <FaInstagramSquare />
                <FaYoutubeSquare />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ marginBottom: "0", opacity: "0.5", marginInline: "5%" }} />
      <div className="copywrite-social-media">
        <img src={group} alt="" />
        <div className="social-media">
          <div className="copy">© Copyrights DigiLanxer 2023 | All Rights Reserved.</div>

        </div>
      </div>
    </div>
  )
};

export default CategoryFooter;
