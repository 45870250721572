import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { MyContext } from '../Mycontext'
import ProfilesCard from './ProfilesCard';
import { BsArrowRightCircle } from 'react-icons/bs'
import { useNavigate } from 'react-router';

function FeaturedFreelancers() {
  const { endPointUrl, Token } = useContext(MyContext);
  const navigate = useNavigate();
  const [featFreelancer, setFeatFreelancer] = useState([]);
  const getFeatFreelancers = async () => {
    const { data } = await axios.post(endPointUrl + '/admin/featuredfreelancers', {
      Token
    });
    setFeatFreelancer(data.data)
  };
  const handleClick = () => {
    document.documentElement.scrollTop = 0;
    navigate('/HireFreelancers')
  }
  useEffect(() => {
    getFeatFreelancers();
  }, [])
  return (
    <div className='feat-freelancers-container'>
      <h3>Find Top Freelancers</h3>
      <div className="featCards">
        {featFreelancer?.map((item, index) =>
          <ProfilesCard key={index} item={item} />
        )}
      </div>
      <div className="jobs-card-open" style={{marginBlock:"30px"}}>
        <button className="btn-outline" onClick={()=>handleClick()}>More Freelancers<BsArrowRightCircle /></button>
      </div>
    </div>
  )
}

export default FeaturedFreelancers