import React from 'react'
import manage from '../images/manage.png'
function ManageFreelancer() {
  return (
    <div className="SignUpEmployee">
      <h1>Work management </h1>
      <p className="sign-up-content">
        You can also use WorkRooms for productive collaboration, employer communication, and teamwork.
      </p>
      <div className="sign-up-content-img">
        <div className="sign-up-points">
          <div className="sign-up-points-numbers">
            <div className="number">1</div>
            <div className="content"><h3>Create and build your team </h3>
              <p>Add new team members to a task and share the same with everyone 
              </p>
            </div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">2</div>
            <div className="content"><h3>
              
              Increase productivity</h3>
              <p>Create invoices, communicate with your team, and share files right here !</p></div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">3</div>
            <div className="content"><h3>Monitor your progress </h3>
              <p>Use Time Tracker to keep track of the time spent and generate invoices for hourly payments.</p>
            </div>
          </div>
        </div>
        <div className="sign-up-img"><img src={manage} alt="" /></div>
      </div>

    </div>
  )
}

export default ManageFreelancer