import React, { useState } from "react";
import signup from '../images/sign-up.png'
import arrow from '../images/right-arrows.png'
import { useNavigate } from "react-router-dom";
function SignUpEmployee() {
  const Navigate = useNavigate();
  return (
    <div className="SignUpEmployee">
      <h1>Sign Up</h1>
      <p className="sign-up-content">
        It’s as simple as creating a free email account ! Simply put in your details, post your job, and get started !  Do also check out the Hire Freelancers tool to get Best quotes from top Freelancers around.
      </p>
      <div className="sign-up-content-img">
        <div className="sign-up-points">
          <div className="sign-up-points-numbers">
            <div className="number">1</div>
            <div ><h3>Get on board with Digilanxer - it's free!</h3>
              <p>Get started with Digilanxer while simply signing up and getting your details verified in a single step.</p>
              {/* <p className="points-links" onClick={() => Navigate('../login')}>Get Started <img src={arrow} alt="" /></p> */}
            </div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">2</div>
            <div className="content"><h3> Post your job</h3>
              <p>Start receiving top quotes from best Freelancers within no time !</p>
              {/* <p className="points-links">Post a Job<img src={arrow} alt="" /></p> */}</div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">3</div>
            <div className="content"><h3>Shortlist the best Freelancers</h3>
              <p>Shortlist the most viable Freelancers while checking through their profile and other key aspects !</p>
              {/* <p className="points-links">StartHire<img src={arrow} alt="" /></p> */}</div>
          </div>





        </div>
        <div className="sign-up-img"><img src={signup} alt="" /></div>
      </div>

    </div>
  );
}

export default SignUpEmployee;
