import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../Mycontext";
import { message } from "antd";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import { scroller } from "react-scroll";
import Bidding from "./Bidding";
import { MdVerified } from 'react-icons/md';
import { AiFillStar } from 'react-icons/ai';
import { Link } from "react-router-dom";
import tempimg from '../../images/HireImg.png';
import { Popup } from "semantic-ui-react";
import Profile from "../../images/freelancer.png";
import img from '../../images/veryfied.png';


function DetailView() {
  const location = useLocation();
  const detailjob = location.state;
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [balance, setBalance] = useState(0);
  const [show, setshow] = useState(false);
  const [Applied, setapplied] = useState(false);
  const Navigate = useNavigate();
  const [analytics, setAnalytics] = useState([]);

  const scrollToElement = () => {
    scroller.scrollTo("myElement", {
      duration: 300,
      delay: 0,
    });
  };
  const checkeditTable = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/job/bidding", {
        Token,
        UserID: user.id,
        JobID: detailjob.JobID,
      });
      if (data.status === true && data.data) {
        setapplied(true);
        setshow(true);
      } else {
        setapplied(false);
        setshow(false);
      }
    } catch {
      setapplied(false);
      setshow(false);
    }
  };
  const BidBalance = async () => {

    const { data } = await axios.post(endPointUrl + "/getuserByBidBalance", {
      Token,
      id: user.id,
    })
    setBalance(data.data.Bid_Balance);
  };
  const checkUser = () => {
    if (user) {
      if (user.Type === "Employer") {
        message.warning("Your are not a Freelancer ")
      } else {
        scrollToElement();
        balance ? setshow(true) : Navigate("/Packages")
      }
    } else {
      Navigate("/login")
      message.warning("Login As Freelancer First")
    }
  }

  const Analytics = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/analytics", {
        id: detailjob?.employer?.id,
        Token
      });
      setAnalytics(data.data);
    } catch {
      message.error("Error while fetching analytics")
    }
  };

  useEffect(() => {
    checkeditTable();
    BidBalance();
    Analytics();
  }, [])
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "90%" }}>
        <div className="home-howitwork" style={{ margin: 0 }}>
          <Link to="/">Home </Link>/{" "}
          <Link to="/UI & UX Design">UI & UX Design</Link>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#f1f7f9", borderRadius: "15px" }}>
          <div style={{ marginLeft: "50px" }}><h3 style={{ fontSize: "32px", margin: "0" }}>Job Details</h3><p style={{ margin: "0" }}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dignissimos laborum?</p></div>
          <img style={{ width: "300px", height: "200px" }} src={tempimg} alt="img" />
        </div>
        <div className="jobProfileContainer" style={{ margin: "0", width: "100%" }}>

          <div className="jobContainer" style={{ padding: "0" }}>
            <div style={{ padding: "20px 40px" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h2 style={{ color: "#07b274" }}>
                  {detailjob?.Title}
                </h2>
                {/* <div className="employerDetail">
                <span style={{ color: "#579347", fontSize: "18px", cursor: "pointer" }} onClick={() => Navigate(`/EmployerProfile/${detailjob.employer.id}`)} >{detailjob?.employer?.Name}<span style={{ display: "flex", alignItems: "center" }}>(<AiTwotoneStar style={{ color: "#ff8a00" }} />{detailjob?.employer?.avg_Rating})</span></span>
                <span className="verified">{(detailjob?.employer?.Verified_Profile == "Verified") ? <MdVerified style={{ color: "#0dd50d" }} /> : <MdVerified style={{ color: "rgb(133 133 133)" }} />}</span>
              </div> */}
              </div>

              {/* <h4 style={{ margin: "0" }}>Detail Description</h4> */}
              <div dangerouslySetInnerHTML={{ __html: detailjob?.Description }} className="detail_conatiner"></div>
              <div style={{ marginTop: "20px", }}>
                {detailjob?.SkillsRequired?.split(",").map((skill) => {
                  return skill.length > 0 ? (
                    <span style={{
                      fontWeight: "500",
                      fontSize: "13px",
                      background: "transparent",
                      border: "1px solid #d4d4d4",
                      color: "#5a5a5a",
                      padding: "5px 18px",
                      margin: "5px",
                      borderRadius: "6px",
                      display: "inline-block",

                    }}> {skill}</span>
                  ) : null;
                })}
              </div>
            </div>
            <>
              <hr />
              <Bidding
                Balance={balance}
                detailjob={detailjob}
                show={show}
                Applied={Applied}
                checkUser={checkUser}
              />
            </>
          </div>



          <div className="sideComponent" style={{ marginTop: "30px", padding: 0, borderRadius: "22px", width: "40%", border: "none" }}>
            <div className="compo2">
              <div className="profileConatiner" style={{ position: "relative" }}>
                <div className="profile-details">
                  {/* <img src={img} alt="" style={{ filter: detailjob?.employer?.Verified_Profile == "Verified" ? "none" : "grayscale(1)", width: "23px", height: "auto", marginLeft: "auto", marginRight: "15px", marginTop: "-20px" }} /> */}
                  <Popup
                    trigger={detailjob?.employer?.Verified_Profile == "Verified" ? <MdVerified style={{ color: "white", width: "23px", height: "auto", marginLeft: "auto", marginRight: "15px", marginTop: "-20px" }} /> :
                      <MdVerified style={{ color: "rgb(133 133 133)", width: "23px", height: "auto", marginLeft: "auto", marginRight: "15px", marginTop: "-20px" }} />}
                    content={detailjob?.employer?.Verified_Profile == "Verified" ? "Profile Verified" :
                      detailjob?.employer?.Verified_Profile == "Pending" ? "Profile verification Pending" :
                        "Profile Unverified"}
                  />
                  <img
                    src={
                      detailjob?.employer?.Photo === "" || detailjob?.employer?.Photo == null
                        ? Profile
                        : `${endPointUrl}/images/${detailjob?.employer?.Photo}`
                    }
                    alt=""
                  />
                  <span className="profilename" onClick={() => Navigate(`/EmployerProfile/${detailjob?.employer?.id}`)} style={{ cursor: "pointer" }}>{detailjob?.employer?.Name}
                  </span>

                  <span className="profileDesignation">
                    {detailjob?.employer?.Title}
                  </span>
                  <span className="rating" style={{ paddingLeft: "6px" }}>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 16 15" fill="#FF8A00" className="rating-star">
                    <path d="M8.00027 12.1734L3.29827 14.8054L4.34827 9.52004L0.391602 5.86137L5.74293 5.22671L8.00027 0.333374L10.2576 5.22671L15.6089 5.86137L11.6523 9.52004L12.7023 14.8054L8.00027 12.1734Z" fill="#FF8A00" />
                  </svg> */}
                    <AiFillStar className="featuredfreelancer-rating-star" />
                    {detailjob?.employer?.avg_Rating ? detailjob?.employer?.avg_Rating : 0} <p>({detailjob?.employer?.no_of_reviews ? detailjob?.employer?.no_of_reviews : 0} Reviews)</p></span>
                </div>
                {/* <div className="profile-complete" style={{ borderRadius: "0" }}>
                  <span style={labelStyles}><span style={{ fontWeight: "600", color: "black", marginRight: "3px" }}>{counter}% </span> Profile complete</span>
                  <div className="progress">
                    <ProgressBar bgColor={bgColor} completed={counter} />
                  </div>
                  <div className="profile-btn" onClick={() => {
                    detailjob?.employer?.Type == "Employer"
                      ? navigate("/employerprofile")
                      : navigate("/logindetailjob?.employer?Profile");
                  }}>
                    <button style={{ borderRadius: "3px" }}>Complete Profile</button>
                  </div>
                </div> */}
                <hr />

                <div className="rate-card" style={{ borderRadius: "0 0 20px 20px" }}>
                  <h2 style={{ marginBlock: "auto" }}>About Employeer</h2>
                  <p style={{ fontSize: "12px", color: "#606060;" }}>{detailjob?.employer?.About}</p>
                  <div>
                    {/* <div className="item item--1">
                    <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"></path><path fill="rgba(149,149,255,1)" d="M17 15.245v6.872a.5.5 0 0 1-.757.429L12 20l-4.243 2.546a.5.5 0 0 1-.757-.43v-6.87a8 8 0 1 1 10 0zm-8 1.173v3.05l3-1.8 3 1.8v-3.05A7.978 7.978 0 0 1 12 17a7.978 7.978 0 0 1-3-.582zM12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path></svg>
                    <span className="quantity" style={{ color: "rgba(149,149,255,1)" }}> {analytics.totalprojects} </span>
                    <span className="text text--1"> Total Projects </span>
                  </div>
                  <div className="item item--2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0L24 0 24 24 0 24z"></path><path d="M16 16c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm10 6c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM6 14c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm8.5-12C17.538 2 20 4.462 20 7.5S17.538 13 14.5 13 9 10.538 9 7.5 11.462 2 14.5 2zm0 2C12.567 4 11 5.567 11 7.5s1.567 3.5 3.5 3.5S18 9.433 18 7.5 16.433 4 14.5 4z" fill="rgba(252,161,71,1)"></path></svg>
                    <span className="quantity" style={{ color: "rgba(252,161,71,1)" }}> {analytics.completedProject} </span>
                    <span className="text text--2"> Completed Projects </span>
                  </div>
                  <div className="item item--3">
                    <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"></path><path fill="rgba(66,193,110,1)" d="M20.083 15.2l1.202.721a.5.5 0 0 1 0 .858l-8.77 5.262a1 1 0 0 1-1.03 0l-8.77-5.262a.5.5 0 0 1 0-.858l1.202-.721L12 20.05l8.083-4.85zm0-4.7l1.202.721a.5.5 0 0 1 0 .858L12 17.65l-9.285-5.571a.5.5 0 0 1 0-.858l1.202-.721L12 15.35l8.083-4.85zm-7.569-9.191l8.771 5.262a.5.5 0 0 1 0 .858L12 13 2.715 7.429a.5.5 0 0 1 0-.858l8.77-5.262a1 1 0 0 1 1.03 0zM12 3.332L5.887 7 12 10.668 18.113 7 12 3.332z"></path></svg>
                    <span className="quantity" style={{ color: "rgba(66,193,110,1)" }}> {analytics.ongoingprojects} </span>
                    <span className="text text--3"> Ongoing Projects </span>
                  </div>
                  <div className="item item--4">
                    <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"></path><path fill="#69aaf9" d="M12 20h8v2h-8C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10a9.956 9.956 0 0 1-2 6h-2.708A8 8 0 1 0 12 20zm0-10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-4 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm8 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm-4 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"></path></svg>
                    <span className="quantity" style={{ color: "#69aaf9" }}> {analytics.expiredprojects} </span>
                    <span className="text text--4"> Expired Projects </span>
                  </div> */}
                  </div>
                  <hr />
                  <div className="user-details">
                    <p>Job Post</p>
                    <span>{analytics.totalprojects}</span>
                  </div>
                  <div className="user-details">
                    <p>Completed Projects</p>
                    <span>{analytics.completedProject}</span>
                  </div>
                  <div className="user-details">
                    <p>Ongoing Projects</p>
                    <span>{analytics.ongoingprojects}</span>
                  </div>
                  <div className="user-details">
                    <p>Close Projects</p>
                    <span>{analytics.expiredprojects}</span>
                  </div>
                  <div className="user-details">
                    <p>Average project Payment</p>
                    <span>${(Math.floor(analytics.revenuegenerated / analytics.totalprojects)) ? (Math.floor(analytics.revenuegenerated / analytics.totalprojects)) : 0}</span>
                  </div>
                  <div className="user-details">
                    <p>Total Spent</p>
                    <span>${analytics.revenuegenerated}</span>
                  </div>
                  <div className="user-details">
                    <p>Language</p>
                    <span>{detailjob?.employer?.Language}</span>
                  </div>
                  {/* <div className="user-details" >
                    <p>Location</p>
                    <span>{detailjob?.employer??.City} {detailjob?.employer?.City ? "," : null} {detailjob?.employer?.Country}</span>
                  </div> */}
                  {/* <div className="user-details">
                  <p>Funds in Escrow </p>
                  <span>$${analytics.fundsInEscrow}</span>
                </div> */}
                  <div className="user-details">
                    <p>Gender</p>
                    <span>{detailjob?.employer?.Gender}</span>
                  </div>
                </div>
              </div>
            </div>



            {/* <div className="sideComponent" style={{ marginTop: "30px" }}>
          {user?.Type !== "Employer" ?
            <div className="sideComponent_bid">
              <span>Bid Balance</span>
              <span id="highlight">{balance ? balance : 0}</span>

            </div> : null}
          <div className="see-other-post">
            {!Applied && user?.Type == "Freelancer" && user?.Status == 1 ? (
              <button
                className="btn"
                onClick={() => { checkUser(); }}
              >
                Apply
              </button>
            ) : null}
            <button
              className="btn"
              onClick={() => {
                setdetailview(false);
              }}
            >
              Close
            </button>
          </div>
          <hr style={{ width: "100%" }} />
          <div className="uploadDocs">
            <h4 style={{ margin: "0 0 5px 0" }}>Documents</h4>
            <div className="uploadtags">
              {(detailjob.Documents ? detailjob.Documents.split(",") : []).map(
                (tag, index) => (
                  <span key={index}>
                    <a
                      href={`${endPointUrl}/images/${tag}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tag}
                    </a>
                  </span>
                )
              )}
            </div>
          </div>
          <hr style={{ width: "100%", background: "" }} />
          <div className="row-view">
            <div>
              <h4 style={{ margin: "5px 0 5px 0" }}>Project Type</h4>
              <small>{detailjob?.ProjectType || ""}</small>
            </div>
          </div>
          <hr style={{ width: "100%", background: "" }} />
          <div className="row-view">
            <div>
              <h4 style={{ margin: "5px 0 5px 0" }}>Budget Details</h4>
              <small>{detailjob?.Budget_From}-</small>
              <small>{detailjob?.Budget_To}/</small>
              <small>{detailjob?.Budget_Type}</small>
            </div>
            <div>
              {" "}
              <h4 style={{ margin: "5px 0 5px 0" }}>Job Type</h4>
              <small>{detailjob?.Type}</small>
            </div>
          </div>
          <hr style={{ width: "100%", background: "" }} />
          <div>
            {" "}
            <h4 style={{ margin: "20px 0 5px 0" }}>Skills</h4>
            <div className="skillview">
              {(detailjob?.SkillsRequired).split(",")?.map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>Category</h4>
            <div className="skillview">
              {(detailjob?.Category).split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>Technology</h4>
            <div className="skillview">
              {(detailjob?.Technology).split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>Location</h4>
            <div className="skillview">
              {(detailjob?.Location).split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>Language</h4>
            <div className="skillview">
              {detailjob?.Language?.split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>KeyWords</h4>
            <div className="skillview">
              {detailjob?.Keywords?.split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
    </div>

  );
}

export default DetailView;
