import GroupContainer from "../components/GroupContainer";
import comma1 from '../images/Icons/quote1.png'
import comma2 from '../images/Icons/quote2.png'
import React, { useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { BsArrowRightCircle } from 'react-icons/bs'
import { useNavigate } from "react-router";
import { MyContext } from "../Mycontext";
import axios from "axios";


export default function ClientReviewContainer() {
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [Reviews, setReviews] = useState([])

  const getReviews = async () => {
    const { data } = await axios.post(endPointUrl + '/admin/globalReviewfind', { Token });
    setReviews(data.data);

  }
  useEffect(() => {
    getReviews();
  }, [])

  const navigate = useNavigate();
  const handleClick = () => {
    document.documentElement.scrollTop = 0;
    navigate('/reviews')
  }
  return (
    <div className="Review_Slider">
      <div className="Review_Slider_Box">
        <div style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <img src={comma1} alt="" />
          <div style={{ lineHeight: "40px", marginInline: "auto" }}>
            <p style={{ margin: "0", textTransform: "uppercase", fontSize: "20px", fontWeight: 600 }}>Testimonials</p>
            <h2 style={{ margin: "0", textTransform: "uppercase", fontSize: "32px" }}>CUSTOMER'S AWESOME</h2>
            <h3 style={{ margin: "0", textTransform: "uppercase", color: "#0dcd86" }}>FeedBack</h3>
          </div>
          <img src={comma2} alt="" />
        </div>
        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 3000, // Initial delay
          }}
        >
          {[...Array(Reviews?.length)].map((_, index) => (
            <SwiperSlide key={index}>
              <GroupContainer index={index} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="jobs-card-open" style={{ marginTop: "20px" }}>
          <button className="btn-outline" onClick={handleClick}>Read all Reviews<BsArrowRightCircle /></button>
        </div>
      </div>

    </div>

  )
}

