import React, { useState, useEffect } from "react";
import {
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill
} from "react-icons/bs";
import img1 from '../../images/sign-up.png'
import img2 from '../../images/Hire.png'
import img3 from '../../images/manage.png'

const data = [
  {
    src: img1,
    alt: "Image 1 for carousel"
  },
  {
    src: img2,
    alt: "Image 2 for carousel"
  },
  {
    src: img3,
    alt: "Image 3 for carousel"
  }
]

export const Carousel = () => {
  const [slide, setSlide] = useState(0);
  const [autoplay, setAutoplay] = useState(true);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  const startAutoplay = () => {
    setAutoplay(true);
  };

  const stopAutoplay = () => {
    setAutoplay(false);
  };

  useEffect(() => {
    let intervalId;

    if (autoplay) {
      intervalId = setInterval(nextSlide, 3000); // Change slide every 3 seconds
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [slide, autoplay]);

  return (
    <div className="carousel" onMouseEnter={stopAutoplay} onMouseLeave={startAutoplay}>
      <BsArrowLeftCircleFill onClick={prevSlide} className="arrow arrow-left" />
      {data.map((item, idx) => {
        return (
          <img
            src={item.src}
            alt={item.alt}
            key={idx}
            className={slide === idx ? "slide" : "slide slide-hidden"}
          />
        );
      })}
      <BsArrowRightCircleFill
        onClick={nextSlide}
        className="arrow arrow-right"
      />
      <span className="indicators">
        {data.map((_, idx) => {
          return (
            <button
              key={idx}
              className={
                slide === idx ? "indicator" : "indicator indicator-inactive"
              }
              onClick={() => setSlide(idx)}
            ></button>
          );
        })}
      </span>
    </div>
  );
};


// import React, { useState } from "react";
// import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
// import img1 from '../../images/sign-up.png'
// import img2 from '../../images/Hire.png'
// import img3 from '../../images/manage.png'

// const   data = [
//     {
//       src: img1,
//       alt: "Image 1 for carousel"
//     },
//     {
//       src: img2,
//       alt: "Image 2 for carousel"
//     },
//     {
//       src: img3,
//       alt: "Image 3 for carousel"
//     }
//   ]

// export const Carousel = () => {
//   const [slide, setSlide] = useState(0);

//   const nextSlide = () => {
//     setSlide(slide === data.length - 1 ? 0 : slide + 1);
//   };

//   const prevSlide = () => {
//     setSlide(slide === 0 ? data.length - 1 : slide - 1);
//   };

//   return (
//     <div className="carousel">
//       <BsArrowLeftCircleFill onClick={prevSlide} className="arrow arrow-left" />
//       {data.map((item, idx) => {
//         return (
//           <img
//             src={item.src}
//             alt={item.alt}
//             key={idx}
//             className={slide === idx ? "slide" : "slide slide-hidden"}
//           />
//         );
//       })}
//       <BsArrowRightCircleFill
//         onClick={nextSlide}
//         className="arrow arrow-right"
//       />
//       <span className="indicators">
//         {data.map((_, idx) => {
//           return (
//             <button
//               key={idx}
//               className={
//                 slide === idx ? "indicator" : "indicator indicator-inactive"
//               }
//               onClick={() => setSlide(idx)}
//             ></button>
//           );
//         })}
//       </span>
//     </div>
//   );
// };