import React, { useReducer, useEffect } from "react";
const session = JSON.parse(sessionStorage.getItem("data"));
let reducer = (user, newuser) => {
  if (newuser === null) {
    sessionStorage.clear();
    return initialState;
  }
  return { ...user, ...newuser };
};
const initialState = null;
const MyContext = React.createContext();

function MyProvider(props) {
  const [user, setuser] = useReducer(reducer, session || initialState);
  const Token = "W4lyaidIGicUCR1gCtaC2JEbn4D2AvzyRwRAHCCcUjvWOx6Epe";

  // const endPointUrl ="http://192.168.29.25:5000";
  // const endPointUrl = "http://192.168.29.91:5000";
  // const endPointUrl = "http://192.168.29.57:5000"; //Harsit
  const endPointUrl = "https://digilanxer.com:5000";
  useEffect(() => {
    sessionStorage.setItem("data", JSON.stringify(user));
  }, [user]);
  return (
    <MyContext.Provider value={{ user, setuser, Token, endPointUrl }}>
      {props.children}
    </MyContext.Provider>
  );
}
export { MyContext, MyProvider };
