import React, { useEffect, useState, useContext } from "react";
import { message } from "antd";
import logo from "../../images/Logo.png";
import Signup from "../../images/sign-up.png";
import face from "../../images/face.png";
import linke from "../../images/linke.png";
import google from "../../images/google.png";
import { Link, useNavigate } from "react-router-dom";
import Forgot from "./Forgot";
import OtpInput from "react-otp-input";
import axios from "axios";
import { MyContext } from "../../Mycontext";
import { useGoogleLogin } from "@react-oauth/google";
import tickimg from '../../images/checkmark.png';
import { Carousel } from "./Carousel";

function Login() {
  const { setuser, endPointUrl, Token } = useContext(MyContext);
  const Navigate = useNavigate();
  // const [user,updateUser]=useState({});
  const [showmodel, setshowModel] = useState(false);
  const [showpage, setshowPage] = useState({
    page1: true,
    page2: false,
    page3: false,
    page4:false,
  });
  const [Email, setemail] = useState("");
  const [Password, setpassword] = useState("");
  const [userotp, updateUserotp] = useState("");
  const [otp, setotp] = useState("");
  const [showpass, setshowpass] = useState(false);
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      storeResultofGoogleLogin(tokenResponse.access_token),
  });
  const storeResultofGoogleLogin = async (access_token) => {
    const { data } = await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`
    );
    try {
      const result = await axios.post(endPointUrl + "/user/sociallogin", {
        Email: data.email,
      });
      setuser(result.data.data);
      sessionStorage.setItem("data", JSON.stringify({ ...user, Password: "" }));
      Navigate("../");
      message.success("Successfull login");
    } catch (err) {
      console.log(err);
      message.error("You are not the verified user");
    }
  };
  const handlesubmit = async (e, Email, Password) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(endPointUrl + "/login", {
        Email,
        Password,
        Token,
      });
      // sessionStorage.setItem(
      //   "data",
      //   JSON.stringify({ ...data.data, Password: "" })
      // );
      setuser(data.data);
      if (data.data.Type === "Employer") {
        Navigate("../EmployerJobProfile");
      } else {
        Navigate("../FreelancerJobProfile");
      }
      message.success("Successfull login");
    } catch (err) {
      console.log('login api is not working');
      message.error("email or password is Incorrect");
      console.log(err.response.data);
    }
  };
  const proceedthree =  async () => {
    if (Password.length < 5) {
      message.warning("Enter stronger password ");
    } else {
      try {
        const { data } = await axios.post(endPointUrl + "/user/forgotPassword", {
          Email,
          Password,
          Token,
          
        });
        message.success("Reset Successfull");
        setshowPage({ page1: false, page2: false, page3: false, page4:true });
      } catch (err) {
        message.error("error");
      }
      
    }
  };
  
  const sendotp=async()=>{
    if(Email.length < 9){
      message.error('enter correct email')
    }
    else{
    try {
      const { data } = await axios.post(endPointUrl + "/user/forgetpasswordotp", {
        Email,Token
      });
      setotp(data.otp);
      message.success('otp send successfull');
      setshowPage({ page1: false, page2: true, page3: false, page4:false });
    }
      catch{
       message.error('otp is not sendable retry!!')
      }
    }
    };
    const resendotp=async()=>{
      try {
        const { data } = await axios.post(endPointUrl + "/user/forgetpasswordotp", {
          Email,Token
        });
        setotp(data.otp);
        message.success('otp send successfull')
      }
        catch{
         message.error('otp is not sendable retry!!')
        }
    };
    const verifytwo= async () => {
      if (parseInt(otp) === parseInt(userotp)) {
        try {
          message.success("Correct OTP");
          setshowPage({ page1: false, page2: false, page3: true , page4:false });
          
        } catch (err) {
          message.error("Something went wrong");
        }
      } else {
        message.error("incorrect otp");
      }
    };
  
  const OpenModel = () => {
    setshowModel(true);
    document.body.classList.add("modal-open");
    setshowPage({ page1: true, page2: false, page3: false, page4:false });
  };
  const CloseModel = () => {
    setshowModel(false);
    document.body.classList.remove("modal-open");
  };
  const handleclik =()=>{
    Navigate("../login");
  }
  return (
    <>
      <div className="login">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="digilancer logo" />
          </Link>
        </div>
        <div className="login-component">
          <div className="login-img" style={{paddingInline:"73px"}}>
            <div style={{marginBottom:"auto"}} className="grow-with-digilancer">
              Grow with <span style={{ color: "#07b274" }}>digilanxer</span>
            </div>
            <div className="image">
              {/* <img style={{width:"120%"}} src={Signup} alt="" />
              <strong style={{marginBlock:"14px"}}>99% Customer Satisfaction</strong>
              <span>Based on paid invoices</span> */}
              <Carousel/>
              <strong style={{marginBlock:"20px 13px"}}>99% Customer Satisfaction</strong>
              <span>Based on paid invoices</span> 
            </div>
          </div>
          <div className="login-details">
            <h2>Log In</h2>
            <form onSubmit={(e) => handlesubmit(e, Email, Password)}>
              <div className="facebooklogin">
                <img src={face} alt="" />
                <img src={linke} alt="" />
                <img src={google} alt="" onClick={() => login()} />
              </div>
              <div className="or-connect">OR Connect With</div>
              <div className="loginemail">
                <input
                  type="text"
                  name="Email"
                  placeholder="email or Username"
                  onChange={(e) => setemail(e.target.value.trim())}
                />
              </div>
              <div className="loginemail password">
                <input
                  type={`${showpass ? "text" : "password"}`}
                  name="Password"
                  placeholder="password"
                  onChange={(e) => setpassword(e.target.value.trim())}
                />
                <span onClick={() => setshowpass(!showpass)}>
                  {showpass ? "hide" : "show"}
                </span>
              </div>
              <div className="login-forgot">
                <span className="checkbox">
                  <input type="checkbox" />
                  <span>Keep me logged in</span>
                </span>
                <span onClick={OpenModel} style={{ color: "#07b274", cursor: "pointer" }}>
                  Forgot password?
                </span>
              </div>
              <button type="submit" className="btn loginBtn">
                Login
              </button>
              <p style={{ textAlign: "center" }}>
                Don't have an account?{" "}
                <span
                  style={{ color: "#07b274", cursor: "pointer" }}
                  onClick={() => Navigate("../Signup")}
                >
                  Sign Up
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
      <div
        id="otpModal"
        className={showmodel ? "model-open" : "model"}
        style={{ overflow: "scroll" }}
      >
        <div className="forgot-modal-content" style={{ color: "black" }}>
          <span className="close" onClick={CloseModel}>
            &times;
          </span>
          {showpage.page1 && (
           <div className="forgot-modal">
            <h1>Forgot Password</h1>
            <p>Enter your email address below</p>
            <input
            type="text" 
            name="email" 
            placeholder="Your Email" 
            onChange={(e) => setemail(e.target.value.trim())}/>

            <button type="submit" className="btn otp-btn" onClick={sendotp}>Get OTP</button>
              
           </div>
           )}
           {showpage.page2 && (
            <div className="forgot-modal">
              <div className="selectAccountType">VERIFY OTP</div>
              <div className="code-text">
                Please enter the 4-digit code sent to your email
              </div>
              <div className="input-code">
                <OtpInput
                  value={userotp}
                  onChange={updateUserotp}
                  numInputs={4}
                  renderInput={(props) => <input {...props} />}
                />
              </div>

              <div className="resend-code">
                <span onClick={resendotp}>Resend Code (5 secs)</span>
                <span>Change Email</span>
              </div>
              <div className="button verify">
                <button
                  className="btn-outline"
                  onClick={() =>
                    setshowPage({ page1: true, page2: false, page3: false, page4:false  })
                  }
                >
                  Back
                </button>
                <button className="btn" onClick={verifytwo}>
                  Verify
                </button>
              </div>
            </div>
           )}
           {showpage.page3 && (
            <div className="forgot-modal">
              <h1>Reset Password</h1>
              
                <span>Enter new password</span>
                <div className="loginemail password">
                <input
                  type={`${showpass ? "text" : "password"}`}
                  name="Password"
                  placeholder="password"
                  value={Password}
                  onChange={(e) => setpassword(e.target.value.trim())}
                />
                <span onClick={() => setshowpass(!showpass)}>
                  {showpass ? "hide" : "show"}
                </span>
                </div>
                <button className="btn" onClick={proceedthree}>Proceed</button>
              
            </div>
           )}
           {showpage.page4 && (
            <div className="forgot-modal">
              <img src={tickimg} alt="" style={{maxWidth:"40%"}}/>
             <h1>Successfull</h1>
             <p>User Id or Password has been reset Successfully</p>
             <button className="btn" onClick={CloseModel}>Click here for login</button>
            </div>
           )}

        </div>
      </div>
    </>
  );
}

export default Login;
