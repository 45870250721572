import React, { useEffect, useState, useContext } from "react";
import { message } from "antd";
import { MyContext } from "../Mycontext";
import axios from "axios";
import { AiFillStar } from 'react-icons/ai';

const GroupContainer = ({ index }) => {
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [Reviews, setReviews] = useState([])

  const getReviews = async () => {
    const { data } = await axios.post(endPointUrl + '/admin/globalReviewfind', { Token });
    setReviews(data.data);
   
  }
  useEffect(() => {
    getReviews();
  }, [])

  return (<>
    {Reviews.length > 0 && (
      <div>
        <p className="reviews">{Reviews[index % Reviews.length]?.GlobalReview_Review}</p>
        <div>
          <h2 className="aname" style={{ margin: 0 }}>{Reviews[index % Reviews.length]?.GlobalReview_Name}</h2>
          <h2 className="star star-2">
            <i data-star={Reviews[index % Reviews.length]?.Stars}></i>
          </h2>
        </div>
      </div>
    )}
  </>
    
    

    // <div className="rectangle-parent-7">
    //   <img className='comma' src={comma} alt="Png File - Double Quotes Png@seekpng.com"/>
    //   <p className="reviews">
    //     Lorem Ipsum is simply dummy text of the printing and typesetting
    //     industry. Lorem Ipsum has been the industry's standard dummy text ever
    //     since the 1500s, when an unknown printer took a galley of type and
    //     scrambled it to make a type specimen book.
    //   </p>
    //     

    // </div>
  );
};

export default GroupContainer;
