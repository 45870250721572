import React from 'react'
import hero from "../images/img.png";
import Selectant from "./Jobs/Select";
import {MyContext} from "../Mycontext";
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { message } from 'antd';
import { AiFillRightCircle } from 'react-icons/ai';
import { BiSearch } from 'react-icons/bi';

function HireExpertConatiner2() {
  const Navigate = useNavigate();
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [error, seterror] = useState(false);
  const [skillSelected, setskillSelected] = useState([]);
  const [popularSelected, setpopularSelected] = useState([]);
  const [skillmaster, setskillmaster] = useState([]);

  const handleskillsmaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setskillmaster(a);
  };
  const handleclick= () =>{
   Navigate('/HireFreelancers',{state:skillSelected})
  };
  const onclick = (e) => {
    console.log(e.target.value);
   Navigate('/HireFreelancers',{state:e.target.value})
  };
  useEffect(() => {
  if (popularSelected.length > 0){
    onclick();
  }
  },[popularSelected])
  useEffect(() => {
    handleskillsmaster();
  }, []);
  return (
    <div className='HireExpertConatiner2'>
        <div className="hireExpertContainer-content">
         <div className="hireExpertContainer-content-heading">
            <h1>Find & Hire</h1>
            <h1>Expert Digital Marketer</h1>
         </div>
        <p>Sourcing and vetting highly skilled professionals who can deliver
          top-notch results cost-effective, providing you with quality work at a competitive price.</p>
<div className="skills-jobs">  
        <div style={{width:"90%",marginBottom:"8px",display:"flex"}}> 
            <Selectant
                  label={"What Skills are you looking for ?"}
                  options={skillmaster}
                  setselected={setskillSelected}
                  selected={skillSelected}
                />
                
                {!skillSelected && error ? (
                  <p
                    style={{
                      marginTop: "-5px",
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    {" "}
                    skills is Required ...
                  </p>
                ) : null}
            <button className='search'
            style={{background:"#f0f8ff00",color:"#07b273",paddingLeft:"0",marginTop:"5px",marginLeft:"2px"}}
            onClick={handleclick}
            ><BiSearch style={{width:"auto",height:"35px",background:"currentColor",fill:"white",padding:"4px 2px 2px 4px",borderRadius:"5px"}}/> </button>
            </div> 
        {/* <p style={{color:"rgba(96, 101, 99, 1)", margin:"3px 20px"}}>or</p>
        <div className="button-post btn"
        onClick={() => {
          if(!user?.Type ){
            Navigate("./Signup")
          }else if (user?.Type === "Employer"){
            Navigate("./JobCreation")
          }else{message.warning("Login as Employer to post Jobs")}
        }}
        >Post a Job - It's Free</div> */}
      </div>
      <div className="popular-search">
      <h6 >Popular:</h6>
      {/* <div className="btn-popular">
        {skillmaster.map((item,index) => 
        index < 4 ? 
            <button
              style={{ color: "white", backgroundColor: "#07b270" }}
        key={item}
        onClick={() => {setpopularSelected(item)}}
        >{item}</button>
        :null 
        )}
      </div> */}
      <div className="btn-popular">
        
            <button
              style={{ color: "white", backgroundColor: "#07b270" }}
              value="SEO Expert"
        onClick={(e) => {onclick(e)}}
            >SEO Expert</button>
            <button
              style={{ color: "white", backgroundColor: "#07b270" }}
              value="Ads Specialistt"
        onClick={(e) => {onclick(e)}}
            >Ads Specialistt</button>
            <button
              style={{ color: "white", backgroundColor: "#07b270" }}
              value="Digital Marketing Manager"
        onClick={(e) => {onclick(e)}}
            >Digital Marketing Manager</button>
            <button
              style={{ color: "white", backgroundColor: "#07b270" }}
              value="Influencer Outreach"
        onClick={(e) => {onclick(e)}}
            >Influencer Outreach</button>
      </div>
    </div>
        </div>
        <div className="hireExpertContainerImage">
        <img src={hero} alt="" />
        </div>
    </div>
  )
}

export default HireExpertConatiner2