import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CategoryFooter from "./components/CategoryFooter";
import Header from "./components/Header";
import WhyDigilancer from "./components/WhyDigilancer.js";
import HowItWorks from "./components/HowItWorks.js";
import HireFreelancers from "./components/HireFreelancers.js";
import IndexV1 from "./pages/IndexV1";
import "./global.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import UserProfile from "./components/UserProfile";
import EmployerProfile from "./components/EmployerProfile";
import Projectlisted from "./components/Projectlisted";
import Login from "./components/login_signup/Login";
import Signup from "./components/login_signup/Signup";
import { MyProvider } from "./Mycontext";
import Logeduser from "./components/Logeduser";
import Employerprofile from "./components/user-profile/employerprofile";
import FreelancerJobprofile from "./components/user-profile/FreelancerJobprofile";
import EmployerJobProfile from "./components/user-profile/EmployerJobProfile";
import Sidebar from "./components/user-profile/Sidebar";
import Jobform from "./components/Jobs/Jobform";
import Package from "./components/Jobs/Package";
import Thanku from "./components/Jobs/Thanku";
import MilestonePayment from "./components/Jobs/milestonePayment";
import SkillTest from "./components/user-profile/SkillTest";
import SKillQA from "./components/user-profile/SKillQA";
import InvoiceGenerator from "./components/user-profile/invoince/InvoiceGenerator";
import DetailView from "./components/Jobs/DetailView";
import ReviewContainer from "./components/reviewContainer";
function App() {
  return (
    <MyProvider>
      {/* <IndexV1/> */}
      <GoogleOAuthProvider clientId="987229229823-4k3n9u6sbsk57tav6ghuhlltg0tll5eo.apps.googleusercontent.com">
        <Header />
        <Routes>

          <Route path="/" element={<IndexV1 />} />
          <Route path="/whyDigilancer" element={<WhyDigilancer />} />
          <Route path="/reviews" element={<ReviewContainer />} />
          <Route path="/How-It-Works" element={<HowItWorks />} />
          <Route path="/HireFreelancers" element={<HireFreelancers />} />
          <Route path="/userProfile/:nameid" element={<UserProfile />} />
          <Route path="/EmployerProfile/:id" element={<EmployerProfile />} />
          <Route path="/UI-UX-Design" element={<Projectlisted />} />
          <Route path="/jobDetailView" element={<DetailView />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/loginUserProfile" element={<Logeduser />} />
          <Route path="/employerprofile" element={<Employerprofile />} />
          <Route
            path="/FreelancerJobProfile"
            element={<FreelancerJobprofile />}
          />
          <Route path="/EmployerJobProfile" element={<EmployerJobProfile />} />
          <Route path="/JobCreation" element={<Jobform />} />
          <Route path="/Packages" element={<Package />} />
          <Route path="/ThankU" element={<Thanku />} />
          <Route path="/milestonePayment" element={<MilestonePayment />} />
          <Route path="/SkillTest" element={<SkillTest />} />
          <Route path="/SKillQA" element={<SKillQA />} />
          <Route path="/Invoice-Generator" element={<InvoiceGenerator />} />
        </Routes>
        <CategoryFooter />
      </GoogleOAuthProvider>
    </MyProvider>
  );
}

export default App;
