import React, { useState, useContext, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import Milestone from "./Milestone";
import { message } from "antd";
import axios from "axios";
import Package from "./Package";
import { CgFileDocument } from 'react-icons/cg';
import { RxCountdownTimer } from 'react-icons/rx';
import { useNavigate } from "react-router-dom"
import Rating from "../Rating";
import { MyContext } from "../../Mycontext";
import { warning } from "framer-motion";

function Bidding({ detailjob, show, Applied, Balance, checkUser }) {
  const navigate = useNavigate();
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [bid, setbid] = useState({
    BidAmount: 0,
    Duration: 0,
    DurationType: detailjob.Budget_Type,
  });
  const [bid_ID, setbid_ID] = useState(null);
  const [P_ID, setP_ID] = useState(null);
  const [reviewShow, setreviewShow] = useState(null);
  const [editable, seteditable] = useState(false);
  const [totalBids, setTotalBids] = useState(0);
  const [Proposal, setProposal] = useState("Add Your Proposal here");
  const [jobPostTime, setJobPostTime] = useState();
  const [forms, setForms] = useState([
    { Amount: "", Description: "", M_Due_Date: "", status: "" },
  ]);
  const [milestonesAmountCheck, setMilestoneAmountCheck] = useState(0);


  const currentTime = new Date();
  const uploadTimestamp = new Date(detailjob.createdAt);
  const timeDifference = currentTime.getTime() - uploadTimestamp.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  useEffect(() => {
    if (days > 0) {
      setJobPostTime(days)
    } else if (hours > 0) {
      setJobPostTime(hours)
    } else if (minutes > 0) {
      setJobPostTime(minutes)
    } else {
      setJobPostTime(seconds)
    }
  }, [])



  const handleApplyBtn = async (status) => {
    // message.success("bid Applied Successfully")
    let milamount = 0;
    forms.map((item) => (milamount += parseFloat(item.Amount)));
    // console.log(milamount + " " + bid.BidAmount);
    if (parseFloat(milamount) === parseFloat(bid.BidAmount)) {
      if (bid_ID == null) {
        if (checkProposallength(Proposal)) {
          message.warning("Proposal should have minmium 20 words");
          return;
        }
        try {
          const { data } = await axios.post(endPointUrl + "/user/applyBid", {
            ...bid,
            Token,
            Proposal,
            Bid_Status: status,
            UserID: user.id,
            JobID: detailjob.JobID,
            Bid_date: status === "Draft" ? null : new Date(),
          });
          handlemilestone(forms, data.data.Bid_ID);
          updateBidBalance();
          message.success("Added to your Open Bids");
          seteditable(!editable);
          navigate("/FreelancerJobProfile")
        } catch {
          message.error("Some Error Ocurr");
        }
      } else {
        try {
          const { data } = await axios.post(endPointUrl + "/user/updateBid", {
            ...bid,
            Token,
            Proposal,
            Bid_Status: status,
            UserID: user.id,
            JobID: detailjob.JobID,
            Bid_ID: bid_ID,
          });
          handlemilestone(forms, bid_ID);
          updateBidBalance();
          message.success("bid Applied Successfully");
        } catch {
          message.error("Some Error Ocurr");
        }
      }
    } else {
      message.warning("Milestone Amount Does not Match with Bid Amount");
    }
  };

  function removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
  };
  const updateBidBalance = async () => {
    if (Balance) {
      try {
        await axios.post(endPointUrl + "/updateBidBalance", {
          Token,
          id: user.id,
          value: -1,
        });
        console.log("Bid Update successful");
      } catch {
        console.log("Bid Update unsuccessful");
      }
    }
  };

  const handlemilestone = async (item, Bid_ID) => {
    if (bid_ID) {
      try {
        await axios.post(endPointUrl + "/user/removeMilestoneforBid", {
          Token,
          Bid_ID,
        });
        console.log("successfully deleted");
      } catch {
        console.log("error occurr");
      }
    }

    item.map(async (res) => {
      await axios.post(endPointUrl + "/user/createMilestone", {
        ...res,
        Token,
        Bid_ID,
        MilstoneStatus: "Pending",
      });
    });
  };

  const onEditorChange = (evt, editor) => {
    const data = evt.editor && evt.editor.getData();
    setProposal(data);
  };
  const getTotalBids = async (JobID) => {
    const { data } = await axios.post(endPointUrl + "/getallPraposal", {
      Token,
      JobID
    })

    setTotalBids(data.data);
  }
  const checkeditTable = async (JobID) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/job/bidding", {
        Token,
        UserID: user.id,
        JobID: JobID,
      });
      if (data.status === true && data.data != null) {
        const { BidAmount, Duration, DurationType, Bid_ID } = data.data;
        setbid({
          BidAmount,
          Duration,

        });
        setProposal(data.data.Proposal);
        // console.log(data.data.Proposal);
        if (data.data.Bid_Status === "Submitted") {
          seteditable(false);
        } else {
          setbid_ID(Bid_ID);
        }

        try {
          const { data } = await axios.post(
            endPointUrl + "/project/getMilestoneforBid",
            { Bid_ID, Token }
          );
          if (data.milestones.length !== 0) {
            const mil = data.milestones;
            setreviewShow(mil[mil.length - 1].FreelancerPaymentStatus);
            setP_ID(mil[0].P_ID);
            let a = [];
            mil.map((res) => {
              a.push({
                Amount: res.Amount,
                Description: res.Description,
                M_Due_Date: res.M_Due_Date,
                mid: res.PM_ID,
                status: res.MilstoneStatus,
                paymentStatus: res.EmployerPaymentStatus,
                recieveStatus: res.FreelancerPaymentStatus
              });
            });
            setForms(a);
          } else {
            try {
              const { data } = await axios.post(
                endPointUrl + "/user/getMilestoneforBid",
                { Bid_ID, Token }
              );
              const mil = data.milestones;
              console.log(data.milestones);
              let a = [];
              mil.map((res) => {
                a.push({
                  Amount: res.Amount,
                  Description: res.Description,
                  M_Due_Date: res.M_Due_Date,
                  mid: res.M_ID,
                  status: res.MilstoneStatus,
                  paymentStatus: res.EmployerPaymentStatus,
                  recieveStatus: res.FreelancerPaymentStatus
                });
              });
              setForms(a);
            } catch {
              console.log("error");

            }
          }
        } catch {
          console.log("error");

        }
      } else {
      }
    } catch { }
  };

  const checkProposallength = (content) => {
    const strippedContent = content.replace(/<[^>]+>/g, "");

    // Count the words in the content
    const wordCount = strippedContent.trim().split(/\s+/).length;

    // Check if the word count is less than 50
    if (wordCount < 20) {
      return true;
    }
    return false;
  };

  const LoginPageRedirect = () => {
    navigate("/login");
    message.warning("Login as a freelancer")
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    checkeditTable(detailjob.JobID);
    getTotalBids(detailjob.JobID);

  }, []);
  return (
    <div style={{ padding: "20px 40px" }}>
      <Rating P_ID={P_ID} JobID={detailjob.JobID} show={reviewShow} />
      <h2 style={{ margin: 0 }}>About this Project</h2>
      <div style={{ display: "flex", alignItems: "center", gap: "50px" }}>
        <p style={{ color: "#5c5b5b" }}><span><CgFileDocument /></span> {totalBids} Proposals</p>
        <p style={{ color: "#5c5b5b" }}><span><RxCountdownTimer /></span> Active {jobPostTime} hours ago</p>
      </div>
      <div>
        <div className="typesandbudget">
          <span><strong>type: </strong>{detailjob.ProjectType || ''}</span> {/* Add Project Type  in place of Budget_Type here... */}
          <span><strong>Budget: </strong>{detailjob.Budget_From}-{detailjob.Budget_To}/{detailjob.Budget_Type}</span>
        </div>
        <div className="typesandbudget">
          <span><strong>category: </strong>{detailjob.Category}</span>
          <span><strong>technology: </strong>{detailjob.Technology}</span>
        </div>
        <div className="typesandbudget">
          <span><strong>Location: </strong>{detailjob.Location}</span>
          <span><strong>language: </strong>{detailjob.Language || ""}</span>
        </div>
      </div>
      <hr />
      {user?.Type == "Freelancer" ?
        <div id="myElement" style={{ width: "100%" }}>
          {Applied ?
            <>
              <h4>Bid Info</h4>
              <div class="bidInfo">
                <p id="highlighted" style={{ marginInlineEnd: "30%" }}>Bid Amount : {bid.BidAmount}</p>
                <p id="highlighted">Duration : {bid.Duration}/{bid.DurationType === "month" ? "Months" : bid.DurationType === "week" ? "Weeks" : "hrs."}</p>
              </div>
              <div>
                <h4>Proposal</h4>
                <div className="proposal">
                  <span>{removeTags(Proposal)}</span>
                </div>
                <Milestone
                  forms={forms}
                  setForms={setForms}
                  editable={!editable}
                  milestonesAmountCheck={milestonesAmountCheck}
                  setMilestoneAmountCheck={setMilestoneAmountCheck}
                />
              </div>
            </>
            :
            <>
              <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h2>Place Your Bid</h2>
                <p> <strong>Bid-Balance </strong>({user.Bid_Balance})</p>
              </span>

              <form className="bid_Form" contentEditable={false}>
                <label htmlFor="BidAmount">
                  <p>Bid Amount</p>
                  <input
                    type="number"
                    id="BidAmount"
                    name="BidAmount"
                    step={0.1}
                    value={bid.BidAmount}
                    onChange={(e) => setbid({ ...bid, BidAmount: e.target.value })}
                    disabled={editable}
                  />
                </label>
                <label htmlFor="Duration">
                  <p>Duration ({bid.DurationType === "month" ? "Months" : bid.DurationType === "week" ? "Weeks" : "hrs."})</p>
                  <input
                    type="number"
                    id="Duration"
                    name="Duration"
                    step={0.1}
                    value={bid.Duration}
                    onChange={(e) => setbid({ ...bid, Duration: e.target.value })}
                    disabled={editable}
                  />
                </label>

              </form>
              <p>Proposal</p>
              {editable ? (
                <CKEditor
                  initData={Proposal}
                  readOnly={true}
                  config={{
                    toolbar: [
                      { name: "document", items: ["Undo", "Redo"] },
                      { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                      { name: "styles", items: ["Format"] },
                      {
                        name: "basicstyles",
                        items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                      },
                    ],
                  }}
                  onChange={onEditorChange}
                />
              ) : (
                <CKEditor
                  initData={Proposal}
                  config={{
                    toolbar: [
                      { name: "document", items: ["Undo", "Redo"] },
                      { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                      { name: "styles", items: ["Format"] },
                      {
                        name: "basicstyles",
                        items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                      },
                    ],
                  }}
                  onChange={onEditorChange}
                />
              )}

              {show ? (
                <>
                  <Milestone
                    forms={forms}
                    setForms={setForms}
                    editable={editable}
                    milestonesAmountCheck={milestonesAmountCheck}
                    setMilestoneAmountCheck={setMilestoneAmountCheck}
                  />
                  {!editable ? (
                    <>
                      <button
                        className="btn"
                        style={{ padding: "10px", margin: "10px 5px" }}
                        onClick={() => { handleApplyBtn("Submitted") }}
                      >
                        Apply For Bid
                      </button>
                      <button
                        className="btn"
                        style={{ padding: "10px", background: "#a0a046" }}
                        onClick={() => handleApplyBtn("Draft")}
                      >
                        Save As Draft
                      </button>
                    </>
                  ) : null}
                </>
              ) :
                <button className="btn" style={{ padding: "15px", marginTop: "20px" }} onClick={() => { checkUser(); }}>Add Milestone</button>
              }
            </>
          }
        </div>
        : user?.Type !== "Employer" ?
          <button className="btn" style={{ padding: "15px", marginTop: "20px" }} onClick={LoginPageRedirect}>Place your Bids</button>
          : null}

    </div>
  );
}

export default Bidding;
