import React, { useContext, useEffect, useState, useRef } from "react";
import profile from "../../images/freelancer.png";
import dolar from "../../images/dolar.png";
import { MyContext } from "../../Mycontext";
import moment from "moment/moment";
import dashboardImg from "../../images/img.png";
import axios from "axios";
import Bidding from "../Jobs/Bidding";
import Messages from "../Messages";
import { Collapse } from "antd";
const { Panel } = Collapse;
import { scroller } from "react-scroll";
import ProgressBar from "../ProgressBar";
import Rating from "../Rating";
import { useNavigate, Link } from "react-router-dom";
import TotalEarnings from "./freelancer-payment/TotalEarnings";
import { motion } from "framer-motion";
import { Popup } from "semantic-ui-react";
import { BsArrowRight } from "react-icons/bs";
import { BiShowAlt, BiHide } from 'react-icons/bi';
import { IoArrowBackSharp } from 'react-icons/io5';
import { message } from "antd";
import { MdVerified } from 'react-icons/md';
import { IoAddOutline } from 'react-icons/io5';
import { AiFillStar } from 'react-icons/ai';
import img from '../../images/whiiteveryfied.png';

function FreelancerJobprofile() {

  const { user, endPointUrl, Token } = useContext(MyContext);
  const [counter, setCounter] = useState(0);
  const [bgColor, setbgColor] = useState('');
  const [balance, setBalance] = useState(0);
  const [education, setEducation] = useState([]);
  const [exp, setExp] = useState([]);
  const Navigate = useNavigate();
  const [detailview, setdetailview] = useState(false);
  const [detailjob, setdetailjob] = useState({});
  const [bidStatus, setbidStatus] = useState({
    openBid_status: "",
    closedBid_status: ""
  });
  const [ClosedBids, setClosedBids] = useState([]);
  const [OpenBids, setOpenBids] = useState([]);
  const [ongoing, setOngoing] = useState({
    InProgress: [],
    Completed: [],
    Closed: [],
  });
  const [current, setcurrent] = useState([]);
  const [Oncurrent, setOncurrent] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [amount, setAmount] = useState(null);
  const [activejob, setActivejob] = useState(0);
  const [activejobIn, setActivejobIn] = useState(0);
  const [Onactivejob, setOnActivejob] = useState(0);
  const [MessageActive, setMessageActive] = useState(0);
  const [show, setshow] = useState(false);
  const [PortData, setPortData] = useState([]);
  const [showjobs, setShowjobs] = useState({
    My_Bids: true,
  })
  const [Applied, setapplied] = useState(false);

  const getEducation = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getuserEducation", { UserID: user.id, Token })
    setEducation(data.data);

  };

  //Set Date
  const handleDate = (item) => {
    let createdAt = new Date(item.createdAt);
    let day = createdAt.getUTCDate().toString().padStart(2, '0'); // Get day (padded with leading zero if needed)
    let month = (createdAt.getUTCMonth() + 1).toString().padStart(2, '0'); // Get month (January is 0)
    let year = createdAt.getUTCFullYear().toString().slice(-2); // Get last two digits of the year
    let DATE = `${day}/${month}/${year}`;
    return DATE;
  };

  const getCertificate = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getCertiByUserID", {
      Token,
      UserID: user.id
    });
    if (data.data != null) {
      setPortData(data.data);
    }

  }

  const getExp = async () => {

    const { data } = await axios.post(endPointUrl + "/user/getuserExp", {
      UserID: user.id,
      Token,
    });
    setExp(data.data);
  };
  useEffect(() => {
    getExp();
    getEducation();
  }, []);

  const getOngoingjobs = async () => {
    const { data } = await axios.post(endPointUrl + "/getProjectsforfreelancer", {
      Token,
      Awarded_To_ID: user.id,
    });

    let progress = data.data.filter((item) => {
      return item.Project_status === "in_Progress"

    });
    let Amount = progress.map((item) => {
      return item.amount;
    })
    let InProgress = progress.map((item) => {
      return item.jobs;
    })

    let closed = data.data.filter((item) => {
      return item.Project_status === "Closed"
    });
    let Closed = closed.map((item) => {
      return item.jobs;
    })

    let completed = data.data.filter((item) => {
      return item.Project_status == "Completed"
    });
    let Completed = completed.map((item) => {
      return item.jobs;
    })

    setAmount(Amount);
    setOngoing({ InProgress: InProgress, Completed: Completed, Closed: Closed });
    setOncurrent(Onactivejob ? Closed : InProgress);
  };
  const getjobs = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/getAllJobs", {
        Token,
      });
      let Published = data.data.filter((item) => {
        return item.IsActive === true && item.Status === "Published";
      });
      getAllAppliedJobs(Published);
      getOpenBids();
      getClosedBids();
    } catch {
      getjobs([]);
    }
  };
  const handlejobClick = (buttonIndex) => {
    setActivejob(buttonIndex);
  };
  const handlejobClickIn = (buttonIndex) => {
    setActivejobIn(buttonIndex);
  };
  const handleOnjobClick = (buttonIndex) => {
    setOnActivejob(buttonIndex);
  };
  const handleMessageClick = (buttonIndex) => {
    setMessageActive(buttonIndex);
  };


  const scrollToElement = () => {
    scroller.scrollTo("myElement", {
      duration: 300,
      delay: 0,
    });
  };
  const checkeditTable = async (JobID) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/job/bidding", {
        Token,
        UserID: user.id,
        JobID: JobID,
      });
      if (data.status === true && data.data) {
        setapplied(true);
        setshow(true);
      } else {
        setapplied(false);
        setshow(false);
      }
    } catch {
      setapplied(false);
      setshow(false);
    }
  };
  const BidBalance = async () => {
    const { data } = await axios.post(endPointUrl + "/getuserByBidBalance", {
      Token,
      id: user.id,
    })
    setBalance(data.data.Bid_Balance);
  };
  const getAllAppliedJobs = async (alljob) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/allbidByuser", {
        Token,
        UserID: user.id,
      });

      let arrApplied = [];

      data.data.map((item) => arrApplied.push(item.JobID));

      const appliedbit = alljob.filter((item) =>
        arrApplied.includes(item.JobID)
      );
      const Notappliedbit = alljob.filter(
        (item) => !arrApplied.includes(item.JobID)
      );
      // console.log(appliedbit);

      setSavedJobs(appliedbit);
    } catch {
      console.log("error on getting jobs");
    }
  };
  const getOpenBids = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getPendingbidsJobsofUser", {
      Token,
      UserID: user.id,
    });
    let open_bit = data.data.filter((item) => {
      return item.freelancerBidjobs
    });
    let Open_bit = open_bit.map((item) => {
      return item.freelancerBidjobs
    });
    let Bid_Status = open_bit.map((item) => item.Bid_Status)
    setcurrent(Open_bit);
    setOpenBids(Open_bit);
    setbidStatus({ openBid_status: Bid_Status })
  };

  useEffect(() => {
    setcurrent(OpenBids);
  }, [OpenBids])

  const getClosedBids = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getRejectedbidsJobsofUser", {
      Token,
      UserID: user.id
    });
    let closed_bit = data.data.filter((item) => {
      return item.freelancerBidjobs
    });
    let Closed_bit = closed_bit.map((item) => {
      return item.freelancerBidjobs
    });
    let Bid_Status = closed_bit.map((item) => item.Bid_Status)

    setClosedBids(Closed_bit);
    setbidStatus({ ...bidStatus, closedBid_status: Bid_Status })
  };
  const Analytics = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/analyticsforfreelancer", {
        id: user.id,
        Token
      });
      setAnalytics(data.data);
    } catch {
      message.error("Error while fetching analytics")
    }
  };
  const labelStyles = {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    color: '#5E5E5E',
    fontSize: '12px',
    margin: '3px'
  }

  const progress = () => {
    (setCounter((counter) => counter = 0));
    (user?.Photo) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.About) ? (setCounter((counter) => counter + 10)) : (null),
      // (user?.Title) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Verified_Profile == "Verified") ? (setCounter((counter) => counter + 20)) : (null),

      (user?.rate_from) ? (setCounter((counter) => counter + 4)) : (null),
      (user?.rate_to) ? (setCounter((counter) => counter + 4)) : (null),
      (user?.rate_type) ? (setCounter((counter) => counter + 4)) : (null),

      (exp?.length) ? (setCounter((counter) => counter + 5)) : (null),
      (education?.length) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Skills) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Technology) ? (setCounter((counter) => counter + 5)) : (null),

      (user?.Name) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Address) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Gender) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Mobile) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Language) ? (setCounter((counter) => counter + 5)) : (null),

      (user?.Email) ? (setCounter((counter) => counter + 2)) : (null),
      (user?.City) ? (setCounter((counter) => counter + 2)) : (null),
      (user?.State) ? (setCounter((counter) => counter + 2)) : (null),
      (user?.Country) ? (setCounter((counter) => counter + 2)) : (null)
  }

  const gradient = () => {
    (counter < 25) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg,rgba(7,178,116,1), #00ffff)')) : (
      (counter < 70) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1), #00ffff, #c4fdff)'))
        : (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1) 25%, #00ffff 63%, rgba(51,154,250,1) 90%)'))
    )
  }
  function removeHtmlTags(input) {
    // Use a regular expression to match and remove HTML tags
    return input.replace(/<[^>]*>/g, '');
  }
  useEffect(() => {

    gradient();
  }, [counter])

  useEffect(() => {

    progress();

  }, [education])

  useEffect(() => {
    (setCounter((counter) => counter = 0));
    BidBalance();
    getjobs();
    Analytics();
    getOngoingjobs();
    getCertificate();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [detailview]);
  return (<>
    <div className="jobProfileContainer">
      <div className="compo">
        {!detailview ? (
          <div className="welcomeConatiner">
            <div>
              <span className="welcome-date">
                {moment().format("dddd") + ", " + moment().format("MMM Do")}
              </span>
              <span className="welcomeName">
                Good Morning,{" "}
                <span style={{ color: "#07B274" }}>
                  {user.Name} {user.Last_Name ? user.Last_Name.charAt(0)?.toUpperCase() + "." : null}
                </span>
              </span>
            </div>
            <div>
              <img src={dashboardImg} alt="" />
            </div>
          </div>
        ) : null}
        {detailview ? (
          <>
            <Collapse defaultActiveKey={["1"]}>
              <Panel
                header={
                  <div >
                    <IoArrowBackSharp style={{
                      backgroundColor: "white",
                      borderRadius: "50px",
                      stroke: "#073b",
                      padding: "2px",
                      margin: " -8px 10px -8px 0"
                    }}
                      onClick={() => {
                        setdetailview(!detailview);
                      }}
                    />
                    <span>
                      {detailjob?.Title}
                    </span>
                  </div>
                }
                key="1" showArrow={false} style={{ fontFamily: "poppins" }}>

                <div
                  style={{ color: "#606563" }}
                  dangerouslySetInnerHTML={{ __html: detailjob?.Description }}
                ></div>
              </Panel>
            </Collapse>
            <div id="myElement">
              <Bidding
                Balance={balance}
                detailjob={detailjob}
                show={show}
                Applied={Applied}
              />
            </div>
          </>
        ) : (
          <>

            <div className="jobContainer">
              <div className="job-container-header">
                <span>Dashboard</span>
              </div>
              <div className="jobMatch">
                <span
                  style={
                    activejob === 0
                      ? {

                        color: "#07b273",
                        fontWeight: "800",


                      }
                      : null
                  }
                  onClick={() => {
                    handlejobClick(0);
                    setShowjobs({ My_Bids: true })
                  }}
                >
                  My Bids
                  {
                    activejob === 0
                      ? (
                        <motion.div className="underline" layoutId="underline" />
                      )
                      : null
                  }
                </span>
                <span
                  style={
                    activejob === 1
                      ? {

                        color: "#07b273",
                        fontWeight: "800",

                      }
                      : null
                  }
                  onClick={() => {
                    handlejobClick(1);
                    setShowjobs({ My_Projects: true })

                  }}
                >
                  {" "}
                  My Projects
                  {
                    activejob === 1
                      ? (
                        <motion.div className="underline" layoutId="underline" />
                      )
                      : null
                  }
                </span>
                <span
                  style={
                    activejob === 2
                      ? {

                        color: "#07b273",
                        fontWeight: "800"
                      }
                      : null
                  }
                  onClick={() => {
                    handlejobClick(2);
                    setShowjobs({ My_Messages: true })

                  }}
                >
                  My Messages{" "}
                  {
                    activejob === 2
                      ? (
                        <motion.div className="underline" layoutId="underline" />
                      )
                      : null
                  }
                </span>
                <span
                  style={
                    activejob === 3
                      ? {

                        color: "#07b273",
                        fontWeight: "800"
                      }
                      : null
                  }
                  onClick={() => {
                    handlejobClick(3);
                    setShowjobs({ My_Earnings: true })

                  }}
                >
                  {" "}
                  My Earnings
                  {
                    activejob === 3
                      ? (
                        <motion.div className="underline" layoutId="underline" />
                      )
                      : null
                  }
                </span>
              </div>
              <hr style={{ margin: "-1px -20px" }} />
              {showjobs.My_Bids ?
                <div style={{ display: "grid" }}>
                  <div className="jobMatch-inside">
                    <span
                      style={
                        activejobIn === 0
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handlejobClickIn(0);
                        setcurrent(OpenBids);
                      }}
                    >
                      Open Bids
                    </span>
                   <p>|</p>
                    <span
                      style={
                        activejobIn === 1
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handlejobClickIn(1);
                        setcurrent(ClosedBids);
                      }}
                    >
                      Closed Bids{" "}
                    </span>
                   <p>|</p>
                    <span
                      style={
                        activejobIn === 2
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handlejobClickIn(2);
                        // setcurrent(ClosedBids);
                      }}
                    >
                      Archived Bids{" "}
                    </span>
                   <p>|</p>
                    <span
                      style={
                        activejobIn === 3
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handlejobClickIn(3);
                        // setcurrent(ClosedBids);
                      }}
                    >
                      Invitation{" "}
                    </span>

                  </div>
                  {current.map((item) =>
                  (
                    <div>
                      <div className="current-jobs">
                        <div>
                          <span
                            className="job-name"
                            onClick={() => {
                              setdetailview(!detailview);
                              setdetailjob(item);
                              checkeditTable(item.JobID);
                            }}
                          >
                            {item.Title}
                          </span>
                          <p dangerouslySetInnerHTML={{ __html: item.Description.slice(0, 300) + '...' }}></p>
                          {/* <p >{removeHtmlTags(item.Description.slice(0, 300)) + '...'}</p> */}
                          <div className="bid-status">
                            <span style={{ background: "none", color: "gray" }} >
                              {handleDate(item)}
                            </span>
                            <span style={{ background: "none", color: "gray" }}>
                              connects : 1
                            </span>
                            <span style={{ background: "none", color: "gray", fontSize: "20px" }}>
                              {item.IsActive ? <BiShowAlt /> : <BiHide />}
                            </span>
                            <span style={{ background: "none", color: "gray" }}>
                              Status: {item.Status}
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                  )}</div>
                : null}
              {showjobs.My_Projects ?
                <div style={{ display: "grid" }}>
                  <div className="jobMatch-inside">
                    <span
                      style={
                        Onactivejob === 0
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handleOnjobClick(0);
                        setOncurrent(ongoing.InProgress);
                      }}
                    >
                      Active
                    </span>
                   <p>|</p>
                    <span
                      style={
                        Onactivejob === 1
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handleOnjobClick(1);
                        setOncurrent(ongoing.Completed);
                      }}
                    >
                      Completed{" "}
                    </span>
                   <p>|</p>
                    <span
                      style={
                        Onactivejob === 2
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handleOnjobClick(2);
                        setOncurrent(ongoing.Closed);
                      }}
                    >
                      Closed{" "}
                    </span>
                  </div>
                  {Oncurrent.map((item, index) => (
                    <div>
                      <div className="current-jobs" key={index}>
                        <div>
                          <span
                            className="job-name"
                            onClick={() => {
                              setdetailview(!detailview);
                              setdetailjob(item);
                              checkeditTable(item.JobID);
                            }}
                          >
                            {item.Title}
                          </span>
                          <div style={{ display: "flex" }}>
                            <p dangerouslySetInnerHTML={{ __html: item.Description.slice(0, 300) + '...' }}></p>
                            <hr style={{ margin: "0.5rem" }} />
                            {amount.map((res) =>
                              res.JobID === item.JobID ?
                                <div className="current-jobs-amount">
                                  <span ><p>${res.total_amount}</p> (Total Amount)</span>
                                  <span ><p>${res.total_amount_received ? res.total_amount_received : 0}</p> (Amount Received)</span>
                                </div>
                                : null
                            )}
                          </div>
                          <div className="current-tags" style={{ marginTop: "0" }}>
                            {item.SkillsRequired.split(",").map((skill) => {
                              return skill.length > 0 ? <span> {skill}</span> : null;
                            })}
                          </div>
                          {/* <div id="keywords">
                            {(item.Keywords ? item.Keywords.split(",") : []).map(
                              (skill) => {
                                return skill.length > 0 ? (
                                  <span style={{ background: "none", color: "gray" }}>
                                    {" "}
                                    #{skill}
                                  </span>
                                ) : null;
                              }
                            )}
                          </div> */}
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
                : null}
              {showjobs.My_Messages ?
                <div style={{ display: "grid" }}>
                  <div className="jobMatch-inside">

                    <span
                      style={
                        MessageActive === 0
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {

                        handleMessageClick(0);
                      }}
                    >
                      Inbox
                    </span>
                   <p>|</p>
                    <span
                      style={
                        MessageActive === 1
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {

                        handleMessageClick(1);
                      }}
                    >
                      Sent
                    </span>

                  </div>


                </div> : null}
              {showjobs.My_Earnings ?
                <TotalEarnings /> : null}
            </div>
          </>
        )}
        {/* <div className="btn-outline">Load More</div> */}
      </div>

      {!detailview ? (
        <div className="compo2">
          <div className="profileConatiner">
            <div className="profile-details" style={{ position: "relative" }} >
              <img src={img} alt="" style={{ filter: user.Verified_Profile == "Verified" ? "none" : "brightness(1)", width: "23px", height: "auto", marginLeft: "auto", position: "absolute", top: "8px", right: "8px" }} />

              {/* <Popup
                trigger={user.Verified_Profile == "Verified" ? <MdVerified style={{ color: "white", width: "23px", height: "auto", marginLeft: "auto", marginRight: "15px" }} /> :
                  <MdVerified style={{ color: "rgb(133 133 133)", width: "23px", height: "auto", marginLeft: "auto", marginRight: "15px" }} />}
                content={user.Verified_Profile == "Verified" ? "Profile Verified" :
                  user.Verified_Profile == "Pending" ? "Profile verification Pending" :
                    "Profile Unverified"}
              /> */}
              <img
                src={
                  user?.Photo === ""|| user?.Photo == null
                    ? profile
                    : `${endPointUrl}/images/${user.Photo}`
                }
                alt=""
              />
              <span className="profilename">{user.Name} {user.Last_Name}
              </span>
              {/* <span className="profileDesignation">
                {user?.Title}
              </span> */}
              <span className="rating">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 16 15" fill="#FF8A00" className="rating-star">
                <path d="M8.00027 12.1734L3.29827 14.8054L4.34827 9.52004L0.391602 5.86137L5.74293 5.22671L8.00027 0.333374L10.2576 5.22671L15.6089 5.86137L11.6523 9.52004L12.7023 14.8054L8.00027 12.1734Z" fill="#FF8A00" />
              </svg> */}
                <AiFillStar className="featuredfreelancer-rating-star" />
                {user.avg_Rating ? user.avg_Rating : 0} <p>({user.no_of_reviews ? user.no_of_reviews : 0} Reviews)</p></span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ padding: "4px 15px", color: "#fff", display: "flex", gap: "9px" }}>
                  <span style={{ margin: "0" }}>Bid Balance</span>
                  <hr />
                  <span style={{ margin: "0" }} >{balance ? balance : 0}</span>
                </div>
                <IoAddOutline onClick={() => Navigate("/Packages")} className="buy-bid" />
              </div>
            </div>

            <div className="profile-complete" style={{ borderRadius: "0" }}>
              <span style={labelStyles}><span style={{ fontWeight: "600", color: "black", marginRight: "3px" }}>{counter}% </span> Profile complete</span>
              <div className="progress">
                <ProgressBar bgColor={bgColor} completed={counter} />
              </div>
              {user.profile_progress < 80 ?
                <>
                  <h6>Complete your profile</h6>
                  <h6>to increase your chances of getting hired</h6>
                  <div className="profile-btn" onClick={() => {
                    user?.Type == "Employer"
                      ? Navigate("/employerprofile")
                      : Navigate("/loginUserProfile");
                  }}>
                    <button style={{ borderRadius: "3px" }}>Complete Profile</button>
                  </div></> : null}
            </div>
            <hr />
            <div className="rate-card" style={{ borderRadius: "0 0 20px 20px " }}>
              <div className="price-range">
                <img style={{ width: "60px" }} src={dolar} alt="" />
                <div className="hour-price">
                  <span className="rate">Rate:</span>
                  <span>${user?.rate_from} / {user?.rate_type}</span>
                </div>
              </div>
              <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
              <div className="user-details">
                <p>Completed Projects </p>
                <span> {analytics.completedProject} </span>
              </div>
              <div className="user-details">
                <p>Success Rate</p>
                <span>{analytics.successRate}% </span>
              </div>
              <div className="user-details">
                <p>Language</p>
                <span>{user?.Language?.split(', ').join(', ')}</span>
              </div>
              <div className="user-details">
                <p>Total Earnings</p>
                <span>${analytics.totalEarning}</span>
              </div>
              <div className="user-details">
                <p>Gender</p>
                <span>{user.Gender}</span>
              </div>
              <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
              <div className="skills-card" style={{ padding: "10px 0px" }}>
                <h5>Skills</h5>
                <div className="skills-employee " >
                  {user?.Skills?.split(",").map((val) => (
                    <strong style={{ color: "#000000", borderRadius: "15px", border: "1px solid #c6bbbb87", padding: "5px 10px" }} key={val}>{val}</strong>
                  ))}
                </div>
              </div>
            </div>

          </div>
        </div>
      ) : (
        <div className="sideComponent">
          <div className="sideComponent_bid">
            <span>Bid Balance</span>
            <span id="highlight">{balance ? balance : 0}</span>

          </div>
          <div className="see-other-post">
            {!Applied ? (
              <button
                className="btn"
                onClick={() => {
                  scrollToElement();
                  { balance ? setshow(true) : Navigate("/Packages") }
                }}
              >
                Apply
              </button>
            ) : null}
          </div>
          <hr style={{ width: "100%" }} />
          <div className="uploadDocs">
            <h4 style={{ margin: "0 0 5px 0" }}>
              {detailjob?.IsActive ? "Documents" : "Upload Documents"}{" "}
            </h4>
            {
              detailjob?.IsActive ? (
                <div className="uploadtags">
                  {(detailjob.Documents
                    ? detailjob.Documents.split(",")
                    : []
                  ).map((tag, index) => (
                    <span key={index}>
                      <a
                        href={`${endPointUrl}/images/${tag}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {tag}
                      </a>
                    </span>
                  ))}
                </div>
              ) : null
              // <DocumentUpload detailjob={detailjob} getjobs={getjobs} />
            }
          </div>
          <hr style={{ width: "100%", background: "" }} />
          <div className="row-view">
            <div>
              <h4 style={{ margin: "5px 0 5px 0" }}>Budget Details</h4>
              <small>{detailjob?.Budget_From}-</small>
              <small>{detailjob?.Budget_To}$ / {detailjob?.Budget_Type}</small>
              {/* <small>{detailjob?.Budget_Type}</small> */}
            </div>
            <div>
              {" "}
              <h4 style={{ margin: "5px 0 5px 0" }}>Job Type</h4>
              <small>{detailjob?.Type}</small>
            </div>
          </div>
          <hr style={{ width: "100%", background: "" }} />
          <div>
            {" "}
            <h4 style={{ margin: "20px 0 5px 0" }}>Skills</h4>
            <div className="skillview">
              {(detailjob?.SkillsRequired).split(",")?.map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>Technology</h4>
            <div className="skillview">
              {(detailjob?.Technology).split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>

          <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>Category</h4>
            <div className="skillview">
              {(detailjob?.Category).split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          {/* <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>KeyWords</h4>
            <div className="skillview">
              {detailjob?.Keywords?.split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div> */}
        </div>
      )}
    </div>
    {/* <Messages/> */}
  </>
  );
}

export default FreelancerJobprofile;
