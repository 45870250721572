import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { MyContext } from "../../../Mycontext";
import add from "../../../images/add.png";
import { MdOutlineCancel } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { AiOutlineFilePdf, AiOutlineFileText } from 'react-icons/ai';
import Selectant from '../../Jobs/Select';
import { message } from 'antd';
import EditPortfolio from "./EditPortfolio";
// import { Link } from "react-router-dom";

export default function AddPortfolio() {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [PF_Id, setPF_Id] = useState()
    const [skillSelected, setskillSelected] = useState([]);
    const [skillmaster, setskillmaster] = useState([]);
    const [preform, setPreform] = useState([]);
    const [PortData, setPortData] = useState([]);
    const [showmodel, setshowModel] = useState(false);
    const [form, setForm] = useState({
        Title: "",
        Description: "",
        Type: "",
        Completion_Date: "",
        Skills: "",
        Project_Link: "",
    });
    const [errorForm, setErrorForm] = useState({
        Title: "",
        Description: "",
        Type: "",
        Completion_Date: "",
        Skills: "",
        Project_Link: "",
    });
    // Use a useEffect hook to update the Skills field in the form state
    useEffect(() => {
        const skillsString = skillSelected.join(", ");
        setForm({ ...form, Skills: skillsString });
    }, [skillSelected]);
    const [File_Type, setFile_Type] = useState([])

    function getFileExtension(filename) {
        var a = filename.split(".");
        if (a.length === 1 || (a[0] === "" && a.length === 2)) {
            return "";
        }
        return a.pop();
    }

    const handlemodle = () => {
        setErrorForm({
            Title: "",
            Description: "",
            Type: "",
            Completion_Date: "",
            Skills: "",
            Project_Link: "",
        })
        setshowModel(true)
    }

    const getPortfolio = async () => {
        const { data } = await axios.post(endPointUrl + "/user/getportfolioByUserID", {
            Token,
            UserID: user.id
        });
        if (data.data != null) {
            setPortData(data.data);
        }

    }

    const handleskillsmaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push(item.Name));
        setskillmaster(a);
    };
    const handlefiles = () => {
        let arr = [];
        Array.from(preform).map((file) => arr.push(getFileExtension(file.name)));
        setFile_Type(arr);
    }
    useEffect(() => {
        if (File_Type.length > 0) { uploadfiles() }
    }, [File_Type])

    const uploadfiles = async () => {

        const formdata = new FormData();
        Array.from(preform).map((item) => formdata.append('File_Upload', item))

        try {
            const { data } = await axios.post(
                endPointUrl + "/user/docupload", formdata //Files array upload
            );

            try {
                await axios.post(endPointUrl + "/user/addportfolioDoc", {
                    Token,
                    PF_Id: PF_Id,
                    UserID: user.id,
                    File_Upload: data.filenames,
                    File_Type: File_Type
                });
                message.success("Portfolio Added")
                getPortfolio();
            } catch {
                console.log("upload url produce error");
            }
        } catch {
            console.log("error occur during upload docs");
        }
    }

    const handleAddProtfolio = () => {
        const newFormErrors = {
            Title: "",
            Description: "",
            Type: "",
            Completion_Date: "",
            Skills: "",
            Project_Link: "",
        };
        let showError = false;
        if (form.Title === "") {
            showError = true;
            newFormErrors.Title = 'Please fill out this field!';
        } else {
            newFormErrors.Title = '';
        }
        if (form.Description === "") {
            showError = true;
            newFormErrors.Description = 'Please fill out this field!';
        } else {
            newFormErrors.Description = '';
        }
        if (form.Type === "") {
            showError = true;
            newFormErrors.Type = 'Please fill out this field!';
        }
        else {
            newFormErrors.Type = "";
        }
        if (form.Completion_Date === "") {
            showError = true;
            newFormErrors.Completion_Date = 'Please fill out this field!';
        } else {
            newFormErrors.Completion_Date = '';
        }
        if (form.Skills === "") {
            showError = true;
            newFormErrors.Skills = 'Please fill out this field!';
        } else {
            newFormErrors.Skills = '';
        }
        if (form.Project_Link === "") {
            showError = true;
            newFormErrors.Project_Link = 'Please fill out this field!';
        } else {
            newFormErrors.Project_Link = '';
        }
        setErrorForm(newFormErrors);
        if (!showError) {
            AddPortfolio()
        }
    }


    const AddPortfolio = async () => {

        try {
            const { data } = await axios.post(endPointUrl + "/user/addportfolio", {
                ...form,
                UserID: user.id,
                Token
            });
            setPF_Id(data.data.PF_Id)
            handlefiles();
            setshowModel(false)

        } catch {
            message.error("Unable to add portfolio")
        }

    }

    const handledeletefile = async (id) => {
        try {
            await axios.post(endPointUrl + "/user/deletePortfolioDoc", {
                Token,
                PFD_Id: id
            });
            getPortfolio();
        } catch {
            message.error("Unable to add portfolio")
        }
    }

    useEffect(() => {
        handleskillsmaster();
        getPortfolio();
    }, [EditPortfolio, PF_Id])

    return (
        <div className="education">
            <strong style={{ display: "flex", alignItems: "center" }}>
                Portfolio <img src={add} alt="" onClick={handlemodle} />
            </strong>

            {PortData.map((item, index) =>
                item?.UserID ?
                    <div className="all-education" key={index}>
                        <div className="education-line">
                        </div>
                        <div className="education-conainer" >
                            <div className="eduction-time" >
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center" }}><span style={{ color: "black", fontSize: "20px", fontWeight: "600" }}>{item?.Type}</span>
                                        {/* <a href={`http://${item?.Project_Link}`} target="_blank">ss</a> */}
                                    </div>
                                    <div className="year-range">{item?.Completion_Date.slice(0, 10)}</div>
                                    <div className="collage">{item?.Title}</div>
                                    <p style={{ fontSize: "12px" }}>
                                        {item?.Description}
                                    </p>
                                    <div className="btn-popular skills">
                                        {item.Skills?.length < 1 ? <button className="skill">.... ....</button> : null}
                                        {item.Skills?.split(',').map((it, index) => {
                                            if (index < 5) {
                                                return (
                                                    <button key={index} className="skill">
                                                        {it}
                                                    </button>
                                                );
                                            }
                                        })}
                                        {item.Skills?.split(',').length > 5 ? "+" : ""}
                                        {item.Skills?.split(',').length > 5 ? item.Skills?.split(',').length - 5 : ""}
                                    </div>

                                    <div className="Portfolio-docs" style={{ display: "flex", alignItems: "center", gap: "20px", marginTop: "20px" }}>
                                        {item.docs?.map((it, index) =>
                                            <div key={index} className="potfolioConatiner" style={{ position: "relative" }}>
                                                <a
                                                    href={`${endPointUrl}/portfoliodoc/${it.File_Upload}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title={it.File_Name}
                                                >
                                                    {it.File_Type === "png" ||
                                                        it.File_Type === "jpg" ||
                                                        it.File_Type === "jpeg" ||
                                                        it.File_Type === "gif" ||
                                                        it.File_Type === "webp" ||
                                                        it.File_Type === "jfif" ? (

                                                        <img
                                                            src={`${endPointUrl}/portfoliodoc/${it.File_Upload}`}
                                                            style={{ width: "50px", height: "50px", borderRadius: "25%" }}
                                                            alt={it.File_Type}
                                                            className="potfoliothumbail"
                                                        />
                                                    ) : it.File_Type === "pdf" ? (
                                                        <AiOutlineFilePdf className="potfoliothumbail" style={{ width: "50px", height: "50px" }} />
                                                    ) : (
                                                        <AiOutlineFileText className="potfoliothumbail" style={{ width: "50px", height: "50px" }} />
                                                    )}

                                                </a>
                                                <RxCross2
                                                    className="deleteiconPotfolio"
                                                    onClick={() => {
                                                        handledeletefile(it.PFD_Id)
                                                    }}
                                                />

                                            </div>
                                        )
                                        }
                                    </div>
                                    <EditPortfolio Portfolio={item} getPortfolio={getPortfolio} />
                                    <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
                                </div>
                            </div>
                        </div>
                    </div> : null
            )}
            <div id="myModal" className={showmodel ? "model-open" : "model"}>

                <div className="modal-content" style={{ padding: 0 }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                        <span style={{ color: "#46c294", fontWeight: "600" }}>Add Portfolio</span>
                        <span className="close" onClick={(() => setshowModel(false))}>
                            &times;
                        </span>
                    </div>
                    <div style={{ padding: "0 40px", margin: "30px 0" }}>
                        <form className="model-field exp-form"
                            encType="multipart/form-data"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <div style={{ paddingInlineEnd: "20px" }}>
                                <div>
                                    {" "}
                                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                        <div>
                                            <label htmlFor="title">Title</label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Title}</small>
                                            <input
                                                style={{ marginInlineEnd: "12%" }}
                                                type="text"
                                                name="title"
                                                value={form.Title}
                                                className={errorForm.Title != "" ? "errorborder" : ""}
                                                placeholder='Enter title'
                                                onChange={(e) => setForm({ ...form, Title: e.target.value })}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="Completion_Date">Date</label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Completion_Date}</small>
                                            <input
                                                value={form.Completion_Date}
                                                type="date"
                                                className={errorForm.Completion_Date != "" ? "errorborder" : ""}
                                                name="Completion_Date"
                                                placeholder="Enter Completion_Date"
                                                onChange={(e) =>
                                                    setForm({ ...form, Completion_Date: e.target.value })
                                                }
                                            />
                                        </div>

                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                        <div>
                                            <label htmlFor="project_type">Project Type</label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Type}</small>
                                            <select
                                                style={{ marginInlineEnd: "12%", marginBottom: "0", width: "100%" }}
                                                name="project_type"
                                                value={form.Type}
                                                className={errorForm.Type != "" ? "errorborder" : ""}
                                                onChange={(e) => setForm({ ...form, Type: e.target.value })}
                                            >
                                                <option value="">Project Type</option>
                                                <option value="Website">Website</option>
                                                <option value="Mobile App">Mobile App</option>
                                                <option value="ERP">ERP</option>
                                                <option value="SPA">SPA</option>
                                            </select>
                                        </div>

                                        <div>
                                            <label htmlFor="SkillsRequired">Skills</label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Skills}</small>
                                            <Selectant
                                                label={"Enter Skills"}
                                                options={skillmaster}
                                                setselected={setskillSelected}
                                                error={errorForm.Skills != ""}
                                                selected={skillSelected}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <div >
                                            <label htmlFor="descrption" style={{ display: "block" }}>Decription</label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Description}</small>
                                            <textarea
                                                style={{ width: "98%" }}
                                                name="descrption"
                                                id=""
                                                cols="30"
                                                rows="3"
                                                maxLength={500}
                                                value={form.Description}
                                                onChange={(e) => setForm({ ...form, Description: e.target.value })}
                                                className={errorForm.Description != "" ? "errorborder" : ""}
                                                placeholder='Enter Description'></textarea>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                            <div>
                                                <label htmlFor="file" style={{ display: "block" }}>Files</label>
                                                <input
                                                    style={{ marginInlineEnd: "12%" }}
                                                    type="file"
                                                    name="File_Upload"
                                                    multiple
                                                    onChange={(e) => { setPreform(e.target.files) }}// Make a function handleUplad and uplaod file and type in array type={file.type}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="Project_Link">Project Link</label>
                                                <small style={{ color: "#ec1c24" }}>{errorForm.Project_Link}</small>
                                                <input
                                                    style={{ marginInlineEnd: "12%" }}
                                                    type="text"
                                                    name="Project_Link"
                                                    value={form.Project_Link}
                                                    className={errorForm.Project_Link != "" ? "errorborder" : ""}
                                                    placeholder='Enter title'
                                                    onChange={(e) => setForm({ ...form, Project_Link: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        {/* Display selected files */}
                                        <div>
                                            {preform && preform.length > 0 && (
                                                <div>
                                                    <h4>Selected Files:</h4>
                                                    <ul>
                                                        {Array.from(preform).map((file, index) => (
                                                            <li key={index} style={{ display: "grid", gridTemplateColumns: "30% 60%", alignItems: "center", gap: "30px" }}>
                                                                <span style={{ overflowWrap: "break-word" }}><strong>Type:</strong> {file.type}</span>
                                                                <span style={{ overflowWrap: "break-word" }}> <strong>Name:</strong> {file.name}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                    <input type="submit" className="btn" value={'Add Portfolio'} style={{ width: "20%", backgroundColor: "#e3e3e3", color: "#000" }} />
                                    <input type="submit" className="btn" onClick={handleAddProtfolio} value={"Submit"} />
                                </div>

                            </div>
                        </form></div>

                </div>
            </div>
        </div>
    )
}
