import React, {useContext, useEffect, useState} from 'react'
import profile from "../images/emplyeer.png"
import Chat from './Chat'
import { MyContext } from '../Mycontext'

import img from"../images/freelancer.png"

const Data = [
  {
    id:1,
    Name:"Harshit Employer",
    image:img,
    desc:"lorem ipsumlo..."
  },
  {
    id:2,
    Name:"Harshit Freelancer",
    image:img,
    desc:"lorem ipsumlo..."
  },
  {
    id:3,
    Name:"Preeti Sir",
    image:img,
    desc:"lorem ipsumlo..."
  }
]

function Messages({Receiver}) {
  const [showMessg, setshowMessg] =useState(false);
  const [showchat, setShowchat] = useState(false);
  const [ReceiverID, setReceiverID] = useState(null);
  const {user} = useContext(MyContext);

const handleclick =()=>{
  setshowMessg(!showMessg);
};
const handleChat =(id)=>{
  setReceiverID(id)
  setShowchat(!showchat);
};
useEffect(() => {
  if(Receiver){
  setReceiverID(Receiver);
  setShowchat(true);
  setshowMessg(true);
  }
},[Receiver])
  return (
    <div className='chat-overlay'>
      <div className='msg-overlay-container'>
        <div className={showMessg?"msg-overlay":"msg-overlay minimised"} >
          <header className='msg-overlay-header' onClick={handleclick}>
            <img style={{width:"35px",marginInline:"0.7rem"}} src={profile} alt=""/>
          <span >Messages</span>
          </header>
          {!showchat ?
          <div className='msg-overlay-profiles'>
            {Data.map(({id,Name,image,desc}) => {
              return(<>
              <div key={id} className='overlay-profiles' onClick={()=>handleChat(id)}>
                <img src={image} alt=""/>
                <div className='overlay-profiles-details'>
                  <h4>{Name}</h4>
                  <p>{desc}</p>
                </div>
              </div>
                <hr style={{marginLeft:"25%",marginBlock:"0em"}}/>
                </>)
            })}
          </div>
           : <div>
            <span id='highlighted' style={{cursor:"pointer"}} onClick={handleChat}>Back</span>
            {/* <Chat  ReceiverID={ReceiverID}/> */}
            </div>} 
        </div>
        </div>
    </div>
  )
}

export default Messages